<template>
  <button :class="buttonClass">
    {{ title }}
  </button>
</template>

<script>
export default {
  name: 'DarkModeButton',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isDarkMode: false,
    };
  },
  computed: {
    buttonClass() {
      return this.isDarkMode ? 'button is-dark' : 'button is-light';
    },
  },
  methods: {
    detectDarkMode() {
      this.isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    },
  },
  mounted() {
    this.detectDarkMode();
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.detectDarkMode);
  },
  beforeDestroy() {
    window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.detectDarkMode);
  },
};
</script>

<style scoped>
.button {
  padding: 10px;
  margin: 5px;
}
</style>
