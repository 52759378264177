<template>
    <div> 
      <div class="news-title"> 
        <i class="fa-brands fa-apple"></i> Developer News
        <!-- <i class="fa-regular fa-heart"></i> ihearswift News -->
      </div>
      <div class="carousel-container" ref="carouselContainer">
          <div class="carousel" ref="carousel">
            <div v-for="item in newsData" class="carousel-item" :key="item.id" style="display: inline-block; padding: 10px; box-sizing: border-box;">
              <img v-if="item.imageUrl" :src="item.imageUrl" style="width: 40px; height: 40px; float: left; margin-right: 10px; border-radius: 5px;" />
              <span v-else style="width: 40px; height: 40px; float: left; margin-right: 0px; border-radius: 5px; font-size: 20px; text-align: center; line-height: 40px;">{{ getRandomEmoji() }}</span>
              <a v-if="item.title.imageUrl" :href="item.link" style="margin-left: 0px; padding-right: 40px; line-height: 40px; text-decoration: none;" v-html="item.title" target="_blank"></a>
              <a v-else :href="item.link" style="margin-left: 0px; padding-right: 40px; line-height: 40px; text-decoration: none;" v-html="item.title" target="_blank"></a>
            </div>
          </div>
      </div>
    </div>
</template>


<script>
import axios from 'axios';

export default {
  name: "AppleDeveloperNews",
  data() {
    return {
      newsData: [],
      autoScrollInterval: null,
      scrollSpeed: 1, // Adjust scroll speed as needed
      emojis: ['😀', '😃', '😄', '😁', '😆', '😅', '🤣', '😂', '🙂', '🙃', '😉', '😊', '😇', '🥰', '😍', '🤩', '😘', '😗', '☺️', '😚', '😙',
        '😋', '😛', '😜', '🤪', '😝', '🤑', '🤗', '🤭', '🤫', '🤔', '🤐', '🤨', '😐', '😑', '😶', '😏', '😒', '🙄', '😬', '🤥', '😌',
        '😔', '😪', '🤤', '😴', '😷', '🤒', '🤕', '🤢', '🤮', '🤧', '😵', '🤯', '🤠', '🥳', '😎', '🤓', '🧐', '😕', '😟', '🙁', '☹️',
        '😮', '😯', '😲', '😳', '🥺', '😦', '😧', '😨', '😰', '😥', '😢', '😭', '😱', '😖', '😣', '😞', '😓', '😩', '😫', '🥱', '😤',
        '😡', '😠', '🤬', '😈', '👿', '💀', '☠️', '💩', '🤡', '👹', '👺', '👻', '👽', '👾', '🤖', '😺', '😸', '😹', '😻', '😼', '😽',
        '🙀', '😿', '😾', '🙈', '🙉', '🙊', '💋', '💌', '💘', '💝', '💖', '💗', '💓', '💞', '💕', '💟', '❣️', '💔', '❤️', '🧡', '💛',
        '💚', '💙', '💜', '🤎', '🖤', '🤍', '💯', '💢', '💥', '💫', '💦', '💨', '🕳️', '💣', '💬', '👁️‍🗨️', '🗨️', '🗯️', '💭', '💤',
        '👋', '🤚', '🖐️', '✋', '🖖', '👌', '🤌', '🤏', '✌️', '🤞', '🤟', '🤘', '🤙', '👈', '👉', '👆', '🖕', '👇', '☝️', '👍', '👎',
        '✊', '👊', '🤛', '🤜', '👏', '🙌', '👐', '🤲', '🤝', '🙏', '✍️', '💅', '🤳', '💪', '🦾', '🦿', '🦵', '🦶', '👂', '🦻', '👃',
        '🧠', '🦷', '🦴', '👀', '👁️', '👅', '👄', '🧑', '👶', '🧒', '👦', '👧', '🧔', '👨', '🧔‍♂️', '👱‍♂️', '👨‍🦰', '👨‍🦱', '👨‍🦳',
        '👨‍🦲', '👩', '👱‍♀️', '👩‍🦰', '👩‍🦱', '👩‍🦳', '👩‍🦲', '👱', '👨‍⚕️', '👩‍⚕️', '👨‍🎓', '👩‍🎓', '👨‍🏫', '👩‍🏫', '👨‍⚖️',
        '👩‍⚖️', '👨‍🌾', '👩‍🌾', '👨‍🍳', '👩‍🍳', '👨‍🔧', '👩‍🔧', '👨‍🏭', '👩‍🏭', '👨‍💼', '👩‍💼', '👨‍🔬', '👩‍🔬', '👨‍💻', '👩‍💻',
        '👨‍🎤', '👩‍🎤', '👨‍🎨', '👩‍🎨', '👨‍✈️', '👩‍✈️', '👨‍🚀', '👩‍🚀', '👨‍🚒', '👩‍🚒', '👮', '👮‍♂️', '👮‍♀️', '🕵️', '🕵️‍♂️',
        '🕵️‍♀️', '💂', '💂‍♂️', '💂‍♀️', '👷', '👷‍♂️', '👷‍♀️', '🤴', '👸', '👳', '👳‍♂️', '👳‍♀️', '👲', '🧕', '🤵', '👰', '🤰',
        '🤱', '👩‍🍼', '👨‍🍼', '🧑‍🍼', '👼', '🎅', '🤶', '🧑‍🎄', '🦸', '🦸‍♂️', '🦸‍♀️', '🦹', '🦹‍♂️', '🦹‍♀️', '🧙', '🧙‍♂️',
        '🧙‍♀️', '🧚', '🧚‍♂️', '🧚‍♀️', '🧛', '🧛‍♂️', '🧛‍♀️', '🧜', '🧜‍♂️', '🧜‍♀️', '🧝', '🧝‍♂️', '🧝‍♀️', '🧞', '🧞‍♂️',
        '🧞‍♀️', '🧟', '🧟‍♂️', '🧟‍♀️', '💆', '💆‍♂️', '💆‍♀️', '💇', '💇‍♂️', '💇‍♀️', '🚶', '🚶‍♂️', '🚶‍♀️', '🧍', '🧍‍♂️', '🧍‍♀️',
        '🧎', '🧎‍♂️', '🧎‍♀️', '🧑‍🦯', '👨‍🦯', '👩‍🦯', '🧑‍🦼', '👨‍🦼', '👩‍🦼', '🧑‍🦽', '👨‍🦽', '👩‍🦽', '🏃', '🏃‍♂️', '🏃‍♀️',
        '💃', '🕺', '🕴️', '👯', '👯‍♂️', '👯‍♀️', '🧖', '🧖‍♂️', '🧖‍♀️', '🧗', '🧗‍♂️', '🧗‍♀️', '🤺', '🏇', '⛷️', '🏂', '🏌️', '🏌️‍♂️',
        '🏌️‍♀️', '🏄', '🏄‍♂️', '🏄‍♀️', '🚣', '🚣‍♂️', '🚣‍♀️', '🏊', '🏊‍♂️', '🏊‍♀️', '⛹️', '⛹️‍♂️', '⛹️‍♀️', '🏋️', '🏋️‍♂️',
        '🏋️‍♀️', '🚴', '🚴‍♂️', '🚴‍♀️', '🚵', '🚵‍♂️', '🚵‍♀️', '🤸', '🤸‍♂️', '🤸‍♀️', '🤼', '🤼‍♂️', '🤼‍♀️', '🤽', '🤽‍♂️', '🤽‍♀️',
        '🤾', '🤾‍♂️', '🤾‍♀️', '🧘', '🧘‍♂️', '🧘‍♀️', '🛀', '🛌', '🧑‍🤝‍🧑', '👭', '👫', '👬', '💏', '👩‍❤️‍💋‍👨', '👨‍❤️‍💋‍👨',
        '👩‍❤️‍💋‍👩', '💑', '👩‍❤️‍👨', '👨‍❤️‍👨', '👩‍❤️‍👩', '👪', '👨‍👩‍👦', '👨‍👩‍👧', '👨‍👩‍👧‍👦', '👨‍👩‍👦‍👦', '👨‍👩‍👧‍👧',
        '👨‍👨‍👦', '👨‍👨‍👧', '👨‍👨‍👧‍👦', '👨‍👨‍👦‍👦', '👨‍👨‍👧‍👧', '👩‍👩‍👦', '👩‍👩‍👧', '👩‍👩‍👧‍👦', '👩‍👩‍👦‍👦', '👩‍👩‍👧‍👧',
        '👨‍👦', '👨‍👦‍👦', '👨‍👧', '👨‍👧‍👦', '👨‍👧‍👧', '👩‍👦', '👩‍👦‍👦', '👩‍👧', '👩‍👧‍👦', '👩‍👧‍👧', '🗣️', '👤', '👥', '🫂',
        '👣', '🧳', '🌂', '☂️', '🧵', '🧶', '👓', '🕶️', '🥽', '🥼', '🦺', '👔', '👕', '👖', '🧣', '🧤', '🧥', '🧦', '👗', '👘', '🥻',
        '🩴', '🩱', '🩲', '🩳', '👙', '👚', '👛', '👜', '👝', '🛍️', '🎒', '🩸', '👞', '👟', '🥾', '🥿', '👠', '👡', '🩰', '👢', '👑',
        '👒', '🎩', '🎓', '🧢', '🪖', '⛑️', '📿', '💄', '💍', '💎', '🔇', '🔈', '🔉', '🔊', '📢', '📣', '📯', '🔔', '🔕', '🎼', '🎵',
        '🎶', '🎙️', '🎚️', '🎛️', '🎤', '🎧', '📻', '🎷', '🪗', '🎸', '🎹', '🎺', '🎻', '🪕', '🥁', '🪘', '📱', '📲', '☎️', '📞',
        '📟', '📠', '🔋', '🔌', '💻', '🖥️', '🖨️', '⌨️', '🖱️', '🖲️', '💽', '💾', '💿', '📀', '🧮', '🎥', '🎬', '📽️', '📺', '📷',
        '📸', '📹', '📼', '🔍', '🔎', '🕯️', '💡', '🔦', '🏮', '🪔', '📔', '📕', '📖', '📗', '📘', '📙', '📚', '📓', '📒', '📃', '📜',
        '📄', '📰', '🗞️', '📑', '🔖', '🏷️', '💰', '🪙', '💴', '💵', '💶', '💷', '🪛', '🪚', '🪝', '🪜', '⚒️', '🛠️', '⛏️', '🪓',
        '🔨', '🔧', '🔩', '⚙️', '🗜️', '⚖️', '🦯', '🔗', '⛓️', '🪝', '🧰', '🧲', '🪜', '⚗️', '🧪', '🧫', '🧬', '🔬', '🔭', '📡',
        '💉', '🩸', '💊', '🩹', '🩺'
      ]
    };
  },
  methods: {
    async fetchNews() {
        try {
            const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/news/apple/developer-news`);
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    },
    async fetchReleases() {
        try {
            const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/news/apple/software-releases`);
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    },
    async fetchAndCombineNews() {
        try {
            const [news, releases] = await Promise.all([this.fetchNews(), this.fetchReleases()]);
            this.newsData = this.interleaveArrays(news, releases);
        } catch (error) {
            console.error(error);
        }
    },
    interleaveArrays(array1, array2) {
        const result = [];
        const maxLength = Math.max(array1.length, array2.length);
        for (let i = 0; i < maxLength; i++) {
            if (i < array1.length) result.push(array1[i]);
            if (i < array2.length) result.push(array2[i]);
        }
        return result;
    },
    getRandomEmoji() {
      const randomIndex = Math.floor(Math.random() * this.emojis.length);
      return this.emojis[randomIndex];
    },
    startAutoScroll() {
      const carousel = this.$refs.carouselContainer;
      this.autoScrollInterval = setInterval(() => {
        if (carousel.scrollLeft < carousel.scrollWidth - carousel.clientWidth) {
          carousel.scrollLeft += this.scrollSpeed;
        } else {
          carousel.scrollLeft = 0;
        }
      }, 20);
    }
  },
  mounted() {
    this.fetchAndCombineNews();
    this.startAutoScroll();
  },
  beforeDestroy() {
    clearInterval(this.autoScrollInterval);
  }
};
</script>

<style>

.carousel-container {
  overflow-x: hidden;
  white-space: nowrap;
}

.news-title { 
  float: left; 
  position: relative; 
  padding: 10px; 
  animation: rainbowGlow 5s infinite alternate;
  overflow: hidden;
  background: rgb(0, 0, 0);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 60px;
  line-height: 40px;
}

.title {
  margin-left: 20px;
}

.carousel {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.carousel-item {
  display: inline-block;
  margin-right: 0px;
  padding: 10px;
  color: white;
  font-weight: bold;
}

a:hover {
  color: rgb(255, 166, 0);
  text-decoration: none;
}

@keyframes rainbowGlow {
  0% { text-shadow: 0 0 10px #ff0000, 0 0 20px #ff0000, 0 0 30px #ff0000, 0 0 40px #ff0000, 0 0 50px #ff0000, 0 0 60px #ff0000, 0 0 70px #ff0000; color: #ff0000; }
  16% { text-shadow: 0 0 10px #ff7f00, 0 0 20px #ff7f00, 0 0 30px #ff7f00, 0 0 40px #ff7f00, 0 0 50px #ff7f00, 0 0 60px #ff7f00, 0 0 70px #ff7f00; color: #ff7f00; }
  33% { text-shadow: 0 0 10px #ffff00, 0 0 20px #ffff00, 0 0 30px #ffff00, 0 0 40px #ffff00, 0 0 50px #ffff00, 0 0 60px #ffff00, 0 0 70px #ffff00; color: #ffff00; }
  50% { text-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00, 0 0 40px #00ff00, 0 0 50px #00ff00, 0 0 60px #00ff00, 0 0 70px #00ff00; color: #00ff00; }
  66% { text-shadow: 0 0 10px #ff7f00, 0 0 20px #ff7f00, 0 0 30px #ff7f00, 0 0 40px #ff7f00, 0 0 50px #ff7f00, 0 0 60px #ff7f00, 0 0 70px #ff7f00; color: #ff7f00; }
  83% { text-shadow: 0 0 10px #ffff00, 0 0 20px #ffff00, 0 0 30px #ffff00, 0 0 40px #ffff00, 0 0 50px #ffff00, 0 0 60px #ffff00, 0 0 70px #ffff00; color: #ffff00; }
  100% { text-shadow: 0 0 10px #8b00ff, 0 0 20px #8b00ff, 0 0 30px #8b00ff, 0 0 40px #8b00ff, 0 0 50px #8b00ff, 0 0 60px #8b00ff, 0 0 70px #8b00ff; color: #8b00ff; }
}

@media (prefers-color-scheme: dark) {
    .carousel-item {
        background-color: #121212;
        color: #ffffff;
    }
}

@media (prefers-color-scheme: light) {
    .carousel-item {
        background-color: rgba(246, 242, 242, 0.57);
        color: black;
    }
}
</style>
