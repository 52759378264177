<template>
    <div class="background"> 
      <div class="columns" style="padding: 60px;">
        <div class="column is-one-third">
          <div class="feature-header">
            <p class="feature-subtitle"> {{ course.step_title }} </p>
            <div class="columns"> 
              <div class="column"> 
                  <img :src="course.step_gif" alt="Adam" style="width: 400px; border-radius: 10px;"/>
              </div>
            </div>
          </div>
        </div>
      <div class="column" style="margin-top: -20px;">
        <StepsComponent :course="course" />
      </div>
    </div>
    </div>
  </template>
  
  <script>
  import StepsComponent from '@/components/StepsComponent.vue';

  export default {
    name: 'FeatureSection',
    props: { 
      course: {
        type: Object,
        required: true
      }
    },
    components: {
      StepsComponent
    },
    data() {
      return {
        features: [
          { title: '📱 Apple Development', description: "Learn how to build apps using Apple's declaritive programming langauge SwiftUI" },
          { title: '🤖 ChatGPT', description: "Don't waste your time doing what AI can do for you. Learn how to work with AI instead of against it" },
          { title: '🐥 Swift Fundamentals', description: 'Everything you need to know about Swift to build a strong foundation for building apps' },
          { title: '🤟 Javascript Fundamentals', description: 'The building blocks of interactivity on the web and server-side programming' },
          { title: '🌎 APIs', description: 'Build APIs with Node.js, Express and KOA. Building local development and production environments' },
          { title: '👩🏻‍💻 DynamoDB', description: 'Develop your understanding of NoSQL and hosted distributed databases' },
          { title: '💻 Heroku', description: 'Deploy your hard work on heroku and share your programs with the world' },
          { title: '⚡️ AWS', description: 'Take your server-side knowledge to the next level and learn how to manage and deploy your own infrastructure' },
          { title: '🙊 Transcribed Content', description: 'Every episode is transcribed and close captioned by AWS Transcribe and other tools' },
          { title: '🧩 Put the puzzle together', description: "Learn how it all connects from a seasoned developer that's worked for large enterprise companies." },
        ]
      };
    }
  }
  </script>
  
<style scoped>

@media (prefers-color-scheme: dark) {
    .background { 
      background: #121212;
    }
    .feature-subtitle {
      color: white; /* Subtitle color */
    }
}

@media (prefers-color-scheme: light) {
    .background { 
      background: #FFFFFF;
    }
    .feature-subtitle {
      color: black; /* Subtitle color */
    }
}

.background { width: 98%; }

.feature-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: auto;
  padding: 2rem;
}

.feature-header {
  margin-bottom: 2rem;
  margin-top: -70px;
}

.feature-title {
  font-size: 1.5rem; /* Adjust size to match the image */
  margin-bottom: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #db0f0f; /* Title color */
  margin-left: -20px;
}

.feature-subtitle {
  font-size: 2rem; /* Adjust size to match the image */
  margin-top: 40px;
  margin-bottom: 20px; 
}

.feature-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.feature-item {
  display: flex;
  align-items: center;
  height: 100px;
}

.checkmark {
  color: green; /* Adjust color to match the image */
  margin-right: 0.5rem;
}

.feature-content {
  text-align: left;
}

.feature-item-title {
  font-size: 1.25rem; /* Adjust size to match the image */
  font-weight: bold;
  color: #000; /* Adjust color to match the image */
}

.feature-item-description {
  font-size: 1rem; /* Adjust size to match the image */
  color: #666; /* Adjust color to match the image */
}
</style>
  