<template>
    <div style="margin-top: 70px;">
        <div style="float: left; position: relative; width: 20%; height: 100vh;"> 
            <ul>
                <li v-for="snippet in snippets" :key="snippet.sk">
                    <button @click="snippetPressed(snippet)"> {{ snippet.title }} </button>
                </li>
            </ul>
        </div>
        <div style="float: left; position: relative; width: 60%; height: 100vh; background: blue;">
            <div id="scrollableDiv" v-html="renderedMarkdown" class="markdown-body"></div>
        </div>
        <div class="sidebar">
            <ul>
                <li v-for="header in headers" :key="header.id" :class="{ active: activeHeader === header.id}" @click="scrollToHeader(header.id)">
                    <span :style="{ marginLeft: `${(header.level.length - 1) * 20}px` }">{{ header.content }}</span>
                </li>
            </ul>
        </div>
    </div>
  </template>
  
<script>
import MarkdownIt from 'markdown-it';
import SnippetsView from './snippets/SnippetsView.vue';
import axios from 'axios';
export default {
    data() {
      return {
        activeHeader: null,
        triggeredScroll: false,
        headers: [],
        md: new MarkdownIt(),
        markdownContainer: null,
        isNavigate: false,
        snippets: [],
        activeSnippet: null,
      };
    },
    components: {
        SnippetsView,
    },
    computed: {
        renderedMarkdown() {
            if (!this.activeSnippet) {
                return '';
            }
            const rendered = this.md.render(this.activeSnippet.markdownContent);    
            const withIds = rendered.replace(/<h([1-6])>(.*?)<\/h([1-6])>/g, (match, openLevel, content, closeLevel) => {
                const id = content.toLowerCase().replace(/\s+/g, '-');
                return `<h${openLevel} id="${id}">${content}</h${closeLevel}>`;
            });
            return withIds;
        },
    },
    mounted() {

        this.fetchSnippets();

        // Select the scrollable div by its ID
        var scrollableDiv = document.getElementById('scrollableDiv');

        // Add the 'scroll' event listener to the div
        scrollableDiv.addEventListener('scroll', () => {

            // This function is called every time the div is scrolled
            // console.log('Div scrolled!', this.scrollTop);

            if (!this.headers) { 
                // console.log(`No headers found.`)
                return;
            }

            let closestHeaderId = null;
            let closestDistance = Infinity;

            const headerElements = this.headers.map(header => document.getElementById(header.id));

            for (let i = 0; i < headerElements.length; i++) {
                const headerElement = headerElements[i];
                if (headerElement) {
                    const { top } = headerElement.getBoundingClientRect();

                    // Calculate the distance from the top of the scrollable div
                    const distance = Math.abs(top);

                    // Check if this header is closer than the previous closest,
                    // and its top is within 1 pixel from the top of the scrollable div
                    if (distance < closestDistance && top >= 0 && top <= 100) {
                        closestDistance = distance;
                        closestHeaderId = this.headers[i].id;
                    }
                }
            }

            // Update the activeHeader with the closest header's ID
            if (closestHeaderId !== null && !this.triggeredScroll) {
                this.activeHeader = closestHeaderId;
                // console.log(`Active Header: ${closestHeaderId} - Distance: ${closestDistance}`)
            }
        })
    },
    methods: {
        navigateTo(id) {
            this.scrollToHeader(id);
        },
        extractHeaders() {
            if (!this.activeSnippet) {
                return;
            }
            const tokens = this.md.parse(this.activeSnippet.markdownContent, {});
            this.headers = tokens.filter(token => token.type === 'heading_open').map(header => {
                const nextToken = tokens[tokens.indexOf(header) + 1];
                return {
                    level: header.tag,
                    content: nextToken.content,
                    id: nextToken.content.toLowerCase().replace(/\s+/g, '-')
                };
            });
            // console.log(`Extracted Headers: ${this.headers}`);
        },
        scrollToHeader(id) {
            this.triggeredScroll = true;
            this.activeHeader = id;
            const element = document.getElementById(id);
            // console.log(`Scrolling to header: ${id} - Element: ${element}`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            setTimeout(() => {
                this.triggeredScroll = false;
            }, 1000);
        },
        snippetPressed(snippet) {
            // console.log(`[📱] Snippet Pressed: ${snippet.title}`);
            // console.log(`[📱] Snippet Content: ${snippet.markdownContent}`);
            this.activeSnippet = snippet;
            this.extractHeaders();
        },
        fetchSnippets() { 
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url:   `${process.env.VUE_APP_BASE_URL}/snippets`,
                headers: { },
            };

            axios.request(config)
                .then((response) => {
                this.snippets = response.data;
                // console.log(JSON.stringify(this.snippets));
            })
            .catch((error) => {
                // console.log(error);
            });
        }
    },
  };
  </script>
  
  <style>
  .markdown-body {
    width: 100%;
    float: left;
    position: relative;
    height: 100vh;
    overflow-y: auto;
  }
  h1, h2, h3, h4, h5, h6 {
  scroll-margin-top: 100px; /* Adjust this value as needed */
}
.sidebar {
    width: 20%;;
    float: right;
    overflow: auto;
    height: 100vh;
  }
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  .sidebar li.active {
    font-weight: bold;
  }
  </style>

  