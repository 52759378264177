<template>
  <div class="progress-container"> 
    <div class="radial-progress">
      <svg viewBox="0 0 36 36" class="circular-chart">
        <path
          class="circle-bg"
          :stroke="backgroundColor"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <path
          class="circle"
          :stroke="foregroundColor"
          :stroke-dasharray="circumference"
          :stroke-dashoffset="dashOffset"
          d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
        />
        <text x="18" y="15.35" class="percentage" dominant-baseline="middle" text-anchor="middle">
          {{ label }}
        </text>
        <text x="8" y="22" class="nextep"> 
          Next Episode
        </text>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    foregroundColor: {
      type: String,
      default: '#4caf50'
    },
    backgroundColor: {
      type: String,
      default: '#eee'
    },
    label: {
      type: String,
      default: ''
    },
    duration: {
      type: Number,
      default: 10 // seconds
    }
  },
  data() {
    return {
      percent: 100,
      timer: null,
      circumference: 100,
    };
  },
  computed: {
    dashOffset() {
      return ((100 - this.percent) / 100) * this.circumference;
    }
  },
  mounted() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      let stepTime = (this.duration * 1000) / 100;
      this.timer = setInterval(() => {
        if (this.percent > 0) {
          this.percent -= 1;
          this.$emit('update:label', `${Math.round(this.percent / 100 * this.duration)}s`);
        } else {
          clearInterval(this.timer);
          this.$emit('completed');
        }
      }, stepTime);
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>

<style scoped>
.progress-container {
  display: flex;
  align-items: center; /* This will vertically center your radial-progress div */
  justify-content: center; /* This will horizontally center your radial-progress div */
  width: 100%; /* Full width */
  height: 100%; /* Full height minus some amount, as per your current style */
}

.radial-progress {
  width: 50%;
  height: 50%;
  transform: rotate(90deg);
  margin: 0 auto;
}

.circular-chart {
  width: 100%;
  height: 100%;
  transform: rotate(-90deg);
}

.circle-bg {
  fill: none;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 3.8;
  transition: stroke-dashoffset 0.35s;
  transform-origin: center;
}

.percentage {
  fill: white;
  font-size: 0.5em;
}

.nextep {
  fill: white;
  font-size: 0.2em;
}
</style>
