<template>
  <router-link :to="link" class="navbar-avatar-container">
    <img v-if="isLoggedIn" :style="{ width: width, height: height, borderRadius: '50%' }" :src="imageSource" /> 
    <img v-else :style="{ width: width, height: height, borderRadius: '50%' }" src="@/assets/avatars/noface-avatar.png" /> 
    <span class="badge" v-if="showBadge">{{ badgeCount }}</span>
  </router-link>
</template>

<script>

const { getUserNotifications } = require('../apis/user');

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    },
    userPhotoURL: {
      type: String,
      default: "@/assets/avatars/noface-avatar.png"
    }, 
    width: {
      type: String,
      default: '50px'
    },
    height: {
      type: String,
      default: '50px'
    }
  },
  data() {
    return {
      isLoading: false,
      badgeCount: 0,
      showBadge: true,
      interval: null
    };
  },
  computed: {
    link() {
      return this.isLoggedIn ? '/dashboard' : '/register';
    },
    imageSource() {
      if (!this.$store.state.user || this.$store.state.user.providerData.length === 0) { 
          return require('@/assets/avatars/noface-avatar.png');
      }
      return this.$store.state.user.providerData[0].photoURL || require('@/assets/avatars/noface-avatar.png')
    }
  }, 
  methods: {
    subscribeToReloadBadgeEvent() {
      const self = this;
      this.emitter.on("reload-badge", () => {
        self.updateBadgeCount();
      });
    },
    updateBadgeCount() {
    
      if (this.isLoading) { 
        return;
      }
      if (!this.$store.state.user) {
        return;
      }
      const uid = this.$store.state.user.uid;
      if (!uid) {
        return;
      }

      // console.log('Reloading badge count from SSE event');
      
      this.loadUserNotifications();
    },
    loadUserNotifications() {
      this.isLoading = true;

      if (!this.$store.state.user) {
        this.isLoading = false;
        return;
      }

      getUserNotifications(this.$store.state.user.uid)
        .then((notifications) => {
          this.badgeCount = notifications.length;
          this.isLoading = false;
          // console.log('User notifications loaded:', notifications.length);
        })
        .catch((error) => {
          console.error('Error loading user notifications:', error);
          this.isLoading = false;
        });
    }
  },
  mounted() {
    this.subscribeToReloadBadgeEvent();
    this.loadUserNotifications();
  }
}
</script>

<style scoped>
.navbar-avatar {
  border-radius: 50%;
  margin-right: 10px;
  background-color: purple;
}

.navbar-avatar-container {
  position: relative;
}

.badge {
  position: absolute;
  top: 0px;
  left: 0px;
  background-color: red; /* Change the background color as needed */
  color: white; /* Change the text color as needed */
  padding: 4px 4px;
  border-radius: 50%;
  width: 20px;
}
</style>
