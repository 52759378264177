<template>
    <canvas ref="canvas" v-show="animationStarted"></canvas>
    <div class="full-page-container" @click="startFillingAnimation()">
      <img v-if="isShowingLogo" src="@/assets/logos/blue-heart-swift-bird-light.png" class="centered-image" /> 
    </div>
</template>

<script>
export default {
  name: 'FullPageRedAnimation',
  data() {
    return {
      isShowingLogo: true,
      animationStarted: false,
      animationComplete: false,
      boxes: [],
      boxSize: 100,
      sound: new Audio('../../pop.mp3')
    };
  },
  mounted() {
    window.addEventListener('resize', this.setupCanvas);
    this.startFillingAnimation();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setupCanvas);
  },
  methods: {
    triggerFadeOut() {
      this.$refs.canvas.classList.add('fade-out');
      const fullPageContainer = document.querySelector('.full-page-container');
      if (fullPageContainer) {
        fullPageContainer.classList.add('fade-out');
      }
    },
    startFillingAnimation() {
      this.animationStarted = true;
      this.setupCanvas();
    },
    setupCanvas() {
      if (!this.animationStarted) return;

      const canvas = this.$refs.canvas;
      const ctx = canvas.getContext('2d');

      // Adjust canvas size
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      const rows = Math.ceil(canvas.height / this.boxSize);
      const cols = Math.ceil(canvas.width / this.boxSize);
      const totalCells = rows * cols;

      // Create an array to represent the grid of boxes
      this.boxes = Array.from({ length: totalCells }, () => ({ filled: false, x: 0, y: 0 }));

      this.fillRandomCell(ctx, cols);
    },
    fillRandomCell(ctx, cols) {
      const unfilledBoxes = this.boxes.filter(box => !box.filled);

      if (unfilledBoxes.length > 0) {
        const box = unfilledBoxes[Math.floor(Math.random() * unfilledBoxes.length)];
        const index = this.boxes.indexOf(box);
        box.filled = true;
        box.x = (index % cols) * this.boxSize;
        box.y = Math.floor(index / cols) * this.boxSize;
        ctx.fillStyle = '#2954FF';
        ctx.fillRect(box.x, box.y, this.boxSize, this.boxSize);

        requestAnimationFrame(() => this.fillRandomCell(ctx, cols));
      } else {
        this.isShowingLogo = false;
        this.animationComplete = true;
        this.triggerFadeOut(); // Call the fade-out method here
      }
    },
    checkAllBoxesFallen(ctx) {
      // Check if all boxes have fallen to the bottom
      return this.boxes.every(box => box.y >= ctx.canvas.height - this.boxSize);
    }
  }
}
</script>

<style scoped>
.full-page-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.centered-image {
  max-width: 30%; /* Adjust size as needed */
  margin-bottom: 20px;
}

.centered-text {
  font-size: 2em;
  color: black;
  user-select: none;
  cursor: pointer;
}

canvas {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 2s forwards; /* Adjust duration as needed */
}
</style>
