<template>
  <scroll-progress-bar></scroll-progress-bar>
  <!-- <div class="block" style="height: 100%; padding-top: 100px;"> 
      <div class="block" style="margin-top: 70px;"> 
        <apple-developer-news-carousel />
      </div>
      <div class="block"> 
          <div class="container"> 
            <daily-quote />
          </div>
      </div>
      <div class="block"> 
          <div class="container">
              <div class="column side-column">
                <swift-spotlight :snippet="latestPost" />
              </div>
              <div class="column middle-column">
                <recent-episodes :snippets="snippets" />
                
              </div>
              <div class="column side-column">
                  <coming-soon />
              </div>
          </div>
      </div>
      <div class="block"> 
          <div class="container">
              <div class="column side-column">
                  <recent-spotlights :snippets="snippets" />
                  <br /> 
                  <br />
                  <github-trending-ios-repositories />
              </div>
              <div class="column middle-column">
                  <recent-snippets :snippets="snippets" />
              </div>
              <div class="column side-column">
                  <most-liked :snippets="snippets" />
                  <br />
                  <br />
                  <ticker-feed title="😎 Top 5 Free Apps" :feed-url="`http://localhost:4000/news/apple/top25Free`" />
                  <br />
                  <br />
                  <ticker-feed title="💰 Top 5 Paid Apps" :feed-url="`http://localhost:4000/news/apple/top25`" />
              </div>
          </div>
      </div>
  </div> -->
  <div class="container contianer-content"> 
    <coming-soon />
  </div>
</template>

<script>
import ComingSoon from '@/components/ComingSoon.vue';
import DailyQuoteComponent from '@/components/home/DailyQuoteComponent.vue';
import SwiftSpotlight from '@/components/home/SwiftSpotlight.vue';
import RecentEpisodesComponent from '@/components/home/RecentEpisodesComponent.vue';
import ComingSoonComponent from '@/components/home/ComingSoonComponent.vue';
import RecentSpotlightsComponent from '@/components/home/RecentSpotlightsComponent.vue';
import MostLikedComponent from '@/components/home/MostLikedComponent.vue';
import RecentSnippetsComponent from '@/components/home/RecentSnippetsComponent.vue';
import TickerFeed from '@/components/home/TickerFeed.vue';
import AppleDeveloperNewsCarousel from '@/components/home/AppleDeveloperNewsCarousel.vue';
import AppleSoftwareReleasesCarousel from '@/components/home/AppleSoftwareReleasesCarousel.vue';
import GithubTrendingiOSRepositoriesComponent from '@/components/home/GithubTrendingiOSRepositoriesComponent.vue';

import FooterComponent from '@/components/FooterComponent.vue';
import ScrollProgressBar from '@/components/ScrollProgressBar.vue';

import SpinnerComponent from '@/components/SpinnerComponent.vue';
import SnippetRowView from '@/components/SnippetRowView.vue';
import AppleDeveloperNews from '@/components/AppleDeveloperNews.vue';

import VueCarousel from '@chenfengyuan/vue-carousel';
import { getSnippets } from '@/apis/snippets';

export default {
  emits: ['snippet-pressed'],
  data() {
      return {
          snippets: [],
          isLoading: false, 
          windowWidth: window.innerWidth, 
      };
  },
  components: {
      'daily-quote': DailyQuoteComponent,
      'swift-spotlight': SwiftSpotlight,
      'recent-episodes': RecentEpisodesComponent,
      'coming-soon': ComingSoonComponent,
      'recent-spotlights': RecentSpotlightsComponent,
      'most-liked': MostLikedComponent,
      'ticker-feed': TickerFeed,
      'recent-snippets': RecentSnippetsComponent,
      'footer-component': FooterComponent,
      'scroll-progress-bar': ScrollProgressBar,
      'apple-developer-news-carousel': AppleDeveloperNewsCarousel,
      'apple-software-releases-carousel': AppleSoftwareReleasesCarousel,
      'github-trending-ios-repositories': GithubTrendingiOSRepositoriesComponent,
      SpinnerComponent, 
      SnippetRowView, 
      'vue-carousel': VueCarousel,
      AppleDeveloperNews,
      'coming-soon': ComingSoon
  },
  mounted() { 
      this.$store.state.isPresentingNavbar = true;
      this.capturePageView();
      this.fetchSnippets();

      window.addEventListener('resize', () => {
          this.windowWidth = window.innerWidth;
      });
  },
  computed: {
      newsUrl() {
          return `${process.env.VUE_APP_BASE_URL}/news/apple/developer-news`;
      }, 
      top25FreeUrl() {
          return `${process.env.VUE_APP_BASE_URL}/news/apple/top25Free`;
      }, 
      mostRecentSnippet() {
          if (this.snippets.length === 0) return null;
          return this.snippets
              .slice()
              .filter(snippet => snippet.is_episode != true)
              .filter(snippet => snippet.spotlight != true)
              .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))[0];
      }, 
      mostRecentSwiftLanguageSnippet() {
          if (this.snippets.length === 0) return null;
          return this.snippets
              .slice()
              .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
              .filter(snippet => snippet.spotlight === true)
              .find(snippet => JSON.parse(snippet.tags).language === 'Swift')
      }, 
      latestPost() {

        if (!this.snippets || this.snippets.length === 0) { 
          // console.log(`No snippets found 🐸`)
          return []
        }

        return this.snippets
          .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          .filter(snippet => snippet.spotlight === true)
          .find(snippet => JSON.parse(snippet.tags).language === 'Swift')
      }  
  },
  methods: {
      tagClass(key) { 
          switch(key) { 
              case 'platform': 
                  return 'tag is-primary is-light';
              case 'language': 
                  return 'tag is-link is-light';
              case 'category':
                  return 'tag is-warning is-light';
              case 'twitter':
                  return 'tag is-info is-light';
              default: 
                  return 'yellow';
          }
      },
      sorted(snippets) { 
          // Sort by updated_at
          return snippets.sort((a, b) => {
              return new Date(b.updated_at) - new Date(a.updated_at);
          });
      },
      snippetPressed(snippet) {
          this.$emit('snippet-pressed', snippet);
      },
      fetchSnippets() { 
          this.isLoading = true;

          getSnippets()
              .then((response) => {
                  this.snippets = response;
                  this.isLoading = false;
              })
              .catch((error) => {
                  // console.log(error);
                  this.isLoading = false;
              });
      }, 
      capturePageView() {
          this.$posthog.capture('snippets_view', {
              'date': new Date().toISOString(),
          });
      }
  }
};
</script>

<style scoped>

/* Prefers light or dark mode */ 

@media (prefers-color-scheme: light) {
  .block { 
    background-color: white;
  }
  .background-hover:hover { 
     background: #ECEEF4;
  }
}

@media (prefers-color-scheme: dark) {
  .block { 
    background-color: black;
  }
  .background-hover:hover { 
      background: #d5f60115;
  }
}

.column { 
  text-align: center;
}

button { 
  width: 100%;            /* Set a fixed width or max-width */
  padding: 10px;           /* Add some padding */
  border: 1px solid #ccc;  /* Add a border */
  text-align: center;      /* Center the text */
  overflow: hidden;        /* Prevents the content from spilling out */
  white-space: normal;     /* Override the default nowrap of button */
  line-height: 1.5; 
  color: var(--snippet-title-color);
}

/* Make li hover */
button:hover {
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
} 

/* Columns */ 
.container {
  display: flex;
  flex-wrap: wrap;
  width: 100vw; /* 100% of the viewport width */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width */
}

.column {
  padding: 10px;
  box-sizing: border-box;
}

.side-column {
  flex: 1;
}

.middle-column {
  flex: 2;
  min-width: 50%;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .middle-column {
    order: -1;
  }
}

/* Spotlight */ 

.spotlight-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Ensure the parent div has a defined height */
  font-size: 1.5vw;
  color: #FF79B2FF;
  border: 1px solid rgba(255, 255, 255, 0.211);
  font-family: 'SFMono-Bold', monospace;
}

</style>
