const axios = require('axios');

const getUserNotifications = async (id) => {
  
    return new Promise((resolve, reject) => {
  
      let config = {
        method: 'get',
        url: `${process.env.VUE_APP_BASE_URL}/user/${id}/notifications`,
        headers: { 
          'Content-Type': 'application/json'
        }
      };
  
      axios.request(config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          // console.log(error);
          reject(error);
        });
    });
  }

  module.exports = {
    getUserNotifications
  }