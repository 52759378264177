<template>
  <div class="tag">
    <span :class="tagClass()"> {{ getEmoji() }} {{ this.tag  }} </span>
  </div>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    snippet: {
      type: Object,
      required: true
    },
    k: {
      type: String,
      required: true
    },
    tag: {
      type: String,
      required: true
    }
  }, 
  mounted() {
    // console.log(this.k, this.tag);
  },
  methods: {
    getEmoji() { 
        const tags = JSON.parse(this.snippet.tags);
        switch (this.k) { 
            case 'platform': 
                if (tags.platform === 'iOS') {
                    return '📱';
                } else if (tags.platform === 'Android') {
                    return '🤖';
                } else if (tags.platform === 'Web') {
                    return '🌐';
                } else {
                    return '🔥';
                }
            case 'language': 
                return '🔤';
            case 'category':
                return '📦';
            case 'twitter':
                return '🐦';
            case 'author':
                return '👤';
            default: 
                return '🔥'
        };
    },
    tagClass() { 
        // is dark mode enabled?
        switch(this.k) { 
            case 'platform': 
                return 'tag is-primary';
            case 'language': 
                return 'tag is-link';
            case 'category':
                return 'tag is-warning';
            case 'twitter':
                return 'tag is-info';
            default: 
                return 'yellow';
        }
    },
  }
}
</script>

<style scoped>

.tag { 
    background-color: transparent;
    padding: 5px;
    margin: -3px;
}

@media (prefers-color-scheme: dark) {

  .tag.is-primary {
    background-color: #4a4a4a; /* Darker background */
    color: #ffffff; /* Light text color */
  }

  .tag.is-info {
    background-color: #363636; /* Darker background */
    color: #ffffff; /* Light text color */
  }

  .tag.is-success {
    background-color: #2a2a2a; /* Darker background */
    color: #ffffff; /* Light text color */
  }

  /* Add more tag color overrides as needed */
}

</style>