<template> 
  <div class="fl-pr w-100" style="height: calc(100vh - 128px); margin-top: 68px;">
    <div class="fl-pr w-100" style=" height: 60px; background-color: powderblue;"> 
      Snippet Collection For X
    </div>
    <div class="fl-pr h-100 br" style="width: 20%;"> 
      <SnippetsView @snippet-pressed="handleSnippetPressed" />
    </div>
    <div class="fl-pr h-100 br" style="width: 80%;"> 
      <div class="fl-pr h-100 br" style="width: 70%;"> 
        <SnippetView :value="activeSnippetContent" :active-header-id="activeHeaderId" @headers-updated="handleSnippetHeaders" />
      </div>
      <div class="fl-pr h-100" style="width: 10%;"> 
        <SnippetSidebar :headers="headers" @navigate="handleNavigate" />
      </div>
    </div>
  </div>
</template> 
<script>
import SnippetsView from './snippets/SnippetsView.vue';
import SnippetView from './snippets/SnippetView.vue';
import SnippetSidebar from './SnippetSidebar.vue';
  
export default {
  props: {
    
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      activeSnippetContent: '',
      activeHeaderId: '',
      headers: []
    };
  },
  components: {
      SnippetsView, 
      SnippetView,
      SnippetSidebar
  },
  mounted() {
      window.addEventListener('resize', this.onResize);
  },
  computed: {
    
  }, 
  methods: {
      onResize() {
          this.windowWidth = window.innerWidth;
      }, 
      handleSnippetPressed(snippet) { 
          // console.log(`[📱] Snippet Pressed: ${snippet.title}`);
          // console.log(`[📱] Snippet Content: ${snippet.markdownContent}`);
          this.activeSnippetContent = snippet.markdownContent;
      }, 
      handleSnippetHeaders(headers) { 
          // console.log(`[📱] Headers Updated: ${headers}`);
          this.headers = headers;
      }, 
      handleNavigate(id) { 
          // console.log(`[📱] Navigating to: ${id}`);
          this.activeHeaderId = id;
      }
  }
};
</script>
  
<style scoped>

.br { 
  border-right: 2px solid #ECEEF4;
}

.bb { 
  border-bottom: 2px solid #ECEEF4;
}

.fl-pr { 
  float: left; 
  position: relative;
}

.w-50 { 
  width: 50%; 
}

.h-50 { 
  height: 50%; 
}

.w-100 { 
  width: 100%; 
}

.container {
  display: flex;
  height: 80vh;
  margin-top: 17vh;
}

.column {
  flex: 1;
  padding: 0px;
  margin: 0px;
}

textarea {
  width: 100%;
  height: 100%;
  border: none;
}

.preview iframe {
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid #000;
}

h3 {
  text-align: center;
}

.disabled-textarea {
  pointer-events: none;
  opacity: 0.5; /* Makes the textarea appear faded, indicating it's disabled */
}

.disabled-div {
  pointer-events: none; /* Disable mouse interactions */
  opacity: 0.5; /* Faded appearance similar to a disabled element */
  background-color: rgb(184, 238, 21)f0; /* Background color similar to a textarea */
  font-family: monospace; /* Font similar to default textarea font */
  white-space: pre-wrap; /* Preserves spaces and line breaks like a textarea */
  overflow: auto; /* Adds scrollbars when content overflows */
}

.sticky-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background: linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,154,0,1) 10%, rgba(208,222,33,1) 20%, rgba(79,220,74,1) 30%, rgba(63,218,216,1) 40%, rgba(47,201,226,1) 50%, rgba(28,127,238,1) 60%, rgba(95,21,242,1) 70%, rgba(186,12,248,1) 80%, rgba(251,7,217,1) 90%, rgba(255,0,0,1) 100%);
  color: white; /* Change text color as needed */
  font-size: 16px; /* Adjust the font size as needed */
  z-index: 100; /* Ensure the footer is above other content */
}
</style>
  