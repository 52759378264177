<template>
  <div>
    <button :class="buttonClass" v-on:click="incrementCount">
      👍({{ likeValue }})
    </button>
    <span style="padding-left: 10px;"></span>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    defaultLikeValue: {
      type: Number,
      required: false,
    },
    defaultDislikeValue: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      uuid: this.id,
      likeValue: this.defaultLikeValue, 
      dislikeValue: this.defaultDislikeValue,
      snippet: null,
      isDarkMode: false,
    };
  },
  computed: {
    buttonClass() {
      return this.isDarkMode ? 'button is-dark' : 'button is-light';
    }
  },
  methods: {
    incrementCount() {
      this.likeValue++
      this.patchSnippet()
      return this.likeValue;
    },
    decrementCount() {
      this.dislikeValue++
      this.patchSnippet()
      return this.dislikeValue;
    },
    getSnippetById() {
      let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_BASE_URL}/snippets/${this.uuid}`,
        headers: { }
      };
      axios.request(config)
        .then((response) => {
          this.snippet = response.data;
          this.likeValue = response.data.likes || 0;
          this.dislikeValue = response.data.dislikes || 0;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    patchSnippet() {
      let data = JSON.stringify({
        "PK": "SNIPPET",
        "SK": this.uuid,
        "likes": this.likeValue,
        "dislikes": this.dislikeValue
      });

      let config = {
        method: 'patch',
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_BASE_URL}/snippets/${this.uuid}`,
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

      axios.request(config)
        .then((response) => {
          // // console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.error(error);
        });
    },
    detectDarkMode() {
      this.isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    }
  },
  mounted() {
    this.getSnippetById();
    this.detectDarkMode();
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.detectDarkMode);
  },
  beforeDestroy() {
    window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.detectDarkMode);
  }
}
</script>

<style scoped>
button {
  padding: 10px;
  margin: 5px;
}
</style>
