// Gives us direct access to the create app function

import { createApp } from 'vue/dist/vue.esm-bundler';
import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

// Main app component
import App from "./App.vue";
import mitt from 'mitt';

// Import from ../node_modules bootstrap
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../public/bulma.min.css";
import "../public/main.css";
import "../public/css/theme.css";
import "@hannanmiah/light-editor/dist/style.css"
import 'bootstrap';

// Import plugins
import posthogPlugin from "../plugins/posthog.js"; 

// Import router
import router from "./routes";

// Import Helper functions
import { getCourses } from './apis/courses';

getCourses().then((data) => {
  executeApp(data);
}).catch((error) => {
  console.error(`Error fetching courses: ${error}`);
})

function executeApp(data) { 

  // // console.log(`Executing app with data: ${JSON.stringify(data)}`)

  /* 
  [
    {
        "PK": "COURSE",
        "SK": "1",
        "title": "Swift Fundamentals",
        "subtitle": "Learn about Apple's Swift programming language",
        "information": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.",
        "thumbnail": "https://iosbeginner-images.s3.amazonaws.com/covers/learn-swift.png",
        "published": true,
        "difficulty": "easy",
        "price": "2.99",
    }
  ]
  */ 

  // Create the app
  const app = createApp(App)

  const mutations = {
    setUser: function (state, payload) {
       state.user = payload
    },
    setCourses: function (state, payload) {
       state.courses = payload
    }, 
    setCoursesProgress: function (state, payload) {
      state.coursesProgress = payload
    },
    setPayments: function (state, payload) {
      state.payments = payload
    },
    setMyCourses: function (state, payload) {
      // console.log(`Setting my courses: ${JSON.stringify(payload)}`)
      state.myCourses = payload
    },
    setSummaryWidth: function (state, payload) {
      state.summaryWidth = payload
    }, 
    setSummaryExpanded: function (state, payload) {
      state.summaryExpanded = payload
    },
    setCurrentVideoTime: function (state, payload) {
      state.currentVideoTime = payload
    },
    setClosestHeaderId: function (state, payload) {
      state.closestHeaderId = payload
    },
    setSeries: function (state, payload) {
      state.series = payload
    }
 }
 
 const actions = {
    updateUserAction: function ({commit}, payload) {
       commit('setUser', payload)
    },
    updateCoursesAction: function ({commit}, payload) {
       commit('setCourses', payload)
    },
    updatePaymentsAction: function ({commit}, payload) {
      commit('setPayments', payload)
    }, 
    updateMyCoursesAction: function ({commit}, payload) {
      commit('setMyCourses', payload)
    }, 
    updateCoursesProgressAction: function ({commit}, payload) {
      commit('setCoursesProgress', payload)
    },
    updateSummaryWidthAction: function ({commit}, payload) {
      commit('setSummaryWidth', payload)
    }, 
    updateSummaryExpandedAction: function ({commit}, payload) {
      commit('setSummaryExpanded', payload)
    },
    updateCurrentVideoTimeAction: function ({commit}, payload) {
      commit('setCurrentVideoTime', payload)
    },
    updateClosestHeaderIdAction: function ({commit}, payload) {
      commit('setClosestHeaderId', payload)
    },
    updateSeriesAction: function ({commit}, payload) {
      commit('setSeries', payload)
    }
 }

// TODO: Fetch courses from network
const store = createStore({
    state () {
      return {
        isPresentingNavbar: true,
        user: null,
        closestHeaderId: null,
        courses: data,
        coursesProgress: [],
        payments: null,
        myCourses: [],
        summaryWidth: 0,
        summaryExpanded: false,
        currentVideoTime: 0,
        mainMenuSelectedItemName: "Home",
        isLandscape: false, // Used to determine if we are in landscape mode (specifically on small phones like iPhone 15 and below)
        isHeightToSmall: false, // Used to determine if the height of the screen is too small (directly related to isLandscape)
      }
    },
    getters: {
      didJoinCourse: (state) => (courseId) => {
        if (!state || !Array.isArray(state.myCourses)) {
          // Handle the case where state or state.myCourses is not as expected
          console.error(`Invalid state or state.myCourses is not an array: ${JSON.stringify(typeof state.myCourses)} `);
          return false;
        }
      
        if (typeof courseId !== 'string' || courseId.trim() === '') {
          // Handle the case where courseId is not a valid non-empty string
          console.error('Invalid courseId');
          return false;
        }
      
        const result = state.myCourses.some(course => 
          typeof course === 'object' && course !== null && course.PK === `COURSE#${courseId}`);
      
        return result; // Returns true if at least one matching course was found, otherwise false
      },
      didPurchaseCourse: (state) => (courseId) => {
        if (!state || !Array.isArray(state.payments)) {
          // Handle the case where state or state.payments is not as expected
          console.error('Invalid state or state.payments is not an array');
          return false;
        }
      
        if (typeof courseId !== 'string' || courseId.trim() === '') {
          // Handle the case where courseId is not a valid non-empty string
          console.error('Invalid courseId');
          return false;
        }
      
        return state.payments.some(payment => 
          typeof payment === 'object' && payment !== null && payment.courseId === courseId);
      }, 
      getSummaryWidth: (state) => {
        return state.summaryWidth
      }, 
      getSummaryExpanded: (state) => {
        return state.summaryExpanded
      },
      getCurrentVideoTime: (state) => {
        return state.currentVideoTime
      },
      getClosestHeaderId: (state) => {
        return state.closestHeaderId
      }, 
      getSeries: (state) => {
        return state.series
      }
    },
    mutations: mutations, 
    actions: actions,
    plugins: [createPersistedState()]
})

const emitter = mitt();
    
// Add emitter to the app
app.config.globalProperties.emitter = emitter;

// Add plugins to the app
app.use(posthogPlugin)

// Add router to the app
app.use(router)

// Install the store instance as a plugin
app.use(store)

// Mount the app
app.mount("#app")

}