<template>
   <div class="main" style="padding: 60px; width: 100%; overflow: hidden;"> 
    <div class="columns">
      <div class="column is-one-third">
        <div class="feature-header">
          <h2 class="feature-title">{{ module.title}}</h2>
          <p class="feature-subtitle"> {{ module.information }}</p>
        </div>
      </div>
      <div class="column">
        <div class="feature-list">
        <div class="feature-item" v-for="detail in data">
          <span class="checkmark">✓</span>
          <div class="feature-content">
            <h3 class="feature-item-title">{{ detail.title }}</h3>
            <p class="feature-item-description">{{ detail.description }}</p>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>
  
<script>

export default {
  name: 'FeatureSection',
  props: {
    module: { 
      type: Object,
      required: true
    }
  },
  data() {
    return {
      data: []
    };
  },
  watch: {
    module() {
      if (this.module.items) { 
        this.data = JSON.parse(this.module.items)
      } else { 
        this.data = [{ 
            title: 'No data found',
            description: 'No data found'
        }]
      }
    }
  },
  mounted() {
    if (this.module.items) { 
      this.data = JSON.parse(this.module.items)
    }
  },
}
</script>
  
<style scoped>

@media (prefers-color-scheme: dark) {
  .main { 
    background-color: black;
    width: 100%;
  }
  .feature-section {
    color: white; /* Adjust color to match the image */
  }
  .feature-title {
    color: white;
  } 
  .feature-subtitle {
    color: #ECEEF4; /* Subtitle color */
  }
  .feature-item-title {
    color: white;
  }

  .feature-item-description {
    color: #ECEEF4; /* Adjust color to match the image */
  }
}

@media (prefers-color-scheme: light) {
  .main { 
    background-color: #f9fbfe;
    width: 100%;
  }
  .feature-section {
    color: black; /* Adjust color to match the image */
  }
  .feature-title {
    color: black;
  } 
  .feature-subtitle {
    color: gray; /* Subtitle color */
  }
  .feature-item-title {
    color: black;
  }

  .feature-item-description {
    color: gray; /* Adjust color to match the image */
  }
}


.feature-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1024px;
  margin: auto;
  padding: 2rem;
}

.feature-header {
  margin-bottom: 2rem;
}

.feature-title {
  font-size: 2.5rem; /* Adjust size to match the image */
  margin-bottom: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.feature-subtitle {
  font-size: 1rem; /* Adjust size to match the image */
}

.feature-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.feature-item {
  display: flex;
  align-items: center;
  height: 160px;
}

.checkmark {
  color: green; /* Adjust color to match the image */
  margin-right: 0.5rem;
}

.feature-content {
  text-align: left;
}

.feature-item-title {
  font-size: 1.25rem; /* Adjust size to match the image */
  font-weight: bold;
}

.feature-item-description {
  font-size: 1rem; /* Adjust size to match the image */
}
</style>
  