<template>
  <SpinnerComponent v-if="isCourseLoading" />
  <div v-else> 
    <div v-if="course" class="hero-container" :style="heroContainerStyle">
      <div class="content">
        <div>
          <img :src="course.icon" class="centered-image heart" />
        </div>
        <!-- <h1 class="title"> Junior is the new Senior 🚀 </h1> -->
        <h1 class="title">{{ course.title }}</h1>
        <h2 v-if="course.subtitle" class="subtitle"> {{ course.subtitle }} </h2>
        <div class="buttons">
          <course-button 
            @click="getStarted()"
            :course-id="this.courseId"
            :did-join-course="this.didJoinCourse(course.SK)" 
            :is-course-completed="isCourseCompleted">
          </course-button>
          <!-- <PixelatedButton :button-image="course.button" text="Get Started" @click="getStarted()" /> -->
        </div>
      </div>
    </div>

    <course-progress-bar v-if="course" :progress="getCourseProgress(this.course).progress" />
    
    <!-- <HomeInfo2Component /> -->
    <BabyStepsComponent v-if="course" :course="course" />
    <MainHeroTabComponent v-if="course" :course="course" />

    <div style="float: left; position: relative; width: 100%; padding-bottom: 60px;"> 
      <div v-if="course" v-for="module in course.modules" class="home-section">
        <HomeSectionComponent 
            :module="module"
            :title="title(module)"
            :subtitle="module.title"
            :tint-color="module.color" 
        />
      </div>
    </div>
    <div style="float: left; position: relative; width: 100%;"> 
      <div @click="getStarted()" class="color-box" id="color-changing-div" :style="{ 
          float: left, 
          position: relative,
          width: '100%',
          paddingTop: '20px',
          backgroundColor: currentColor,    
        }">
          <div class="centered-div" :style="{ position: 'relative', width: windowWidth < 768 ? '100%' : '40%', margin: '0 auto', paddingRight: '40px'}"> 
          <img src="../../public/swift-bird-light.png" style="width: 80%; margin: 0 auto; background: transparent; margin-top: 40px; margin-bottom: 0px;"> 
          <span
            v-bind:style="emojiStyle"
            style="float: left; position: relative; width: 100%; text-align: center; padding-top: 40px; font-weight: bold; font-size: 100px;   text-shadow: 2px 2px;
      "
            >
            {{ currentEmoji }}
            </span>
            <span style="float: left; position: relative; width: 100%; text-align: center; padding-bottom: 0px; margin-top: -20px; font-weight: bold; font-size: 72px; color: white;"> Just tap! </span>
        </div>  
      </div>
    </div>
    
    <FooterComponent /> 
  </div>
</template>
  
<script>
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import PixelatedButton from '@/components/PixelatedButton.vue';
import HomeInfo2Component from '@/components/HomeInfo2Component.vue';
import MainHeroTabComponent from '@/components/MainHeroTabComponent.vue';
import BabyStepsComponent from '@/components/BabyStepsComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import CourseInfoTableV2 from '@/components/CourseInfoTableV2.vue';
import HomeSectionComponent from '@/components/HomeSectionComponent.vue';
import CourseButton from '@/components/CourseButton.vue';
import CourseProgressBar from '@/components/CourseProgressBar.vue';
  
import { joinCourse, getCourseById, getCoursesByExternalId } from '../apis/courses.js';
import { getCoursesProgress } from '../apis/watch.js';

import { mapState, mapActions, mapGetters } from 'vuex';

export default {
    components: {
      SpinnerComponent,
      PixelatedButton,
      HomeInfo2Component,
      MainHeroTabComponent,
      BabyStepsComponent,
      HomeSectionComponent,
      CourseInfoTableV2,
      FooterComponent,
      'course-progress-bar': CourseProgressBar,
      'course-button': CourseButton
    },
    data() {
      return {
        courseId: 'ghi',
        course: null,
        coursesProgress: [],
        isCourseLoading: false,
        windowWidth: window.innerWidth,
        colors: ['#FF5722', '#2096F3', '#4CAF50', '#B217FF', '#E91E63', '#FFEB3B', '#00BCD4'],
        currentColorIndex: 0, // To track the current color
        emojis: ['☝️', '☝🏼', '☝🏽', '☝🏾', '☝🏿'],
        currentEmojiIndex: 0,
      }
    },
    computed: {
      ...mapState([
        'user'
      ]),
      ...mapGetters([
        'didJoinCourse'
      ]),
      heroContainerStyle() { 
        return {
          float: 'left',
          position: 'relative',
          background: `url(${this.course.hero}) center center / cover no-repeat`,
          width: '100%',
          height: '50%',
          marginTop: '70px',
        }
      },
      currentColor() {
          return this.colors[this.currentColorIndex];
      },
      currentEmoji() {
          return this.emojis[this.currentEmojiIndex];
      },
      // check if course is completed
      isCourseCompleted() {
        return this.coursesProgress.some(c => c.courseId === this.courseId && c.progress >= 100);
      }
    },
    mounted() {
      this.courseId = this.$route.params.courseId || 'ghi'
      // console.log(`🔥🔥🔥[CourseListView.vue] - [mounted] - params: ${this.courseId}`)
      this.fetchCourseDetails();

      setInterval(this.nextEmoji, 1000); // Change emoji every 1000ms (1 second)
        
      // Sets up an interval to change the currentColorIndex every second
      setInterval(() => {
        this.currentColorIndex = (this.currentColorIndex + 1) % this.colors.length;
      }, 1000);
        
      // Add a resize event listener to update the window width
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
      });
      this.$store.state.isPresentingNavbar = true;

      this.fetchCoursesProgress();
    },
    methods: {
      ...mapActions([
        'updateMyCoursesAction',
        'updateCoursesProgressAction'
      ]),
      fetchCourseDetails() { 
        this.isCourseLoading = true;
        // console.log(`[CourseListView.vue] - [fetchCourseDetails] - courseId: ${this.courseId}`)
        getCourseById(this.courseId).then((course) => {
          this.course = course;
          this.isCourseLoading = false;
          // console.log(`[CourseListView.vue] - [fetchCourseDetails] - finished: ${this.courseId}`)
        }).catch((error) => {
          console.error(`[CourseListView.vue] - [fetchCourseDetails] - error: ${error}`);
          this.isCourseLoading = false;
        });
      },
      title(module) {
        return parseInt(module.position) + 1;
      },
      browseCatalog() {
        // Logic to browse the catalog
      },
      getStarted() {
        // console.log('✅');
        this.patchCourse();
      }, 
      goToCourse() { 
          this.$router.push(`/learn/${this.course.SK}`)
      },
      patchCourse() {
        if (!this.user) {
            this.goToCourse();
            return
        }
        // console.log('[RR] - Join Course - ' + this.course.SK + ' - ' + this.user.uid)
        joinCourse(this.course.SK, this.user.uid)
            .then(() => {
                getCoursesByExternalId(this.user.uid)
                    .then((myCourses) => {
                        this.updateMyCoursesAction(myCourses);
                        this.goToCourse();
                    })  
            })
      },
      fetchCoursesProgress() { 
        const user = this.$store.state.user
        if (!user) {
            // console.log('🚀🚀🚀 No user found')
            return
        }
        getCoursesProgress(user.uid).then((coursesProgress) => {
            this.coursesProgress = coursesProgress;
            // console.log('🚀🚀🚀 Course Progress', JSON.stringify(this.coursesProgress))
            this.updateCoursesProgressAction(coursesProgress);
        }).catch((error) => {
            // console.log(error);
        });
      },
      getCourseProgress(course) {
          if (!this.$store.state.user) { 
              return {}
          }
          // console.log(`🤣 Course ID: ${this.courseId} | Progress: ${this.coursesProgress.find(p => p.courseId === course.SK) || {}}`)
          return this.coursesProgress.find(p => p.courseId === this.courseId) || {}
      },
    }
  }
</script>
  
<style scoped>

@media (prefers-color-scheme: dark) {
    .home-section {
        background: #121212;
    }
}

@media (prefers-color-scheme: light) {
    .home-section {
        background: #FFFFFF;
    }
}

.home-section { 
  float: left; position: relative; width: 100%; padding: 20px; padding-bottom: 0px;
}
  
.color-box {
  /* Adjust the size of the div as needed */
  width: 100%;
  height: 100vh;
  transition: background-color 1s; /* Smooth transition between colors */
}

.centered-image {
  display: block;
  margin: 0 auto;
  max-width: 200px;
  width: 100%;
}

.hero-container {
  float: left; position: relative;
  background: url('@/assets/background_2.png') center center / cover no-repeat;
  background-color: #1A48FE;
  width: 100%;
  height: 50%;
  margin-top: 70px;
}

.hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  opacity: 0.2;
  pointer-events: none;
} 

.title {
  font-size: 4vw; /* Adjust font size as necessary */
  font-family: 'PixelSplitter', sans-serif;                                                                                                                                        
  margin-bottom: 30px; /* Adjust margin as necessary */
  font-weight: bold;
  color: white;
  text-align: center;
}

.subtitle { 
  font-size: 3vw; /* Adjust font size as necessary */
  margin-bottom: 30px;
  font-family: 'PixelSplitter', sans-serif;                                                                                                                                        
  color: #121212; /* Red text color */
  text-align: center;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);
}

.btn { 
  width: 300px;
  height: 80px;
  font-size: 48px;
  font-weight: bold;
}

.content {
  position: relative; /* Ensures content is above the overlay */
  max-width: 900px;
  margin: 0 auto;
  z-index: 1; /* Stack above the overlay */
  padding-top: 60px;
}

.buttons {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  gap: 10px; /* Optional: adds space between the buttons */
  margin-top: 40px;
  margin-bottom: 50px;
}

.btn {
  background: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  margin: 5px;
  transition: all 0.3s ease;
}

.btn:hover {
  background: #ffffff;
  color: #000000;
}

.btn-primary {
  background: #8A2BE2; /* Adjust primary color as necessary */
  border-color: #8A2BE2;
}

.btn-primary:hover {
  background: #7B1FA2; /* Adjust on hover color as necessary */
}
  
</style>
  ../apis/courses