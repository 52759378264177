<template>
    <div>
        <div v-if="!isInModal" style="display: flex"> 
            <router-link v-if="!isInModal" :to="`/register`">👈</router-link>
            <button v-else @click="$emit('goToPreviousPage')">👈</button>
            <h5 class="card-title">Welcome back 👋</h5>        
        </div>
        <div class="alert alert-warning" role="alert" v-if="isLoginError">
            🚨 Sign in failed. {{ errorMessage }}
        </div>
        <div class="alert alert-success" role="alert" v-if="isLoginComplete">
            🎉 Sign in successful! Your account was created!
        </div>
        <form id="loginForm" @submit.prevent="submitForm">
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" class="form-control" id="email" v-model="email" required>
                <small v-if="submitted && !isEmailValid" class="text-danger">Please enter a valid email address.</small>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="password" v-model="password" required>
                <small v-if="submitted && !isPasswordValid" class="text-danger">Please enter a valid password.</small>
            </div>
            <CustomButton
                type="submit"
                class="fr"
                title="Sign in 👉🏼"
            />
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { auth, GoogleAuthProvider, signInWithPopup } from '../firebase/client.js';
import { login } from '../../src/apis/auth.js';
import { getCoursesByExternalId, joinCourse } from '../../src/apis/courses.js';
import { getPaymentIntents } from '../../src/apis/stripe.js';
import CustomButton from '@/components/CustomButton.vue';

export default {
    name: 'LoginComponent',
    emits: ['goToPage', 'goToPreviousPage'],
    props: {
        isFromAdvertisement: {
            type: Boolean,
            required: true
        },
        isInModal: {
            type: Boolean,
            default: false
        },
        course: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            email: '',
            password: '',
            isEmailValid: true,
            isPasswordValid: true,
            isLoginError: false,
            isLoginComplete: false,
            submitted: false, // Track if the form has been submitted
            errorMessage: ''
        };
    },
    mounted() {
       
    },
    components: {
        CustomButton
    },
    computed: {
        ...mapState([
            'user'
        ]),
    },
    methods: {
        ...mapActions([
            'updateUserAction',
            'updateMyCoursesAction', 
            'updatePaymentsAction'
        ]),
        takeCourse() { 
            if (!this.user) {
                // console.log(`User not logged in! cannot join course.`);
                return
            }
            joinCourse(this.course.SK, this.user.uid)
                .then(() => {
                    this.getMyCourses();
                })
        },
        async signInWithGoogle() {
            try {
                const provider = new GoogleAuthProvider();
                const userCredential = await signInWithPopup(auth, provider);
                const user = userCredential.user;
                
                this.updateUserAction(user);
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('session', user.stsTokenManager.accessToken);
                
                this.isLoginComplete = true;
                this.isLoginError = false;

                this.emitter.emit("user-logged-in", { isLoggedIn: true });
                
                if (this.isInModal) {
                    this.takeCourse();
                } else {
                    setTimeout(() => {
                        this.$router.push('/dashboard');
                    }, 1000);
                }

            } catch (error) {
                // Handle any errors here
                // // console.log(`Sign in error: ${JSON.stringify(error.code)}`);
                this.isLoginComplete = false;
                this.isLoginError = true;
                this.errorMessage = error.message;
                setTimeout(() => {
                    this.errorMessage = '';
                }, 5000);
                this
            }
        },
        signInUser() { 

            login(this.email, this.password).then((response) => {
                
                localStorage.setItem('user', JSON.stringify(response));
                localStorage.setItem('session', response.stsTokenManager.accessToken);

                this.updateUserAction(response);

                this.isLoginComplete = true;
                this.isLoginError = false;

                this.emitter.emit("user-logged-in", { isLoggedIn: true });

                if (this.isInModal) {
                    this.takeCourse();
                } else {
                    setTimeout(() => {
                        this.$router.push('/dashboard');
                    }, 1000);
                }
            }).catch((error) => {
                // // console.log(`Sign in error: ${JSON.stringify(error)}}`)
                this.isLoginComplete = false;
                this.isLoginError = true;
                this.errorMessage = error.message;

                setTimeout(() => {
                    this.signinError = '';
                }, 5000);
            });
        },
        // Dupe code in dashboard view (TODO: refactor into a service)
        // Pulling my courses here after I join one.
        getMyCourses() { 
            if (!this.user) {
                // console.log(`User not logged in!`)
                return
            }
            getCoursesByExternalId(this.user.uid)
                .then((myCourses) => {
                    this.updateMyCoursesAction(myCourses);
                    this.getPaymentIntents();
                })
        },
        getPaymentIntents() { 
            if (!this.user) {
                return
            }
            getPaymentIntents(this.user.uid)
                .then((intents) => {
                    this.updatePaymentsAction(intents);
                    if (!this.$store.getters.didPurchaseCourse(this.course.SK)) { 
                        this.$emit('goToPage', 3);
                    } else {
                        this.$emit('goToPage', 5);
                    }
                });
        },
        submitForm() {
            this.submitted = true; // Set submitted to true when form is submitted
            this.validateForm();
            if (this.isEmailValid && this.isPasswordValid) {
                this.signInUser();
            } else {
                this.isLoginComplete = false;
                this.isLoginError = true;
                this.errorMessage = 'Please enter a valid email and password.';
            }
        },
        validateForm() {
            this.isEmailValid = this.validateEmail(this.email);
            this.isPasswordValid = this.validatePassword(this.password);
        },
        validateEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        validatePassword(password) {
            return password.length >= 6;
        }
    }
};
</script>

<style scoped>

/* SAME CSS AS REGISTER VIEW (REFACTOR LATER) */

.container {
    margin-top: 50px;
}

.btn-style {
    background-color: indigo; 
    color: white;
    font-size: 24px;
}

.fr { 
    float: right;
}

.card-title {
    font-size: 24px;
    width: 70%;
    margin: 0 auto;
    text-align: center;
}

label { 
    font-size: 18px;
    color: gray;
}

input { 
    font-size: 16px;
}

form { 
    margin-top: 20px;
}

.text-danger { 
    font-size: 18px;
}
.alert { 
    font-size: 18px;
}

.vertical { 
    display: flex;
    flex-direction: column;
    align-items:baseline;
    justify-content: left;
    margin-bottom: 20px;
}

input:focus {
    box-shadow:none;
}


/* END SAME CSS AS REGISTER VIEW (REFACTOR LATER) */

@media (prefers-color-scheme: dark) {
    .card {
        background-color: black;
        color: #fff;
    }
    input { 
        background-color: black;
        border: 1px solid #313030;
        color: white;
    }

    input:focus {
        background: black;
        border: 1px solid yellow;
        outline: none;
        color: yellow;
    }
}

</style>
