<template>
    <!-- {{  time  }} -->
    <div v-if="isTranscriptAvailable" class="transcriptClass" ref="transcriptRef">
      <!-- Render the transcript as a single paragraph -->
      <p v-for="(item, index) in transcriptItems" :key="index" :class="{'highlight': isHighlight(item)}">
        <!-- Use a span to wrap each sentence so it can be highlighted -->
        <span>{{ item.content }} </span>
      </p>
    </div>
    <div v-else class="empty-text"> No transcript available.</div>
  </template>
  
  <script>  
  import { mapGetters } from 'vuex';
  import videojs from 'video.js';

  export default {    
    name: 'TranscriptComponent',
    props: {
      transcript: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        debug: '',
        time: 0,
        transcriptItems: []
      };
    },
    created() {
      this.initializePlayer();
    },
    computed: {
        ...mapGetters(['getCurrentVideoTime']),

        isTranscriptAvailable() {
          return this.transcript ? true : false;
        }
    },
    methods: {
        initializePlayer() {

          // // console.log('Transcription:', this.transcript);

          if (this.transcript === '' || this.transcript === undefined) {
            // // console.log('Transcript is empty');
            return;
          }

          try {
            const transcriptJson = JSON.parse(this.transcript);
            this.transcriptItems = this.processTranscriptionData(transcriptJson.results.items);

            const myPlayer = videojs('video-player');
              myPlayer.on('timeupdate', () => {
                
                if (myPlayer) { 
                  const currentTime = myPlayer.currentTime();
                  this.updateTime(currentTime)
                }

              }); 
          } catch (error) {
              // Handle the error gracefully
              console.error('Error parsing JSON:', error);
              // Optionally, provide a fallback or default value
              // const transcriptJson = {}; // or any other suitable default value
          }
        },
        updateTime(currentTime) {
            this.time = currentTime
            this.scrollToHighlighted();
        },
        isHighlight(item) {
            return item.start_time <= this.time && item.end_time >= this.time;
        },
        scrollToHighlighted() {
            const highlighted = this.$refs.transcriptRef.querySelector('.highlight');
            if (highlighted) {
                highlighted.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        },
        processTranscriptionData(items) {
            let sentences = [];
            let sentence = { content: '', start_time: 0, end_time: 0 };
  
            items.forEach(item => {
                if (item.type === 'pronunciation') {
                    if (!sentence.content) {
                        sentence.start_time = parseFloat(item.start_time);
                    }
                    sentence.content += item.alternatives[0].content + ' ';
                    sentence.end_time = parseFloat(item.end_time);
                } else if (item.type === 'punctuation') {
                    sentence.content = sentence.content.trim() + item.alternatives[0].content;
                    if (['.', '!', '?'].includes(item.alternatives[0].content)) {
                        sentences.push({ ...sentence });
                        sentence = { content: '', start_time: 0, end_time: 0 };
                    }
                }
            });
    
            // Add the last sentence if it doesn't end with punctuation
            if (sentence.content.trim()) sentences.push({ ...sentence });
            return sentences;
        }, 
    }
  }

  </script>
  
  <style scoped>
  .transcriptClass {
    overflow-y: scroll;
    color: black;
    font-size: 18px;
    width: 100%; height: 100%; border: 0px solid green;
  }
  .highlight {
    background-color: turquoise;
    color: black;
  }

  @media (prefers-color-scheme: dark) {
    .transcriptClass {
      color: #FFF;
    }
  }
  </style>
  