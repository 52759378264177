// Import Vue and VueRouter to extend with routes
import { createRouter, createWebHashHistory } from 'vue-router'

// Pages 
import HomePage from './views/pages/home/HomePage.vue'
import SpotlightsPage from './views/pages/spotlights/SpotlightsPage.vue'
import RecentEpisodesPage from './views/pages/recent/RecentEpisodesPage.vue'
import SeriesPage from './views/pages/series/SeriesPage.vue'
import SeriesDetailPage from './views/pages/series/SeriesDetailPage.vue'
import SeriesViewerPage from './views/pages/series/SeriesViewerPage.vue'

// Content Views
import CourseListView from './views/CourseListView.vue'
import CourseDetailView from './views/CourseDetailView.vue'
import LearnView from './views/LearnView.vue'
import SnippetsView from './views/snippets/SnippetsView.vue'
import SnippetView from './views/snippets/SnippetView.vue'
import VideoView from './views/VideoView.vue' 
import AppleNews from './views/AppleNews.vue'
import HomeView from './views/HomeView.vue'
import HTMLEditorView from './views/HTMLEditorView.vue'
import TestView from './views/TestView.vue'
import TestView3 from './views/TestView3.vue'
import QuizView from './views/QuizView.vue'
import ProductsView from './views/ProductsView.vue'
import SwiftCheatSheet from './views/SwiftCheatSheet.vue'
import LandingView from './views/LandingView.vue'

// Authentication Views
import RegisterView from './views/RegisterView.vue'
import LoginView from './views/LoginView.vue'
import ForgotPasswordView from './views/ForgotPasswordView.vue'

// Dashboard Views
import DashboardView from './views/DashboardView.vue'

import { checkIfBetaFeatureIsEnabled } from './posthog'

// Create a new router
const router = createRouter({
    history: createWebHashHistory(),
    routes: [ 
        { 
            path: '/',
            component:  HomePage // LandingView, CourseDetailView
        },
        { 
            path: '/spotlights',
            component: SpotlightsPage
        },
        { 
            path: '/recent-episodes',
            component: RecentEpisodesPage
        },
        { 
            path: '/series',
            component: SeriesPage
        },
        { 
            path: '/series/:seriesId',
            component: SeriesDetailPage
        },
        { 
            path: '/series/:seriesId/viewer',
            component: SeriesViewerPage
        },
        { 
            name: 'Snippets',
            path: '/snippets',
            component: SnippetsView
        },
        { 
            path: '/snippets/:snippetId',
            component: SnippetView
        },
        { 
            path: '/swift-cheat-sheet',
            component: SwiftCheatSheet
        },
        { 
            path: '/products',
            component: ProductsView
        },
        { 
            path: '/course-list',
            component: CourseListView
        }, 
        { 
            path: '/course/:courseId',
            component: CourseDetailView
        }, 
        { 
            path: '/learn/:courseId',
            component: LearnView
        },
        { 
            path: '/register',
            component: RegisterView
        }, 
        { 
            path: '/login',
            component: LoginView
        },
        { 
            path: '/forgot-password',
            component: ForgotPasswordView
        },
        { 
            path: '/dashboard',
            component: DashboardView
        }, 
        {
            path: '/video',
            component: VideoView
        },
        {
            path: '/news',
            component: AppleNews
        },
        {
            path: '/home',
            component: HomeView
        }, 
        {
            path: '/css',
            component: TestView
        }, 
        { 
            path: '/test3',
            component: QuizView
        }, 
        { 
            path: '/test4',
            component: TestView3
        },
        { 
            path: '/html-editor',
            component: HTMLEditorView
        },
        { 
            path: '/landing',
            component: LandingView
        }
    ], 
    scrollBehavior() {
        // Smoothly scroll to the element
        const appElement = document.getElementById('app');
        const appPosition = appElement.getBoundingClientRect();

        // Calculate the desired top position with 80px padding
        const topPosition = appPosition.top + window.pageYOffset - 80;

        // Scroll to the calculated position smoothly
        window.scrollTo({ top: topPosition, behavior: 'smooth' });
    }
})

router.beforeEach(async (to, from) => {
    // const isBetaEnabled = await checkIfBetaFeatureIsEnabled();
    // if (isBetaEnabled) {
    //     return true
    // } else {
    //     if (to.path === '/') {
    //         return true
    //     } 
    //     if (to.path.includes('snippets')) {
    //         return true
    //     }
    //     if (to.path.includes('news')) {
    //         return true
    //     }
    //     if (to.path.includes('course')) {
    //         return false
    //     }
    //     if (to.path.includes('learn')) {
    //         return true
    //     }
    //     if (to.path.includes('spotlights')) {
    //         return true
    //     }
    //     if (to.path.includes('recent-episodes')) {
    //         return true
    //     }
    //     if (to.path.includes('series')) {
    //         return true
    //     }
    //     return { name: 'Snippets' }
    // }
    if (to.path === '/') {
        return true
    } else { 
        return false
    }
})

export default router;