<template>
    <footer class="footer">
      <div class="footer-content">
        <!-- <div class="footer-section">
          <h4>IOS APP</h4>
          <img src="@/assets/adam-small.png" alt="App Store Badge" />
        </div> -->
        <div class="footer-section">
          <h4>CONTACT</h4>
          <a href="/feedback">Send Feedback</a>
          <a href="/help">Get Help</a>
          <a href="/request">Request an Episode</a>
        </div>
        <!-- <div class="footer-section">
          <h4>LEGAL</h4>
          <a href="/privacy">Privacy</a>
          <a href="/terms">Terms</a>
        </div> -->
        <!-- <div class="footer-icon">
          <a href="https://support.example.com"><img src="@/assets/adam-small.png" alt="Support" /></a>
        </div> -->
      </div>
      <div class="footer-bottom">
        <p>© 2024 iheartswift. All rights reserved <span style="color: blue;">❤️</span>.</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'FooterComponent'
    // No data or methods needed for this static component
  };
  </script>
  
<style scoped>

@media (prefers-color-scheme: dark) {
  .footer {
      background-color: black;
      color: white;
  }
  .footer-section a {
    color: whitesmoke;
  }
  .footer-bottom {
    width: 100%;
    border-top: 1px solid white;
  }
}

@media (prefers-color-scheme: light) {
  .footer {
      background-color: #f5f5f538;
      color: black;
  }
  .footer-section a {
    color: #121212;
  }
  .footer-bottom {
    width: 100%;
    border-top: 1px solid #121212;
  }
}

h4 { 
  font-weight: bold;
}

.footer {
  padding: 2em 5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.footer-content {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.footer-section {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.footer-section h4 {
  margin-bottom: 1em;
}

.footer-section a {
  text-decoration: none;
  margin-bottom: 0.5em;
}

.footer-section a:hover {
  text-decoration: underline;
}

.footer-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-bottom {
  width: 100%;
  text-align: center;
  margin-top: 2em;
  padding-top: 1em;
}

@media (max-width: 768px) {
  .footer {
    justify-content: center;
  }

  .footer-content {
    justify-content: center;
  }

  .footer-section,
  .footer-icon {
    align-items: center;
    margin-bottom: 1em;
  }
}
</style>
  