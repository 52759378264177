<template>
  <div style="border: 0px solid green;">
    <div style="border: 0px solid red;" class="tab-content-container">
      <div class="tabs" style="height: 60px; margin-top: -5px; border: none;">
        <ul style="margin-top: 10px;">
          <li v-for="(tab, index) in tabs" :key="index" :class="{'selectedTab' : activeTab == index, 'notSelectedTab' : activeTab != index }">
            <a style="height: 60px;" @click="activeTab = index; onPressTab(index)"> {{ tab.title }}</a>
          </li>
        </ul>
      </div>
      <div>
        <!-- Episodes -->
        <div v-if="activeTab === 0" :style="mobileTabScrollStyle(false)" class="modules-container">
          <!-- Scrolling container -->
          <div v-for="mod in theCourse.modules">
            <ModuleTitleComponent :course="course" :moduleId="mod.SK" />
            <div @click="onSelectedEpisode(episode)" class="episode" :style="{ 
                            'background-color': this.theme === 'light' ? selectedEpisode && episode.SK === selectedEpisode.SK ? '#ECEEF4' : 'white' : selectedEpisode && episode.SK === selectedEpisode.SK ? 'black' : '#121212',
                            'border-bottom': this.theme === 'light' ? '1px solid #eceef4' : '1px solid black'
                        }" v-for="episode in mod.episodes">
              <img :src="episode.thumbnail" alt="Thumbnail" style="min-width: 70px; height: 50px; margin-left: -10px; border-radius: 5px; object-fit: cover; overflow: hidden;">
              <div class="episode-info">
                <div class="episode-title">{{ episode.title }}</div>
                <div class="episode-subtitle">{{ episode.subtitle }}</div>
                <span style="margin-top: 10px; margin-bottom: 5px;" v-if="completedVideoIds.includes(episode.SK)" class="tag is-primary">Completed</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Summary -->
        <div v-if="activeTab === 1">
          <p :style="mobileTabScrollStyle(true)" class="information">
            {{ selectedEpisode.information }}
          </p>
        </div>
        <!-- Transcript -->
        <div v-if="activeTab === 2">
          <div :style="mobileTabScrollStyle(true)"> 
            <TranscriptComponent :transcript="selectedEpisode.transcript" />
          </div>
        </div>
        <!-- Resources -->
        <div v-if="activeTab === 3">
          <!--   props:['isExpanded', 'isPictureInPicture', 'value'] -->
          <div :style="mobileTabScrollStyle(true)"> 
            <ResourcesComponent :is-expanded="false" :is-picture-in-picture="false" :value="selectedEpisode.resources" />
          </div>
        </div>
        <!-- Summary  Currently not available-->
        <div v-if="activeTab === 4">
          <div :style="mobileTabScrollStyle(false)">
            <!-- <NotesComponent :course-id="course.SK" :selected-episode="selectedEpisode" @show-signup="handleSignInToContinue" is-mobile="true" /> -->
            <p class="notes-p"> Please use desktop or tablet to view / add notes! </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TranscriptComponent from './TranscriptComponent.vue';
import ResourcesComponent from './ResourcesComponent.vue';
import NotesComponent from './NotesComponent.vue';
import ModuleTitleComponent from './ModuleTitleComponent.vue';

export default {
  props: ['onPressTab', 'course', 'selectedEpisode', 'notes', 'colors', 'currentNote', 'selectedColor', 'completedVideoIds', 'theme'],
  emits: ['onMobileSelectedEpisode'],
  components: {
    TranscriptComponent,
    ResourcesComponent,
    NotesComponent,
    ModuleTitleComponent,
  },
  data() {
    return {
      activeTab: 0,
      tabs: [
          { title: '🎬 Episodes' },
          { title: '📚 Summary' },
          { title: '💬 Transcript' },
          { title: '📝 Resources' },
          // { title: '🟢 Online'},
          // { title: '🤔 Q&A'} 
          // { title: '📝 Notes' } Add these three soon!
      ],
      theCourse: this.course,
      theSelectedEpisode: this.selectedEpisode,
      theNotes: this.notes,
      theCurrentNote: this.currentNote,
      theColors: this.colors,
      theSelectedColor: this.selectedColor,
      iFrameHeight: 0,
      availableHeight: 0,
    };
  },
  computed: {
    iFrameStyle() { 
      return { 
        height: `${this.iFrameHeight}px`, 
        width: '100%', 
        padding: '0', 
        margin: '0', 
        border: 'none', 
        borderRadius: '10px' 
      };
    }
  },
  watch: { 
    activeTab() { 
      // // console.log(`TabMobileComponent DEBUG: activeTab: ${this.activeTab}`); 
    }
  },
  methods: {
    mobileTabScrollStyle(addPadding) {
      const screenHeight = window.innerHeight;
      const availableHeight = screenHeight - 425;
      // // console.log(`TabMobileComponent DEBUG: screenHeight: ${screenHeight}`);
      this.availableHeight = availableHeight;
      return { 
        overflowY: 'auto', 
        height: this.availableHeight + 'px', 
        padding: addPadding ? '10px' : '0px',
        border: '0px solid red',
      }; 
    },
    findModuleIndexBySK(modules, targetSK) {
        // console.log(`Modules: ${JSON.stringify(modules)}`)
        // Find the index of a module where the SK field matches targetSK
        const index = modules.findIndex(module => module.SK === targetSK);

        // index will be -1 if no module matches the target SK
        return index + 1
    },
    showSignup() {
      // // console.log('showSignup');
    },
    backgroundColor() { 
      return this.theme == 'dark' ? 'black' : 'white'; 
    },
    onChangeNoteInput(e) {
      // // console.log('note changed', e);
      const note = e.target.value;
      this.theCurrentNote = note;
    },
    onSelectedEpisode(episode) {
      // // console.log(`onSelectedEpisode (Mobile Tab Component): ${episode.title}`);
      this.$emit('onMobileSelectedEpisode', episode);
      this.theSelectedEpisode = episode;
    },
    onAddNote() {
      this.theNotes.unshift({
        text: this.theCurrentNote,
        color: this.theSelectedColor,
      });
      // // console.log(`The Notes (Mobile Tab Component): ${this.theNotes}`);
      this.theCurrentNote = '';
    },
  },
  mounted() {
    // // console.log('TabMobileComponent DEBUG: mounted (Mobile Tab Component)');
    // // console.log(`TabMobileComponent DEBUG: The Colors (Mobile Tab Component): ${this.theColors}`);
    window.addEventListener('resize', () => {
      const screenHeight = window.innerHeight;
      const availableHeight = screenHeight - 425;
      // // console.log(`TabMobileComponent DEBUG: screenHeight: ${screenHeight}`);
      this.availableHeight = availableHeight;
    });
  },
};
</script>

<style scoped>

.course-list { 
  padding: 10px;
}

.mobile-episode-content .tabs-component {
  /* Add your styling here */
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-width: 320px;
  height: 100%;
  overflow-y: auto;
}

.notes-list { 
  overflow-y: auto;
  height: 45vh;
}

.mobile-episode-content .tabs-component .tabs {
  max-height: 45px;
  display: flex;
}

.tabs {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.tabs::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.mobile-episode-content .tabs-component .tabs ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-episode-content .tabs-component .tabs li {
  cursor: pointer;
}

.mobile-episode-content .tabs-component .tabs a {
  padding: 10px 20px;
  border-bottom: none;
}

.mobile-episode-content .tabs-component .tabs li.is-active a {
  background-color: white;
}

.mobile-episode-content .tabs-component .tab-content {
  padding: 10px;
  height: 100%;
}

.mobile-episode-content .tabs-component .tab-content .table-of-contents {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.episode {
  display: flex;
  padding: 15px;
  padding-left: 20px;
}

.episode .thumbnail {
  height: 40px;
  width: auto;
}

.episode:hover {
  cursor: pointer;
}

.episode-info {
  margin-left: 10px;
  margin-top: 0px;
}

.episode-title {
  font-size: 14px;
  font-weight: 500;
}

.episode-subtitle {
  font-size: 12px;
  font-weight: 400;
  color: #9e9e9e;
}

.color-picker {
  display: flex;
  margin-left: 10px;
}

.color-choice {
  width: 20px;
 
}

.notes-p { 
  color: black; 
  background: white;
  padding: 20px;
}

@media (prefers-color-scheme: dark) {

  .notes-p { 
    color: white;
    background: black;
    padding: 20px;
  }


  .tab-content-container { 
    background-color: black;
  }

  .information { 
    color: white;
    background-color: black;
  }

  .transcriptClass {
    color: white;
    background-color: black;
  }

  .selectedTab { 
    color: black;
    font-weight: bold;
  }

  .notSelectedTab { 
    color: white;
    font-weight: bold;
  }
  .tabs { 
    background-color: #121212;
  }

  .selectedTab { 
    background-color: yellow;
  }

  .modules-container { 
    background-color: #121212;
  }

  .module-title { 
    color: white;
  }

  .episode {
    background-color: #121212;
    border-bottom: 1px solid #121212;
  }

  .episode-title { 
    color: #ECEEF4;
  }

  .episode:hover { 
    background-color: #121212;
  }

  .selectedEpisode { 
    background-color: black;
  }
}


@media (prefers-color-scheme: light) {

  .selectedTab { 
    color: black;
    font-weight: bold;
  }

  .notSelectedTab { 
    color: black;
    font-weight: bold;
  }
  .tabs { 
    background-color: white;
    font-weight: bold;
  }

  .selectedTab { 
    background-color: #ECEEF4;
  }

  .modules-container { 
    background-color: white;
  }

  .module-title { 
    color: black;
  }

  .episode {
    background-color: white;
    border-bottom: 1px solid #ECEEF4;
  }

  .episode-title { 
    color: black;
  }

  .episode:hover { 
    background-color: #ECEEF4;
  }

  .selectedEpisode { 
    background-color: #ECEEF4;
  }
}

</style>
