<template>
    <div class="steps-container">
      <div 
        v-for="(module, index) in course.modules" 
        :key="index" 
        class="step" 
        :class="`step-${index + 1}`"
      >
        <div class="step-number" :style="{ backgroundColor: module.color }">
          <span style="font-weight: bold;">{{ index + 1 }}</span>
        </div>
        <div class="step-content">
          <h2>{{ module.title }}</h2>
          <p style="color: gray;">{{ module.subtitle }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'StepsComponent',
    props: { 
      course: {
        type: Object,
        required: true
      } 
    }
  };
  </script>
  
<style>

@media (prefers-color-scheme: dark) {
  .step {
    border: 1px solid #FFF;
  }
  .step-content h2 {
    color: white;
  }
  .step-number {
    color: black;
  }
}

@media (prefers-color-scheme: light) {
  .step {
    border: 1px solid #ECEEF4;
  }
  .step-content h2 {
    color: black;
  }
  .step-number {
    color: white;
  }
}

.steps-container {
  display: flex;
  flex-direction: column;
  margin-top: -10px;
}

.step {
  display: flex;
  align-items: center;
  padding: 20px;
  margin: 0px 0;
}

.step-number {
  font-size: 20px;
  width: 50px;
  height: 50px;
  min-width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  margin-right: 20px;
}

.step-content h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.step-content p {
  margin: 0px 0;
}

.step-content a {
  text-decoration: none;
  color: var(--baby-steps-foreground);
}

.step-1 .step-number { background-color: #FF5722; }
.step-2 .step-number { background-color: #2096F3; }
.step-3 .step-number { background-color: #4CAF50; }
.step-4 .step-number { background-color: #B217FF; }
.step-5 .step-number { background-color: #E91E63; }
.step-6 .step-number { background-color: #FFEB3B; }
.step-7 .step-number { background-color: #00BCD4; }
</style>
  