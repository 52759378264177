<template>
  {{ getClosestHeaderId }}
    <div class="sidebar">
      <ul>
        <li v-for="header in headers" :key="header.id" :class="{ active: activeHeader === header.id }" @click="navigateTo(header.id)">
          <span :style="{ marginLeft: `${(header.level.length - 1) * 20}px` }">{{ header.content }}</span>
        </li>
      </ul>
    </div>
  </template>
  
  <script>
import { mapGetters } from 'vuex';
  export default {
    props: ['headers', 'closestHeaderId'],
    data() {
      return {
        activeHeader: null,
      };
    },
    computed: {
      ...mapGetters(['getClosestHeaderId']),
    },
    mounted() {
    
    },
    methods: {
      navigateTo(id) {
        this.$emit('navigate', id);
        this.activeHeader = id; // Update active header on navigate
      },
    },
    watch: {
      closestHeaderId(newValue, oldValue) {
        // console.log(`[YYY] ${newValue}`);
      },
    },
  };
  </script>
  
  <style>
  .sidebar {
    width: 30%;;
    float: right;
    overflow: auto;
    height: 100vh;
  }
  .sidebar ul {
    list-style-type: none;
    padding: 0;
  }
  .sidebar li.active {
    font-weight: bold;
  }
  </style>