<template>
  <div>
    <home-title :title=title></home-title>
    <div class="ticker-container" ref="tickerContainer">
      <div class="ticker-feed">
        <a :href="newsItem.link" v-for="newsItem in newsData" :key="newsItem.id" class="news-item">
          <img v-if="newsItem.iconUrl" :src="newsItem.iconUrl" alt="News Image" class="news-image" />
          <span class="spotlight-content">{{ newsItem.title.split('-')[0] }}</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import HomeTitleComponent from './HomeTitleComponent.vue';
import axios from "axios";

export default {
  name: "TickerFeed",
  props: {
    title: {
      type: String,
      default: 'Ticker Feed'
    },
    feedUrl: {
      type: String,
      default: `${process.env.VUE_APP_BASE_URL}/news/apple/top25`
    }
  },
  components: {
    'home-title': HomeTitleComponent
  },
  data() {
    return {
      newsData: [],
      scrollSpeed: 1,
    };
  },
  computed: {
    duplicatedNewsData() {
      // Duplicate the news data for infinite scrolling
      return [...this.newsData, ...this.newsData];
    }
  },
  mounted() {
    this.fetchNews();
    this.startScrolling();
  },
  beforeDestroy() {
    clearInterval(this.scrollInterval);
  },
  methods: {
    async fetchNews() {
      // console.log(`fetchNews: ${this.feedUrl}`)
      try {
        const response = await axios.get(this.feedUrl);
        this.newsData = response.data.slice(0, 5); // Get the top 10 apps
      } catch (error) {
        console.error(error);
      }
    },
    startScrolling() {
      const ticker = this.$refs.tickerContainer;
      this.scrollInterval = setInterval(() => {
        if (ticker.scrollTop < ticker.scrollHeight - ticker.clientHeight) {
          ticker.scrollTop += this.scrollSpeed;
        } else {
          ticker.scrollTop = 0;
        }
      }, 20);
    },
  },
};
</script>

<style scoped>

.header-title { 
  text-align: left;
  color: yellow;
}

.ticker-container {
  overflow: hidden;
  white-space: normal;
  margin-top: 10px;
  position: relative;
}

.ticker-feed {
  display: flex;
  flex-direction: column;
}

.news-item {
  display: flex;
  align-items: center;
  padding: 10px;
  text-align: left;
  color: white;
}

.news-image {
  width: 30px; 
  height: 30px; 
  border-radius: 50%;
  margin-right: 10px;
}

.news-item:hover {
  background-color: rgba(0, 255, 255, 0.081);
}

.ticker-title {
  font-size: 14px;
  font-weight: bold;
}

.spotlight-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: 'SFMono-Bold', monospace;
}

@media (prefers-color-scheme: light) {
  .news-item {
    color: black;
  }
}

</style>
