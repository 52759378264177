//./plugins/posthog.js
import posthog from "posthog-js";

export default {
  install(app) {
    app.config.globalProperties.$posthog = posthog.init(
      process.env.VUE_APP_POSTHOG_API_KEY,
      {
        api_host: 'https://app.posthog.com',
        capture_pageview: false
      }
    );
  },
};
