<template>
  <div class="bg" style="float: left; position: relative; width: 100%; height: 100%;"> 
    <div class="container container-content" :style="{ 'height' : '100%', 'padding-top': '80px', 'width': windowWidth < 768 ? '100%' : '60%', 'margin': '0 auto', 'position': 'relative' , 'margin-bottom' : '100px'}"> 
      <dark-mode-button style="margin-left: -3px; margin-bottom: 20px;" @click="leave()" title="👈 Back" />
      <span class="spotlight-title"> 💡 Keyword Spotlights </span>
      <div style="margin-bottom: 20px;"> 
        <span style="font-weight: medium; font-size: 24px; color: gray;"> 
          In Swift, keywords are like the VIPs of the language – they're <span style="color: #ff79b2"> reserved words </span> with special meanings, so you can't just throw them around to name your variables or functions.
      </span>
      </div>
      <div class="spotlights-page">
        <div class="spotlights-grid">
          <div v-for="spotlight in spotlights" :key="spotlight.id" class="spotlight-item">
            <router-link :to="`/snippets/${spotlight.SK}`" class="spotlight-content">{{ spotlight.spotlight_title }}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DarkModeButton from '@/components/DarkModeButton.vue';

import { getSnippets } from '@/apis/snippets';

export default {
  data() {
    return {
      isLoading: false,
      spotlights: [
        { id: 1, title: 'Spotlight 1' },
        { id: 2, title: 'Spotlight 2' },
        { id: 3, title: 'Spotlight 3' },
        // Add more spotlights here
      ], 
      windowWidth: window.innerWidth
    };
  }, 
  components: {
    'dark-mode-button': DarkModeButton
  },
  methods: {
    fetchSpotlights() {
      this.isLoading = true;

      getSnippets()
        .then((response) => {
          this.spotlights = response.filter((spotlight) => spotlight.spotlight == true);
          this.isLoading = false;
        })
        .catch((error) => {
          // console.log(error);
          this.isLoading = false;
        });
    },
    leave() {
      this.$router.go(-1);
    }
  },
  mounted() {
    this.fetchSpotlights();
    window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
    });
  },
};
</script>

<style scoped>
.spotlights-page {
  height: 100%;
  overflow-y: scroll;
}

.spotlights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}

.spotlight-item {
  background-color: #f0f0f0;
  padding: 20px;
}

.spotlight-title {
  font-size: 14px;
  font-weight: bold;
}

.spotlight-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #ff79b2;
  font-family: 'SFMono-Bold', monospace;
  height: 100px;
}

.spotlight-item:hover {
  border: 2px solid #ff79b2;
}

.spotlight-title { 
  float: left; 
  position: relative; 
  width: 100%; 
  font-weight: bold; 
  font-size: 36px; 
  margin-bottom: 10px;
}


@media (prefers-color-scheme: dark) {
  .spotlight-item {
    background-color: black;
  }
  .spotlight-content {
    background: black;
  }
  .spotlight-title { 
    color: white;
  }
}

@media (prefers-color-scheme: dark) {
    .spotlight-item {
      border: 1px solid rgba(255, 255, 255, 0.211);
    }
    .bg { 
      background-color: black;
    }
}

@media (prefers-color-scheme: light) {
    .spotlight-item {
      border: 1px solid #f5f5f5;
      background-color: #f5f5f5;
    }
    .spotlight-title { 
      color: black;
    }
    .bg { 
      background-color: white;
    }
}

</style>