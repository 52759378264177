const axios = require('axios');

const createPaymentConfig = async () => { 
  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.VUE_APP_BASE_URL}/payment/config`,
    headers: { 
      'Content-Type': 'application/json'
    },
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const createPaymentIntent = async (courseId, userId) => {
  const data = JSON.stringify({
    "paymentMethodType": "card",
    "currency": "usd", 
    "courseId": courseId,
    "userId": userId
  });
  let config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: `${process.env.VUE_APP_BASE_URL}/payment/create`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const updatePaymentIntent = async (courseId, userId, intentId, paymentMethodType, currency, amount, status) => { 
  const data = JSON.stringify({
    "PK": `USER#${userId}`,
    "SK": `PAYMENTINTENT#${intentId}`,
    "courseId": courseId,
    "userId": userId,
    "paymentIntentId": intentId,
    "paymentMethodType": paymentMethodType,
    "currency": currency,
    "amount": amount,
    "status": status,
    "GSI2PK": `USER#${userId}`,
    "GSI2SK": `PAYMENTINTENT#${intentId}`,
  });
  const config = {
    method: 'patch',
    maxBodyLength: Infinity,
    url: `${process.env.VUE_APP_BASE_URL}/payment/update`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const getPaymentIntents = async (userId) => {
  let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_BASE_URL}/payment/intents/${userId}`,
      headers: { 
          'Content-Type': 'application/json'
      }
  };
  try {
      const response = await axios.request(config);
      return response.data;
  } catch (error) {
      throw error;
  }
}

export { 
    createPaymentConfig,
    createPaymentIntent,
    updatePaymentIntent, 
    getPaymentIntents
}