<template>
  <button @click="handleButtonClick" class="button">
      {{ buttonText }}
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',
  emits: ['click'],
  props: { 
    buttonText: {
      type: String,
      required: true,
    },
  },
  methods: {
    handleButtonClick() {
      this.$emit('click');
    },
  }
};
</script>

<style scoped>
@keyframes colorAndGlow {
  0% {
    background-color: #ff5722; /* Orange */
    box-shadow: 0 0 20px #ff5722; /* Orange */
  }
  14% {
    background-color: #2096f3; /* Dodger Blue */
    box-shadow: 0 0 20px #2096f3; /* Dodger Blue */
  }
  28% {
    background-color: #4caf50; /* Chateau Green */
    box-shadow: 0 0 20px #4caf50; /* Chateau Green */
  }
  42% {
    background-color: #b217ff; /* Electric Violet */
    box-shadow: 0 0 20px #b217ff; /* Electric Violet */
  }
  57% {
    background-color: #e91e63; /* Amaranth */
    box-shadow: 0 0 20px #e91e63; /* Amaranth */
  }
  71% {
    background-color: #ffeb3b; /* Gorse */
    box-shadow: 0 0 20px #ffeb3b; /* Gorse */
  }
  85% {
    background-color: #00bcd4; /* Robin's Egg Blue */
    box-shadow: 0 0 20px #00bcd4; /* Robin's Egg Blue */
  }
  100% {
    background-color: #ff5722; /* Back to Orange */
    box-shadow: 0 0 20px #ff5722; /* Back to Orange */
  }
}

.button {
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  overflow: hidden;
  animation: colorAndGlow 10s infinite alternate;
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}


.button:hover {
  transform: scale(1.1); /* Increase the scale to 110% of its original size */
  background-color: #e65a1c; /* Optional: Change background color on hover */
}

.rocket-icon {
  margin-left: 10px;
}

</style>
