<template>
        <div style="display:block; width: 100%;"> 
            <div id="notes-list" class="scrollable notes-container" :style="{ height: this.availableHeight }">
                <!-- {{ debug }} -->
                <div class="content" style="border: 0px solid blue;">
                    <div
                        v-if="notes.length > 0" 
                        id="item"
                        v-for="(note, index) in notes" 
                        :key="index" 
                        class="sticky-note" 
                        :style="{ 
                            'background': 'transparent', 
                            'color' : this.theme === 'light' ? 'black' : 'white',
                            'border-left': '5px solid ' + note.color,
                        }"
                        @mouseover="showCloseButton[index] = true"
                        @mouseleave="showCloseButton[index] = false">
                        <div style="position: relative;">
                            <div> 
                                <div style="display: flex; font-size: 12px; color: gray;"> 
                                    <div> 
                                        {{ formatTime(note.time) }} - {{ note.episodeTitle }}
                                    </div>
                                </div>
                                {{ note.text }}
                            </div>
                            <div class="close-button" @click="deleteNote(note.SK)" v-show="showCloseButton[index]">&#10006;</div>
                        </div>
                    </div>
                    <div v-else>
                        <p style="padding: 40px; color: gray; font-size: 24px;">No notes yet...😢 </p>
                    </div>
                </div>
            </div>
            <div> 
                <textarea
                    id="note-input"
                    placeholder="Add notes here..."
                    :value="currentNote"
                    @input="onChangeNoteInput"
                    :style="{ 'background-color': this.theme === 'light' ? '#eceef4' : 'black', 'color' : this.theme === 'light' ? 'black' : 'white' }"
                ></textarea>
                <div class="colors-container" :style="{ 'background-color': this.theme === 'light' ? '#ECEEF4' : 'black', 'color' : this.theme === 'light' ? 'black' : 'white' }"> 
                    <div class="color-picker">
                        <div v-for="color in colors" :class="{ 'active': color === selectedColor }" :style="{ backgroundColor: color }" class="color-choice">
                            <div @click="onColorSelect(color)" :style="{ width: '100%', height: '100%' }" data-color="color"></div>
                        </div>
                    </div>
                    <button 
                        class="button is-white" 
                        style="height: 30px; line-height: 1; margin-right: 10px;"
                        :style="{ 'background-color': this.theme === 'light' ? 'white' : 'black', 'color' : this.theme === 'light' ? 'black' : 'white' }"
                        @click="onAddNote()"
                    > 
                    Add Note 
                    </button>
                </div>
            </div>
        </div>
</template>

<script>
const axios = require('axios');
const uuid = require('uuid');

const { getNotes, postNote, deleteNote } = require('../apis/notes');

export default {
    props: {
        courseId: {
            type: String,
            required: true
        },
        selectedEpisode: {
            type: Object,
            required: true
        },
        isMobile: {
            type: Boolean,
            required: true
        },
    },
    emits: ['show-signup'],
    data() {
        return {
            debug: '',
            time: 0,
            isTablet: false,
            theme: 'light', // Default theme
            notes: [],
            currentNote: '',
            colors: ['#ff8a80', '#ffcc80', '#ffff8d', '#ccff90', '#a7ffeb', '#80d8ff', '#82b1ff'],
            selectedColor: '#ff8a80', 
            showCloseButton: [],
            innerWindowHeight: window.innerHeight
        };
    },
    created() {
        this.showCloseButton = new Array(this.notes.length).fill(false) // initialize array with false values
    },
    mounted() {

        setTimeout(() => {
            this.scrollToItem('notes-list', this.notes.length - 1);
        }, 1000);

        const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
    
        // Set the initial theme based on the user's preference
        this.handleThemeChange(prefersDarkScheme);
        
        // Listen for changes in the media query
        prefersDarkScheme.addListener(this.handleThemeChange);

        this.initializePlayer();
        this.debug = `Fetching notes...`
        this.fetchNotes();

        this.updateAvailableHeight();

        // Add event listener for resize event
        window.addEventListener('resize', this.updateAvailableHeight); 

        // Add event listener for orientation change event
        window.addEventListener('orientationchange', this.updateAvailableHeight);

        this.innerWindowHeight = window.innerHeight;
    },
    computed: {
        widgetContainer() {
            return 'position: fixed; bottom: 0px;'
        },

    },
    methods: {
        updateAvailableHeight() { 
            this.availableHeight = window.innerHeight;
        },
        initializePlayer() {
            const myPlayer = videojs('video-player');
            myPlayer.on('timeupdate', () => {
                const currentTime = myPlayer.currentTime();
                this.updateTime(currentTime)
            }); 
        },
        updateTime(currentTime) {
            this.time = currentTime
        },
        truncateTime(currentTime) { 

            const inputString = currentTime;

            // Find the position of the colon
            const colonIndex = inputString.indexOf(':');

            // If colon exists and there are more than 2 characters after it
            let result = inputString;
            if (colonIndex !== -1 && inputString.length > colonIndex + 3) {
                // Extract the substring after the colon and truncate it to two characters
                result = inputString.substring(0, colonIndex + 3); // +3 to include the colon and two characters after it
            }
            return result
        },
        formatTime(seconds) {
            // Extract integer part and fractional part of seconds
            const integerSeconds = Math.floor(seconds);
            const fractionalSeconds = seconds - integerSeconds;

            // Calculate hours, minutes, and remaining seconds
            const hours = Math.floor(integerSeconds / 3600);
            const minutes = Math.floor((integerSeconds % 3600) / 60);
            const remainingSeconds = integerSeconds % 60;

            // Format the time string
            // const formattedTime = `${hours.toString().padStart(2, '0')}h:${minutes.toString().padStart(2, '0')}m:${remainingSeconds.toString().padStart(2, '0')}s`;
            // const formattedTime = `${minutes.toString().padStart(2, '0')}m:${remainingSeconds.toString().padStart(2, '0')}s`;

            let formattedTime = '';

            if (hours) { 
                formattedTime = `${hours.toString().padStart(2, '0')}h:${minutes.toString().padStart(2, '0')}m:${remainingSeconds.toString().padStart(2, '0')}s`;
            } else if (minutes) { 
                formattedTime = `${minutes.toString().padStart(2, '0')}m:${remainingSeconds.toString().padStart(2, '0')}s`;
            } else { 
                formattedTime = `${remainingSeconds.toString().padStart(2, '0')}s`;
            }

            // If there are fractional seconds, append them to the formatted time string
            if (fractionalSeconds > 0) {
                const fractionalSecondsString = fractionalSeconds.toFixed(2).substring(1); // Format fractional seconds with three digits
                return `${formattedTime}:${fractionalSecondsString.replace(/\./g, '')}ms`;
            }
            return formattedTime;
        },
        handleThemeChange(e) {
          this.theme = e.matches ? 'dark' : 'light'; // Update the theme based on the media query matches
        },
        onColorSelect(color) {
            this.selectedColor = color;
        },
        onChangeNoteInput(event) {
            this.currentNote = event.target.value;
        },
        onAddNote() {

            if (!this.$store.state.user) {
                this.$emit('show-signup');
                return;
            }

            if (this.currentNote) {

                const note = { 
                    text: this.currentNote, 
                    color: this.selectedColor, 
                    time: this.time, 
                    episodeTitle: this.selectedEpisode.title,
                    createdAt: new Date().toISOString()
                }

                this.notes.push(note);
                this.currentNote = '';

                if (this.notes.length > 1) { 
                    setTimeout(() => {
                        this.scrollToItem('notes-list', this.notes.length - 1);
                    }, 100);
                }
                this.createNote(note.text, note.color, note.time, note.episodeTitle, note.createdAt);
            }
        }, 
        fetchNotes() {       
            if (!this.courseId || !this.$store.state.user) {
                return;
            }
            getNotes(this.courseId, this.$store.state.user.uid)
                .then((notes) => {
                    this.notes = notes.map((note) => { 
                        return { 
                            SK: note.SK,
                            text: note.text, 
                            color: note.color, 
                            time: note.time, 
                            episodeTitle: note.episodeTitle, 
                            createdAt: note.createdAt
                        }
                    })
                    this.notes.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

                    if (this.notes.length == 0) { 
                        this.notes.push({ 
                            text: "Welcome to Swift Fundamentals, so excited to have you here 🎉. Feel free to delete this note and jot down things you find important to remember 💡", 
                            color: "#B217FF", 
                            time: 0, 
                            episodeTitle: "Episode 1", 
                            createdAt: new Date().toISOString() 
                        });
                    }
                })
        },
        createNote(text, color, time, episodeTitle, createdAt) {
            postNote(this.courseId, this.$store.state.user.uid, text, color, time, episodeTitle, createdAt)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        deleteNote(noteId) { 
            this.notes = this.notes.filter((note) => note.SK !== noteId);
            deleteNote(noteId)
                .then((response) => {
                    // console.log(JSON.stringify(response.data));
                })
                .catch((error) => {
                    console.error(error);
                });
        },
        scrollToItem(containerId, itemIndex) {
            // Get the container div by its ID
            var container = document.getElementById(containerId);

            if (!container) { 
                // // console.log(`Container not found: ${containerId}`)
                return;
            }

            // Get all list items within the container
            var items = container.querySelectorAll('div#item'); // Assuming the list items are 'li' elements

            // Check if the specified item index is within range
            if (itemIndex < 0 || itemIndex >= items.length) {
                // // console.log('Item index is out of bounds');
                return;
            }

            // Get the specified item by its index
            var itemToScrollTo = items[itemIndex];

            // Calculate the position to scroll to
            // OffsetTop gives the distance of the element relative to the top of its offsetParent,
            // here we consider it's the container div if items are not positioned absolutely or fixed within the container
            var scrollPosition = window.innerWidth < 768 ? itemToScrollTo.offsetTop - 500 : itemToScrollTo.offsetTop;
            // // console.log(`Scrolling to position: ${scrollPosition}`)

            // Scroll the container to the desired item
            container.scrollTo({
                top: scrollPosition,
                behavior: 'smooth' // Optional: Adds smooth scrolling
            });
        }
    },
    beforeDestroy() {
    // Clean up the event listener when the component is destroyed
        window.matchMedia('(prefers-color-scheme: dark)').removeListener(this.handleThemeChange);
    },
};
 // Prevent pinch and zoom on touch devices
document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
});
</script>

<style scoped>
.scrollable {
    overflow-y: scroll;
    border: 1px solid #ccc; /* Just for visibility */
}

.content {
    margin-bottom: 300px; /* Extra space at the bottom */
}

textarea { 
    border-style: none; 
    border-color: Transparent; 
    overflow: auto; 
    box-shadow: none;
    height: 100px;
    width: 100%;
    resize: none;
    margin-bottom: -6px;
}

.notes-container { 
    width: 100%; 
    margin-top: 1px;
    border: 0px solid green;
    overflow: scroll;
    height: calc(100% - 151px)
}

@media (prefers-color-scheme: dark) {
    .notes-container { 
        background: #121212;
    }

    textarea { 
        background: #121212;
        color: white;
    }
}

.colors-container { 
    background-color: #eceef4; 
    min-height: 50px; 
    display: flex;
    justify-content: space-between; align-items: center;
    z-index: 1;
}
.sticky-note { /* Red color for the left border */
    width: 95%;
    background-color: white; /* Light yellow color typical of sticky notes */
    font-size: 16px;
    margin-left: 5px;
    margin-right: -5px;
    margin-top: 5px;
    padding-left: 5px;
}

.sticky-note p {
    margin: 0; /* Removes default paragraph margins */
}

.color-picker {
    display: flex;
    margin-left: 10px;
}

.color-choice {
    width: 20px;
    height: 20px;
    border-radius: 50%; /* Makes the span element circular */
    margin-right: 5px;
    cursor: pointer;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
}

.color-choice.active {
    border: 1px solid #000;
}

.close-button {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    display: none;
}
.sticky-note:hover .close-button {
    display: block;
}
</style>



