<template>
    <div class="home-section"> 
        <div v-if="isFlip"> 
            <div class="home-section-right" :style="{ width: isNarrowScreen ? `100%` : `70%` }">
                <CourseInfoTableV2 :module="module" :is-narrow-screen="isNarrowScreen" />
            </div>
            <div class="home-section-left" :style="{ width: isNarrowScreen ? `100%` : `30%` }">
                <section class="hero" :style="{ backgroundColor: tintColor }">
                    <div class="hero-body">
                    <p class="title" style="color: black;">{{ title }}</p>
                    <p class="subtitle" style="color: black;">{{ subtitle}} </p>
                    </div>
                </section>
            </div>
        </div>
        <div v-else> 
            <div class="home-section-left" :style="{ width: isNarrowScreen ? `100%` : `35%` }">
                <section class="hero" :style="{ backgroundColor: tintColor }">
                    <div class="hero-body">
                    <p class="title" style="color: black;">{{ title }}</p>
                    <p class="subtitle" style="color: black;">{{ subtitle}} </p>
                    </div>
                </section>
            </div>
            <div class="home-section-right" :style="{ width: isNarrowScreen ? `100%` : `65%`, height: isNarrowScreen ? `100%` : `280px`, marginTop: isNarrowScreen ? '40px' : '0px' }">
                <CourseInfoTableV2 :module="module" :is-narrow-screen="isNarrowScreen" :tintColor="tintColor" />
            </div>
        </div>
    </div>
</template>

<script>
import CourseInfoTableV2 from '../components/CourseInfoTableV2.vue';

export default {
    name: 'HomeSectionComponent',
    props: {
        module: { 
            type: Object,
            required: false
        },
        title: {
            type: String,
            required: true,
            default: 'Title'
        },
        subtitle: {
            type: String,
            required: true,
            default: 'Subtitle'
        }, 
        tintColor: {
            type: String,
            required: true,
            default: '#FFC008'
        }, 
        flip: { 
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        CourseInfoTableV2
    },
    data() { 
        return {
            windowWidth: window.innerWidth
        }
    },
    mounted() {
        // Add a resize event listener to update the window width
        // Collapses the menu when the window is resized
        // i.e user is in desktop but resizes to mobile then opens the menu
        // then resizes backout to desktop
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    },
    computed: { 
        isNarrowScreen() {
            // Check if the viewport width is less than 500px
            return this.windowWidth < 1100;
        }, 
        isFlip() {
            return this.isNarrowScreen ? false : this.flip;
        }
    }
}
</script>

<style scoped>

@media (prefers-color-scheme: dark) {
    .home-section {
        background: #121212;
    }
}

@media (prefers-color-scheme: light) {
    .home-section {
        background: #FFFFFF;
    }
}

.home-section {
    float: left; 
    position: relative; 
    width: 100%;
}

.home-section-left { 
    float: left; 
    position: relative; 
}

.home-section-right { 
    float: left; 
    position: relative; 
    margin-top: 20px;
}

.title {
  font-size: 72px; /* Adjust font size as necessary */
  margin-bottom: 30px; /* Adjust margin as necessary */
  font-weight: bold;
  color: var(--course-details-title);
  text-align: center;
}

.subtitle { 
  font-size: 48px;
  font-weight: bold;
  color: #fcff02; /* Red text color */
  text-align: center;
}

</style>