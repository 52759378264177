
const axios = require('axios')

// API: 
// online.routes.js
// online.controller.js
// online.model.js

exports.goOnline = (userId, courseId) => {
    return new Promise((resolve, reject) => {
        let data = JSON.stringify({
            "user_id": userId,
            "course_id": courseId
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.VUE_APP_BASE_URL}/online`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                // console.log(error);
                reject(error);
            });
    });
}

exports.goOffline = (userId, courseId) => {
    return new Promise((resolve, reject) => {
        let data = JSON.stringify({
            "user_id": userId,
            "course_id": courseId
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.VUE_APP_BASE_URL}/offline`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };

        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                // console.log(error);
                reject(error);
            });
    });
}

exports.getOnlineForCourseWithId = (courseId) => {
    return new Promise((resolve, reject) => {
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.VUE_APP_BASE_URL}/online/${courseId}`, // Use courseId in the URL
            headers: { }
        };
        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                resolve(response.data); // Resolve the promise with the response data
            })
            .catch((error) => {
                // console.log(error);
                reject(error); // Reject the promise with the error
            });
    });
};


