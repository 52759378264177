<template>
  <div class="bg" style="float: left; position: relative; width: 100%; height: 100%;"> 
    <div class="container container-content bg" :style="{ 'height' : '100%', 'padding-top': '100px', 'width': windowWidth < 768 ? '100%' : '58%', 'margin': '0 auto', 'position': 'relative' , 'margin-bottom' : '100px'}"> 
      <div class="button-container"> 
          <dark-mode-button style="margin-left: -3px; width: 100px;" @click="leave()" title="👈 Back" />   
          <span style="margin-top: 20px;" class="spotlight-title"> 📺 Recent Episodes </span>
          <div style="margin-bottom: 20px;"> 
            <span style="font-weight: medium; font-size: 24px; color: gray;"> 
              Welcome to the Swift <span style="color: #ff79b2"> content playground </span> of videos and fun things that we try when we code with the Swift programming language.
            </span>
          </div>
      </div>
      <div class="block"> 
        <router-link :to="`/snippets/${episode.SK}`" v-for="episode in recentEpisodes" :key="episode.SK" class="episode-card">
          <div class="episode-thumbnail" :style="{ backgroundImage: 'url(' + episode.episode_thumbnail + ')' }"></div>
          <div class="episode-details">
            <p class="episode-title">{{ episode.title }}</p>
            <p class="episode-subtitle">{{ episode.subtitle }}</p>
            <div class="watch-button">
              <router-link :to="`/snippets/${episode.SK}`" class="watch-link">Watch</router-link>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HomeTitleComponent from '@/components/home/HomeTitleComponent.vue';
import SeeAllButtonComponent from '@/components/SeeAllButtonComponent.vue';
import DarkModeButton from '@/components/DarkModeButton.vue';
import { getSnippets } from '@/apis/snippets';

export default {
  data() {
    return {
      snippets: [],
      windowWidth: window.innerWidth
    };
  },
  components: {
    'home-title': HomeTitleComponent,
    'see-all-button': SeeAllButtonComponent,
    'dark-mode-button': DarkModeButton
  },
  computed: {
    recentEpisodes() {
        return this.snippets
            .filter(snippet => snippet.is_episode === true)
            .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    }
  }, 
  mounted() {
    this.fetchSnippets();
    window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    fetchSnippets() {
      getSnippets()
        .then((response) => {
          this.snippets = response;
        });
    }, 
    leave() {
      this.$router.go(-1);
    }
  },
};
</script>

<style scoped>

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.episode-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

@media (min-width: 768px) {
  .episode-card {
    flex-direction: row;
  }
}

.episode-thumbnail {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

@media (min-width: 768px) {
  .episode-thumbnail {
    width: 200px;
    height: 200px; /* Ensure height is set */
    flex-shrink: 0;
  }
}

.episode-details {
  flex: 1;
  padding: 20px;
  background: var(--episode-details-background);
  box-sizing: border-box;
  width: 100%;
  height: 200px;
  border-radius: 10px;
}

.episode-title {
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  color: var(--episode-details-title);
}

.episode-subtitle {
  font-weight: bold;
  font-size: 18px;
  margin: 0px 0;
  color: var(--episode-details-subtitle);
}

.watch-button {
  display: inline-block;
  width: auto;
  background: #FF79B2FF;
  text-align: center;
  padding: 8px 16px;
  font-size: 16px;
  margin-top: 20px;
  border-radius: 5px;
}

.watch-link {
  text-decoration: none;
  color: white;
  font-weight: bold;
}

.button-container { 
  margin-bottom: 20px;
}

.bg {
  height: 100%;
}

@media (prefers-color-scheme: dark) {
  .bg {
    background: black;
  }
  .episode-card {
    border: 1px solid rgba(255, 255, 255, 0.211);
    border-radius: 10px;
  }
  .spotlight-title { 
    color: white;
  }
}

@media (prefers-color-scheme: light) {
  .bg {
    background: white;
  }
  .episode-card {
    border: 1px solid rgba(255, 255, 255, 0.211);
  }
}

.spotlight-title { 
  float: left; 
  position: relative; 
  width: 100%; 
  font-weight: bold; 
  font-size: 36px; 
  margin-bottom: 10px;
}

</style>
