import axios from 'axios';

const getAllSeries = async () => {
  return new Promise((resolve, reject) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url:   `${process.env.VUE_APP_BASE_URL}/series`,
        headers: { },
    };
    axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // console.log(error);
        reject(error)
      })
  })
}

const getSeries = async (seriesId) => {
  return new Promise((resolve, reject) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url:   `${process.env.VUE_APP_BASE_URL}/series/${seriesId}`,
        headers: { },
    };
    axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // console.log(error);
        reject(error)
      })
  })
}

export {
  getAllSeries,
  getSeries,
}