<template>
    <div>
        <form id="payment-form">
            <div id="payment-element"></div>
            <button style="float: right; margin-top: 20px;" class="button is-primary textStyle">Make a payment to continue watching 💪</button>
        </form>
        <div id="checkout"></div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { createPaymentConfig, createPaymentIntent, updatePaymentIntent, getPaymentIntents } from '../apis/stripe.js'

export default {
    name: 'StripePaymentComponent',
    props: {
        courseId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isDarkMode: false, 
            stripeStyles: {
              base: {
                // Default styles for light mode
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                  color: "#aab7c4"
                }
              },
              invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
              }
            },
            stripeClientSecret: '',
        };
    },
    computed: {
        ...mapState(['user'])
    },
    mounted() {

      this.updateTheme(); // Initial theme check
      // Add event listener if 'matchMedia' is supported
      if (window.matchMedia) {
        window.matchMedia('(prefers-color-scheme: dark)').addListener(this.themeChangeListener);
      }

      // // console.log(`StripePaymentComponent mounted with courseId: ${this.courseId}`)
      this.domLoaded();
    },
    methods: {
        ...mapActions(['updatePaymentsAction']),
        updateTheme() {
          // Check if the browser supports 'matchMedia'
          if (window.matchMedia) {
            // Use matchMedia to check if the dark mode is preferred
            this.isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
          }
        },
        // Listener for changes in the color scheme
        themeChangeListener(event) {
          this.isDarkMode = event.matches;
        },
        domLoaded() { 
            if (!this.courseId) {
                return;
            }
            createPaymentConfig(this.courseId).then((config) => {
                const publishableKey = config.publishableKey;
                const stripe = window.Stripe(publishableKey)
                createPaymentIntent(this.courseId, this.$store.state.user.uid).then((intent) => {
                  const clientSecret = intent.clientSecret;
                  const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
                  const appearance = {
                    theme: isDarkMode ? 'night' : 'stripe'
                  };
                  const elements = stripe.elements({ clientSecret,  appearance});
                  const paymentElement = elements.create('payment');
                  paymentElement.mount('#payment-element');

                  const form = document.getElementById('payment-form');
                  const checkout = document.getElementById('checkout');
                  form.addEventListener('submit', async (e) => {
                    e.preventDefault();
                    const { error, paymentIntent } = await stripe.confirmPayment({
                      elements,
                      confirmParams: {
                        return_url: window.location.href.split('?')[0] + "complete.html"
                      },
                      redirect: "if_required",
                    });
                    if (error) {
                      const errorCode = error.code;
                      checkout.innerHTML = `
                        <div style="margin-top: 20px;" class="notification is-danger">
                            <button class="delete"></button>
                            Your payment failed: ${errorCode}. Please try again. 😢
                        </div>
                      `;
                      setTimeout(() => {
                        checkout.innerHTML = '';
                      }, 5000);
                      if (!paymentIntent) {
                        return;
                      }
                      this.updatePaymentIntent(paymentIntent.id, paymentIntent.payment_method_types[0], paymentIntent.currency, paymentIntent.amount, paymentIntent.status);
                    } else if (paymentIntent && paymentIntent.status === "succeeded") {
                      this.$emit('goToPage', 4);      
                      if (!paymentIntent) {
                        return;
                      }
                      this.updatePaymentIntent(paymentIntent.id, paymentIntent.payment_method_types[0], paymentIntent.currency, paymentIntent.amount, paymentIntent.status);      
                    } else {
                      if (!paymentIntent) {
                        return;
                      }
                      this.updatePaymentIntent(paymentIntent.id, paymentIntent.payment_method_types[0], paymentIntent.currency, paymentIntent.amount, paymentIntent.status);
                    }
                  });
                })
            });
        }, 
        updatePaymentIntent(intentId, paymentMethodType, currency, amount, status) { 
          updatePaymentIntent(this.courseId, this.$store.state.user.uid, intentId, paymentMethodType, currency, amount, status).then(() => {
            this.getPaymentIntents();
          });
        },
        getPaymentIntents() { 
          getPaymentIntents(this.$store.state.user.uid).then((intents) => {
            this.updatePaymentsAction(intents);
          });
        }
    },
    beforeDestroy() {
      // Remove the listener when the component is destroyed
      if (window.matchMedia) {
        window.matchMedia('(prefers-color-scheme: dark)').removeListener(this.themeChangeListener);
      }
    }
}
</script>

<style scoped>

/* Add darkmode styles */
@media (prefers-color-scheme: dark) {
  .textStyle {
    color: black;
    font-weight: bold;
  }
}
</style>
