import axios from "axios";

const getCourses = async () => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/cms/courses`);
    return response.data;
  } catch (error) {
    return error;
  }
}

const getCourseById = async (courseId) => {
  try {
    const response = await axios.get(`${process.env.VUE_APP_BASE_URL}/cms/courses/${courseId}`);
    return response.data;
  } catch (error) {
    return error;
  }
}

const getCoursesByExternalId = async (userId) => {
  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.VUE_APP_BASE_URL}/courses/${userId}`,
    headers: { 
      'Content-Type': 'application/json'
    },
  };
  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    return error;
  }
}

const joinCourse = async (courseId, userId) => { 

  const data = JSON.stringify({
    "PK": `COURSE#${courseId}`,
    "SK": `USER#${userId}`,
    "joinedAt": new Date().toISOString(),
    "GSI1PK": `USER#${userId}`,
    "GSI1SK": `COURSE#${courseId}`,
    "progress": 0
  });

  const config = {
    method: 'patch',
    maxBodyLength: Infinity,
    url: `${process.env.VUE_APP_BASE_URL}/courses/join`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    return error;
  }
}

const completeCourse = async (courseId, userId) => {

  const data = JSON.stringify({
    "PK": `COURSE#${courseId}`,
    "SK": `USER#${userId}`,
    "completedAt": new Date().toISOString(),
    "GSI1PK": `USER#${userId}`,
    "GSI1SK": `COURSE#${courseId}`,
    "progress": 100
  });

  const config = {
    method: 'patch',
    maxBodyLength: Infinity,
    url: `${process.env.VUE_APP_BASE_URL}/courses/complete`,
    headers: { 
      'Content-Type': 'application/json'
    },
    data : data
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    return error;
  }
} 

export {
  getCourses,
  getCourseById,
  getCoursesByExternalId, 
  joinCourse, 
  completeCourse
} 