<template>
  <section class="section" style="margin-top: 100px;">
    <div class="container">
      <div class="columns is-multiline is-variable is-8">
        
        <!-- First column for Apple download -->
        <div class="column is-one-third">
          <router-link :to="`/learn-swift`"> 
            <img src="../../public/swift-small.png" alt="Swift logo" />
          </router-link>
        </div>
        
        <!-- Second column for Windows download -->
        <div class="column is-one-third">
          <img src="../../public/swiftui-small.png" alt="Swift logo" />
        </div>
        
        <!-- Third column for Additional options -->
        <div class="column is-one-third">
          <img src="../../public/swift-data-small.png" alt="Swift logo" />
        </div>
        
      </div>
    </div>
    <div class="container" style="margin-top: 100px;">
      <div class="columns is-multiline is-variable is-8">
        
        <!-- First column for Apple download -->
        <div class="column is-one-third">
          <router-link :to="`/learn-swift`"> 
            <img src="../../public/Group 27.png" alt="Swift logo" />
          </router-link>
        </div>
        
        <!-- Second column for Windows download -->
        <div class="column is-one-third">
          <img src="../../public/Group 28.png" alt="Swift logo" />
        </div>
        
        <!-- Third column for Additional options -->
        <div class="column is-one-third">
          <img src="../../public/Group 29.png" alt="Swift logo" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DownloadSection',
  // Include any component data or methods here
}
</script>

<style scoped>

.title {
  font-size: 2rem;
  font-weight: bold;
  color: black;
}

/* Add any additional styling here */
.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 200px; /* Adjust height as needed */
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li:not(:last-child) {
  margin-bottom: 0.5rem;
}

/* Ensure to include the Bulma CSS file in your project */
</style>
