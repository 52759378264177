<template>
  <div>
    <home-title title="👩🏾‍💻 Trending iOS Repositories" />
    <ul>
      <li v-for="repo in displayedRepositories" :key="repo.id">
        <div>
          <i class="fa-brands fa-github" :class="{ 'white-text': darkMode }" style="margin-right: 5px;"></i>
          <a :href="repo.html_url" target="_blank" :class="{ 'white-text': darkMode }">{{ repo.full_name.split('/')[1] }}</a>
          <span style="margin-left: 10px; color: gray; font-size: 10px;">{{ repo.stargazers_count }}</span> ⭐️
        </div>
      </li>
    </ul>
    <div style="float: left; position: relative; width: 100%;">
      <button style="float: left; position: relative; padding: 5px; background: black; color: white;
      font-size: 12px; font-weight: bold; border-radius: 5px; margin-top: 10px;" @click="prevPage" :disabled="page === 1">Previous</button>
      <button style="float: left; position: relative; padding: 5px; background: black; color: white;
      font-size: 12px; font-weight: bold; border-radius: 5px; margin-left: 10px; margin-top: 10px;" @click="nextPage" :disabled="page * 5 >= totalResults">Next</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import HomeTitleComponent from './HomeTitleComponent.vue';

export default {
  data() {
    return {
      repositories: [],
      page: 1,
      totalResults: 0,
      darkMode: window.matchMedia('(prefers-color-scheme: dark)').matches, // Initialize based on system dark mode
    };
  },
  components: {
    'home-title': HomeTitleComponent,
  },
  computed: {
    displayedRepositories() {
      const startIndex = (this.page - 1) * 5;
      return this.repositories.slice(startIndex, startIndex + 5);
    },
  },
  async mounted() {
    await this.fetchRepositories();
    this.watchDarkMode(); // Listen for changes to system dark mode
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.watchDarkMode);
  },
  methods: {
    async fetchRepositories() {
      try {
        const response = await axios.get(
          'https://api.github.com/search/repositories',
          {
            params: {
              q: 'language:swift',
              sort: 'stars',
              order: 'desc',
              per_page: 50, // Fetching 50 results to allow for pagination
              page: this.page,
            },
          }
        );
        this.repositories = response.data.items;
        this.totalResults = response.data.total_count;
      } catch (error) {
        console.error('Error fetching repositories:', error);
      }
    },
    prevPage() {
      if (this.page > 1) {
        this.page--;
        this.fetchRepositories();
      }
    },
    nextPage() {
      if (this.page * 5 < this.totalResults) {
        this.page++;
        this.fetchRepositories();
      }
    },
    watchDarkMode() {
      this.darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    },
  },
  beforeDestroy() {
    window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.watchDarkMode);
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}
li {
  text-align: left;
  padding: 5px;
  font-family: 'SFMono-Regular', monospace;
}
.white-text {
  color: white;
}
</style>
