<template>
  <div class="module" :style="{ backgroundColor: moduleColor }">
      <span class="module-index" :style="{ backgroundColor: moduleColor }">{{ moduleIndex }}</span>
      <div style="margin-top: 0px; color: black;" class="module-title">{{ moduleTitle }}</div>
  </div>
</template>

<script>
export default {
  name: 'ModuleTitleComponent',
  props: {
    course: {
      type: Object,
      required: true
    },
    moduleId: {
      type: String,
      required: true
    },
  }, 
  computed: {
    module() {
      return this.course.modules.find(module => module.SK === this.moduleId);
    },
    moduleIndex() {
      return this.findModuleIndexBySK(this.course.modules, this.moduleId);
    },
    moduleColor() { 
      return this.module.color;
    },
    moduleTitle() {
      return this.module.title;
    }
  },
  methods: {
    findModuleIndexBySK(modules, SK) {
      return modules.findIndex(module => module.SK === SK) + 1;
    }
  },
}
</script>

<style scoped>

.module {
  display: flex;
  align-items: center; /* Aligns the circle and title vertically */
  margin-top: 0px;
  margin-bottom: 0px;
  color: black;
}

.module-title {
  font-size: 18px;
  font-weight: 1000;
  margin-top: 10px;
  margin-bottom: 0px;
  padding-left: 10px;
}

.module-index {
  width: 30px; /* Circle size */
  height: 30px; /* Circle size */
  border-radius: 50%; /* Makes the background a circle */
  background-color: #3498db; /* Default background color; you can change it */
  display: flex;
  justify-content: center; /* Centers the text horizontally */
  align-items: center; /* Centers the text vertically */
  font-weight: bold; /* Optional: makes the index number bold */
  margin: 10px;
  font-size: 24px;
  margin-right: 0px; /* Spacing between the circle and the title */
}

</style>