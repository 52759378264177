<template>
  <!-- {{ getSummaryWidth}} -->
  <div v-if="isResourcesAvailable" class="markdown-body" :style="preview" v-html="compiledMarkdown"></div>
  <div v-else class="empty-text"> No resources available.</div>
</template>

<script>

import { mapGetters } from 'vuex';

import MarkdownIt from 'markdown-it';
import Prism from 'prismjs';
import 'prismjs/components/prism-swift'; // Import Swift language support
import 'prismjs/themes/prism.css';
import '../../public/markdown.css';
import 'prismjs/themes/prism-okaidia.css'; 

export default {
  name: 'ResourcesComponent',
  emits: ['input-updated'],
  props:['isExpanded', 'isPictureInPicture', 'value'],
  data() {
    return {
      input: this.value ? this.value : '',
      compiledMarkdown: '',
      theme: 'light', // Default theme
    };
  },
  computed: {
    ...mapGetters(['getSummaryWidth']),
    preview() {
      return {
        overflowY: 'auto',
        // height: this.isPictureInPicture ? this.checkIsIpad ? 'calc(100vh - 210px)' : 'calc(100vh - 140px)' : this.isExpanded ? '560px' : '360px',
        height: '100%',
        // width: this.getSummaryWidth < 500 ?  800 - 200 + 'px' : this.getSummaryWidth - 200 + 'px',
        width: '95%',
        border: '0px solid pink',
        margin: '0 auto',
        marginLeft: '2.5%',
        paddingBottom: '100px',
        paddingTop: '30px',
        background: this.theme === 'light' ? 'white' : 'black',
      };
    },
    checkIsIpad() { 
      var isIpad = /iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
      return isIpad;
    },
    isResourcesAvailable() {
      return this.value ? true : false;
    }
  },
  mounted() {

    // console.log(`[📱] Resources Component: Loading value ${this.value}`);

    this.updatePreview();

    // // console.log(`[❌] Markdown Editor: ${JSON.stringify(this.preview)}`);

    const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
    
    // Set the initial theme based on the user's preference
    this.handleThemeChange(prefersDarkScheme);
    
    // Listen for changes in the media query
    prefersDarkScheme.addListener(this.handleThemeChange);
  },
  methods: {
    updatePreview() {
      const md = new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true,
        breaks: true, // Enable conversion of line breaks
        highlight: function (str, lang) {
          if (lang && Prism.languages[lang]) {
            try {
              return Prism.highlight(str, Prism.languages[lang], lang);
            } catch (e) {
              console.error(`Failed to highlight code: ${e}`);
            }
          }
          return ''; // Fallback to no highlighting
        }
      });
      this.compiledMarkdown = md.render(this.input);
      // // console.log(`Compiled Markdown: ${this.compiledMarkdown}`); 
      this.$emit('input-updated', this.input);
    },
    handleThemeChange(e) {
      this.theme = e.matches ? 'dark' : 'light'; // Update the theme based on the media query matches
      // // console.log()
      this.updatePreview();
    },
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    window.matchMedia('(prefers-color-scheme: dark)').removeListener(this.handleThemeChange);
  },
  watch: { 
    value(newValue, oldValue) {
      // // console.log(`[M] Markdown Editor: ${newValue}`);
      if (newValue) { 
        this.input = newValue;
        this.updatePreview();
      } else { 
        this.input = '❌ No transcript available.';
        this.updatePreview();
      }
    },
  }
};
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.markdown-body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.markdown-body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

</style> 