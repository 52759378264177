<template>
    <button class="custom-button" @click="handleClick">
      <img v-if="imageSrc" :src="imageSrc" alt="Button Icon" class="button-icon">
      <span class="button-title">{{ title }}</span>
    </button>
  </template>
  
  <script>
  export default {
    name: 'CustomButton',
    props: {
      imageSrc: {
        type: String,
        required: false
      },
      title: {
        type: String,
        required: true
      },
      onClick: {
        type: Function,
        default: () => {}
      }
    },
    computed: {

    },
    methods: {
      handleClick() {
        // Execute the custom click handler
        this.onClick();
      }
    }
  }
  </script>
  
  <style scoped>
  .custom-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    background-color: #f0f0f0;
    color: #000;
  }
  
  .custom-button {
    background-color: black;
    border: 1px solid #313030;
    color: #fff;
  }

  .custom-button:hover { 
    font-weight: bold;
  }

@media (prefers-color-scheme: dark) {
  .custom-button {
    background-color: black;
    border: 1px solid #313030;
    color: #fff;
  }
}

@media (prefers-color-scheme: light) {
  .custom-button {
    background-color: white;
    border: 1px solid #ECEEF4;
    color: black;
  }
}

.button-icon {
  width: 24px; /* Adjust based on your icon size */
  height: 24px; /* Adjust based on your icon size */
  margin-right: 10px;
}

.button-title {
  font-size: 16px;
}
  </style>