<template>
  <div>
    <div class="title-container">
      <home-title :title="title" />
      <see-all-button :link="'/snippets'"></see-all-button>
    </div>
    <div> 
        <div v-if="isLoading" style="width: 100%; height: 100vh;">
            <spinner />
        </div>
        <div v-else> 
            <ul>
              <li v-for="snippet in recentSnippets()" :key="snippet.id">
                <router-link :to="`/snippets/${snippet.SK}`">
                  <snippet-row-view :snippet="snippet" />
                </router-link>
              </li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
import HomeTitleComponent from '@/components/home/HomeTitleComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import SnippetRowView from '@/components/SnippetRowView.vue';
import SeeAllButtonComponent from '@/components/SeeAllButtonComponent.vue';

export default {
  props: {
    title: {
      type: String,
      default: '🚀 Latest Posts'
    },
    snippets: {
      type: Array,
      default: []
    }
  },
  components: {
    'home-title': HomeTitleComponent,
    'spinner': SpinnerComponent, 
    'snippet-row-view': SnippetRowView, 
    'see-all-button': SeeAllButtonComponent
  },
  data() {
    return {
      isLoading: false
    };
  },
  mounted() {
    
  },
  methods: {
    recentSnippets() { 
      return this.snippets
        .filter(snippet => !snippet.spotlight)
        .filter(snippet => !snippet.is_episode)
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .slice(0, 4);
    }
  }
};
</script>

<style scoped>
li { 
  padding-bottom: 10px;
}
li:hover { 
  background-color: rgba(0, 255, 255, 0.081);
}
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>