<template>
  <div>
    <div class="title-container">
      <home-title title="📺 Recent Episodes"></home-title>
      <see-all-button :link="'/recent-episodes'"></see-all-button>
    </div>
    <div class="block" style="height: 200px;"> 
        <vue-carousel :data="recentEpisodeDivs"></vue-carousel>
    </div>
  </div>
</template>

<script>

import HomeTitleComponent from './HomeTitleComponent.vue';
import SeeAllButtonComponent from '../SeeAllButtonComponent.vue';

// Third party
import VueCarousel from '@chenfengyuan/vue-carousel';

export default {
  props: { 
    snippets: { 
      type: Array, 
      required: true
    }
  },
  components: {
    'home-title': HomeTitleComponent, 
    'vue-carousel': VueCarousel,
    'see-all-button': SeeAllButtonComponent
  },
  data() {
    return {
      // Your component's data properties go here
    };
  },
  computed: {
    recentEpisodes() {
        return this.snippets
            .slice()
            .filter(snippet => snippet.is_episode === true)
            .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
    }, 
    recentEpisodeDivs() {
      return this.recentEpisodes.map(episode => {
          return `
            <div style="
                margin: 0 auto; 
                position: relative; 
                width: 100%; 
                height: 200px; 
                color: white;
                border-radius: 3px;
            ">
                <div style="
                    position: absolute; 
                    top: 0; 
                    left: 0; 
                    width: 100%; 
                    height: 100%; 
                    background-image: url('${episode.episode_thumbnail}'); 
                    background-size: cover; 
                    background-position: center;
                    z-index: -1;
                    border-radius: 3px;
                "></div>
                <div class="overlay" style="
                    position: relative; 
                    padding: 20px; 
                    background: rgba(0, 0, 0, 0.7);
                    height: 100%;
                    box-sizing: border-box;
                    border-radius: 3px;
                ">
                    <p style="font-weight: bold; font-size: 36px; margin: 0;">${episode.title}</p>
                    <p style="font-weight: bold; font-size: 18px; margin: 0px 0;">${episode.subtitle}</p>
                    <div style="
                        display: inline-block;
                        width: auto; 
                        background: #FF79B2FF; 
                        text-align: center; 
                        padding: 8px 16px; 
                        font-size: 16px; 
                        margin-top: 20px;
                        border-radius: 5px;
                    ">
                        <router-link :to="\`/snippets/${episode.SK}\`" style="text-decoration: none; color: white; font-weight: bold;">Watch</router-link>
                    </div>
                </div>
            </div>
        `;
      });
    }
  },
  methods: {

  },
  mounted() {
  
  },
};
</script>

<style scoped>
.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>