<template>
      <div :style="iconStyle" class="animatedIcon">{{ currentIcon }}</div>
  <div 
  id="p-div"
  class="tabs-component" 
  :style="{ 
    'border-left' : this.theme === 'light' ? '1px solid #eceef4' : '0px',
    'border-right' : this.theme === 'light' ? '1px solid #eceef4' : '0px'
  }
  ">
    <div class="tabs">
      <ul>
        <li v-for="(tab, index) in tabs" :key="index" :class="this.theme === 'light' ? { 'is-active-light': activeTab === index } : { 'is-active-dark': activeTab === index}" :style="{ background: this.theme == 'light' ? 'white' : 'black'}">
          <!-- <a @click="activeTab = index; onPressTab(index)"> {{ index == 0 ? tab.title + `(${online.length})` : tab.title }} </a> -->
          <a style="font-size: 12px; font-weight: 500;" @click="activeTab = index; onPressTab(index)"> {{ tab.title }} </a>
        </li>
      </ul>
      <div class="action-link">
        <a :style="this.theme === 'light' ? 'color: black' : 'color: orange' " v-if="!isPictureInPicture" href="javascript:void(0)" @click="toggleTabs"> {{ isExpanded ? 'Collapse' : 'Expand'}}</a>
      </div>
    </div>
    <div class="tab-content" id="tab-content">
      <div v-if="activeTab === 0">
        <div :style="{ 
          border: `0px solid red`,
          padding: `20px`,
          fontSize: `18px`,
          height: pDivAvailableHeight + `px`,
          overflowY: `auto`
        }">
          <p v-if="selectedEpisode.information">
            {{ selectedEpisode.information }}
          </p>
          <p class="empty-text" v-else>
            No summary available.
          </p>
      </div>
      </div>
      <div v-if="activeTab === 1">
        <div :style="{ 
            border: `0px solid pink`,
            padding: `20px`,
            fontSize: `18px`,
            height: pDivAvailableHeight + `px`,
            overflowY: `auto`
          }"> 
          <TranscriptComponent 
            :transcript="selectedEpisode.transcript"
          />
         <!-- <div style="width: 100%; height: 100%; border: 10px solid green;"> </div> -->
        </div>
      </div>
      <div v-if="activeTab === 2" style="height: 100%;">
        <ResourcesComponent :isExpanded="isExpanded" :is-picture-in-picture="isPictureInPicture" :value="selectedEpisode.resources" /> 
      </div>
      <div v-if="activeTab === 3">
          <div :style="{ 
            border: `0px solid green`,
            padding: `20px`,
            fontSize: `18px`,
            height: pDivAvailableHeight + `px`,
            overflowY: `auto`
          }">
          <div class="user-row" style="border: 0px solid orange;" v-for="obj in online" :key="obj">
              <img v-if="JSON.parse(obj.user).photoURL" :src="JSON.parse(obj.user).photoURL" :alt="JSON.parse(obj.user).displayName" class="user-avatar">
              <img v-else src="../assets/avatars/noface-avatar.png" :alt="JSON.parse(obj.user).displayName" class="user-avatar">
              <span class="user-name">
                <p>{{ JSON.parse(obj.user).displayName }}</p>
              </span>
          </div>
          <!-- <div v-if="expandLeft && expandRight" style="position: absolute; bottom: 0px; height: 60px; width: calc(100% - 600px); margin-left: -20px; padding-left: 10px; padding-right: 10px;"> 
            <EmojiPickerComponent @emojiSelected="handleEmoji" />
          </div>
          <div v-if="expandLeft && !expandRight" style="position: absolute; bottom: 0px; height: 60px; width: calc(100% - 300px); margin-left: -20px; padding-left: 10px; padding-right: 10px;"> 
            <EmojiPickerComponent @emojiSelected="handleEmoji" />
          </div>
          <div v-if="!expandLeft && expandRight" style="position: absolute; bottom: 0px; height: 60px; width: calc(100% - 300px); margin-left: -20px; padding-left: 10px; padding-right: 10px;"> 
            <EmojiPickerComponent @emojiSelected="handleEmoji" />
          </div>
          <div v-if="!expandLeft && !expandRight" style="position: absolute; bottom: 0px; height: 60px; width: calc(100%); margin-left: -20px; padding-left: 10px; padding-right: 10px;"> 
            <EmojiPickerComponent @emojiSelected="handleEmoji" />
          </div> -->
        </div>
      </div>
      <div v-if="activeTab === 4">
        <QAComponent :style="{ 
          border: `0px solid blue`,
          fontSize: `18px`,
          height: pDivAvailableHeight + `px`,
          overflowY: `auto`,
          width: '100%'
        }"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import ResourcesComponent from './ResourcesComponent.vue';
import TranscriptComponent from './TranscriptComponent.vue';
import EmojiPickerComponent from './EmojiPickerComponent.vue'; // Adjust the path based on your file structure
import QAComponent from './QAComponent.vue'; // Adjust the path based on your file structure

import { getOnlineForCourseWithId } from '../apis/online.js'

export default {
  props: [
    'onPressTab', 
    'courseId',
    'selectedEpisode', 
    'toggleTabsContainer', 
    'expandLeft', 
    'expandRight', 
    'isPictureInPicture', 
    "isOnline"
  ],
  emits: ['toggledTabs'],
  data() {
    return {
      activeTab: 0,
      isExpanded: false,
      isFrameLoaded: false,
      theme: 'light', // Default theme
      currentTime: 0,
      windowInnerHeight: window.innerHeight,
      documentClientHeight: document.documentElement.clientHeight,
      pDivAvailableHeight: 0,
      online: [],
      interval: null,
      currentIcon: '',
      iconStyle: {
        display: 'none', // Hide icon initially
      },
      tabs: [
          { title: '📚 Summary' },
          { title: '💬 Transcript' },
          { title: '📝 Resources' },
          { title: '🟢 Online'},
          { title: '🤔 Q&A'}
      ],
    };
  },
  components: {
    ResourcesComponent,
    TranscriptComponent, 
    EmojiPickerComponent,
    QAComponent
  },
  methods: {
    handleEmoji(emoji) {
      // console.log('Selected emoji:', emoji);
      // Handle the selected emoji as needed
      this.currentIcon = emoji;

      // First, make the icon visible and apply the fadeIn animation
      this.iconStyle = {
        display: 'block', // Make icon visible
        animation: 'fadeIn 1s ease-in-out',
      };

      // After a delay, apply the fadeOut animation
      setTimeout(() => {
        this.iconStyle = {
          ...this.iconStyle,
          animation: 'fadeOut 1s ease-in-out',
        };
      }, 2000); // Adjust timing as needed

      // Optionally, hide the icon after the fadeOut animation completes
      setTimeout(() => {
        this.iconStyle = {
          ...this.iconStyle,
          display: 'none',
        };
      }, 3000); // This should match the total animation time
    },
    ...mapActions(['updateSummaryExpandedAction']),
    updatePDivHeight() { 
      // // console.log('Height test');
      var pDiv = document.getElementById('p-div')
      // // console.log(`P Div: ${pDiv.clientHeight}`);
      this.pDivAvailableHeight = this.isExpanded ? pDiv.clientHeight - 40 : pDiv.clientHeight - 40;
    },
    toggleTabs() {
        this.isExpanded = !this.isExpanded;
        this.updateSummaryExpandedAction(this.isExpanded);
        this.toggleTabsContainer();
        // // console.log(`Active tab: ${this.activeTab}`)
        
        setTimeout(() => {
          this.$emit('toggledTabs', this.isExpanded);
        }, 1000);

        setTimeout(() => {
          this.updatePDivHeight();
        }, 200);
    },
    handleThemeChange(e) {
      this.theme = e.matches ? 'dark' : 'light'; // Update the theme based on the media query matches
    },
    fireOnlineMethods() { 
      getOnlineForCourseWithId(this.courseId).then((response) => {
        this.online = response;
      }).catch((error) => {
        console.error(`Error getting online for course with id: ${this.courseId}`);
      });
    }
  },
  watch: {
      selectedEpisode(newValue, oldValue) {
        // // console.log(`Selected episode transcript: ${newValue.transcript}`)
        setTimeout(() => {
          this.isFrameLoaded = true;
        }, 1000);
        this.activeTab = 0;
      },
      isPictureInPicture(newValue, oldValue) {
        // // console.log(`Picture in picture: ${newValue}`)
        /// Need to wait for pip to get back into place before
        setTimeout(() => {
          this.isExpanded = false;
          this.updateSummaryExpandedAction(false);
          this.$emit('toggledTabs', false);
        }, 1000);
        setTimeout(() => {
          this.updatePDivHeight();
        }, 200);
      },
      isOnline(newValue, oldValue) {
        // // console.log(`Is online: ${newValue}`)
        if (newValue) {
          this.fireOnlineMethods();
        }
      },
  },
  mounted() {
    // // console.log('[D] mounted (Tab Component)');
    // // console.log(`[D] The Selected Episode (Tab Component): ${this.selectedEpisode}`);
    this.isExpanded = this.$store.getters.getSummaryExpanded;

    const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
    
    // Set the initial theme based on the user's preference
    this.handleThemeChange(prefersDarkScheme);
    
    // Listen for changes in the media query
    prefersDarkScheme.addListener(this.handleThemeChange);

    this.isExpanded = false;
    this.updateSummaryExpandedAction(this.isExpanded);

    this.updatePDivHeight();

    // this.interval = setInterval(() => {
    //   // Your repeated logic here, e.g., calling a method every second
    //     this.fireOnlineMethods();

    // }, 1000 * 10);  // 1000 milliseconds = 1 second

    // console.log(`WTF ${this.expandLeft} ${this.expandRight}`)
  },
  beforeDestroy() {
    // Clean up the event listener when the component is destroyed
    window.matchMedia('(prefers-color-scheme: dark)').removeListener(this.handleThemeChange);

    if (this.interval) {
      clearInterval(this.interval); // Clears the interval when the component is destroyed
    }
  },
}
 // Prevent pinch and zoom on touch devices
document.addEventListener('gesturestart', function (e) {
    e.preventDefault();
});
</script>

<style>
.tabs-component {
  /* Add your styling here */
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 300px;
  height: 100%;
  width: 100%;
}

.tabs, .tabs:not(:last-child) {
  margin-bottom: 0;
}

.tabs ul {
  max-height: 45px;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  border-bottom: none;
}

.tabs li {
  cursor: pointer;
}

.tabs ul li a {
  padding: 10px 20px;
  border-bottom: none;
}

.is-active-light a {
  background-color: #ECEEF4;
}

.is-active-dark a {
  background-color: yellow;
}

.is-active-dark {
  color: black;
}

.tab-content {
  height: 100%;
  width: 100%;
  overflow-y: hidden;
}

@media (prefers-color-scheme: dark) {

  .tabs-component {
    background-color: black;
  }

  .tab-content {
    background-color: black;
  }
}

@media (prefers-color-scheme: light) {
  .tab-content {
    background-color: white;
  }
}

/* Online user rows */ 

/* Styling for the row and its content */
.user-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* space between rows */
  padding-bottom: 10px;
  border-bottom: 1px solid #eceef412; /* border between rows */
}

.user-row:hover {
  background-color: var(--user-name-hover); /* highlight the row on hover */
}

.user-avatar {
  width: 40px; /* adjust size as needed */
  height: 40px; /* adjust size as needed */
  border-radius: 50%; /* makes the image circular */
  margin-right: 10px; /* space between the avatar and the name */
  margin-left: 10px;
  margin-top: 10px;
}

.user-name {
  font-size: 20px; /* adjust size as needed */
  font-weight: bold;
  margin-left: 5px;
  margin-top: 10px;
  color: var(--user-name-color); /* adjust color as needed */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(5);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(5);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.5);
  }
}

.animatedIcon {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(5);
  font-size: 2em; /* Adjust the icon size as needed */
  animation-fill-mode: forwards; /* Keeps the element in the final state of animation */
  /* Ensure the scaling is centered */
  transform-origin: center center;
}

</style>
