<template>
  <div>
    <div class="title-container">
      <home-title title="👀 Recent Spotlights"></home-title>
      <see-all-button :link="'/spotlights'"></see-all-button>
    </div>
    <div class="spotlight-container" ref="spotlightContainer">
      <div class="spotlight-feed">
        <router-link :to="`/snippets/${spotlightItem.SK}`" v-for="spotlightItem in sorted(filtered(snippets))" :key="spotlightItem.PK" class="news-item">
          <i class="fa-brands fa-swift" style="color: orange;"></i>
          <span class="spotlight-content">{{ spotlightItem.spotlight_title }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import HomeTitleComponent from './HomeTitleComponent.vue';
import SeeAllButtonComponent from '../SeeAllButtonComponent.vue';

export default {
  name: "RecentSpotlightsComponent",
  props: {
    snippets: {
      type: Array,
      default: []
    },
  },
  components: {
    'home-title': HomeTitleComponent, 
    'see-all-button': SeeAllButtonComponent
  },
  methods: {
    filtered(snippets) {
      return snippets.filter(snippet => snippet.spotlight === true);
    },
    sorted(snippets) {
      return snippets.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
    }
  },
};
</script>

<style scoped>

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  text-align: left;
  color: yellow;
}

.spotlight-container {
  overflow: hidden;
  white-space: normal;
  margin-top: 10px;
  position: relative;
}

.spotlight-feed {
  display: flex;
  flex-direction: column;
}

.news-item {
  display: flex;
  align-items: center;
  padding: 10px;
  text-align: left;
  color: white;
}

.news-image {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 10px;
}

.news-item:hover {
  background-color: rgba(0, 255, 255, 0.081);
}

.spotlight-title {
  font-size: 14px;
  font-weight: bold;
}

.spotlight-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #ff79b2;
  font-family: 'SFMono-Bold', monospace;
  padding-left: 10px;
}
</style>
