<template>
    <div v-if="isDataLoading">
        <SpinnerComponent :less-height="true" />
    </div>
    <div class="table-of-contents">
      <div class="episode" v-for="episode in firstThreeEpisodes" :key="episode.SK">
            <div style="float: left; position: relative;">
              <img 
            :src="episode.thumbnail" 
            alt="Episode thumbnail" 
            class="thumbnail"
            style="border-radius: 10px; border: none; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;"
            >
            </div>
            <div style="float: left; position: relative; width: 90%; margin-top: -15px; margin-left: 20px;">
              <div class="episode-content">
              <div class="episode-title">{{ episode.title }}</div>
              <div class="episode-information">{{ truncateSummary(episode.information) }}</div>
            </div>
            </div>
        </div>
        <p
        v-if="module != undefined && module.episodes.length > 0" 
        :style="{ 
            position: absolute, 
            bottom: `-30px`,
            paddingLeft: `10px`,
            fontSize: `14px`, 
            fontWeight: `bold`,
            // background: `black`,
            color: tintColor,
            // width: `200px`,
            // borderRadius: `10px`,
            paddingBottom: isNarrowScreen ? '20px' : ' 10px',
            marginTop: '-10px'
        }"> {{ module.episodes.length }} more... </p> <!-- subtract 3 -->
    </div>
</template>
  
<script>
  import SpinnerComponent from './SpinnerComponent.vue';
  const axios = require('axios');

  export default {
    name: 'TableOfContents',
    props: {
        module: { 
            type: Object,
            required: true
        },
        isNarrowScreen: {
            type: Boolean,
            required: true
        }, 
        tintColor: {
            type: String,
            required: true,
            default: '#FFC008'
        }
    }, 
    components: {
        SpinnerComponent
    },
    data() {
        return {
            windowWidth: window.innerWidth,
            isDataLoading: false
        }
    },
    mounted() {

        // Add a resize event listener to update the window width
        // Collapses the menu when the window is resized
        // i.e user is in desktop but resizes to mobile then opens the menu
        // then resizes backout to desktop
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });


        // console.log(`[CourseInfoTableV2.vue] - [Mounted] with ${JSON.stringify(this.module)}`)
    },
    computed: {
        isNarrowScreen() {
            // Check if the viewport width is less than 500px
            return this.windowWidth < 500;
        },
        firstThreeEpisodes() {
          if (this.module && Array.isArray(this.module.episodes)) {
            return this.module.episodes.slice(0, 3);
          }
          return [];
        }
    },
    methods: {
        truncateSummary(text, maxCharsPerLine = 80) {

          if (!text) return '';

          // Define the maximum length for two lines
          const maxLength = maxCharsPerLine * 2;

          // Check if the text is shorter than the maximum length
          if (text.length <= maxLength) {
            return text;
          }

          // Find the last space before the cutoff to avoid breaking words
          let cutoffPoint = text.lastIndexOf(' ', maxLength);
          
          // If there's no space, or the cutoff point is too early in the string, use the maximum length
          if (cutoffPoint === -1 || cutoffPoint < maxCharsPerLine) {
            cutoffPoint = maxLength;
          }

          // Return the truncated string with an ellipsis to indicate it's been shortened
          return text.substring(0, cutoffPoint) + '...';
        }
    },
  }
</script>
  
<style scoped>

/* height less than 768px */
@media (max-height: 768px) {

} 


/* height less than 768px */
@media (min-height: 768px) {

} 


/* Light theme styles */
@media (prefers-color-scheme: light) {

  .table-of-contents {
    background-color: #FFFFFF;
  }

  .module {
    margin-top: 40px;
  }

  h1 {
    color: #fff;
  }
  
  h2 {
    color: #040404;
  }

  .episode-title { 
    color: #525151;
  }

  .episode-duration {
    color: #aaa;
  }
  
  h3 {
    color: #ddd;
  }
  
  .episode-information {
    color: #676666;
  }
}

/* Dark theme styles */
@media (prefers-color-scheme: dark) {

  .table-of-contents {
    background-color: #121212;
  }

  .module {
    margin-top: 40px;
    border-bottom: 1px solid #333;
  }
  
  h1 {
    color: #fff;
  }
  
  h2 {
    color: #bbb;
  }
  .episode-title { 
    color: #ddd;
  }

  .episode-duration {
    color: #aaa;
  }
  
  h3 {
    color: #ddd;
  }
  
  .episode-information {
    color: #aaa;
  }
}

.table-of-contents {
  padding: 20px;
  font-family: 'Arial', sans-serif;
  margin: 0 auto;
  padding-top: 0px;
}

.table-of-contents-title {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--course-details-title);
}

.module {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

h2 {
  font-size: 24px;
  margin-bottom: 15px;
}

.episode {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.thumbnail {
  width: 120px;
  height: 60px;
  margin-right: 10px;
  padding: 0px;
  border-radius: 0px;
  border: none;
}

.episode-details {
  display: flex;
  align-items: left;
}

.episode-title { 
  font-size: 18px;
}

.episode-information { 
  font-size: 14px;
  height: 40px;
  overflow: hidden;
  white-space: wrap;
  text-overflow: ellipsis;
}

.episode-content {
  flex-grow: 1;
}

.episode-duration {
  font-size: 14px;
  margin-left: 10px;
}

h3 {
  font-size: 18px;
  margin: 0;
}

p {
  font-size: 14px;
}
</style>
  