<template>
    <div class="container container-content">
        <div class="card" style="position: relative; margin: 0 auto;">
            <div class="card-body">
                <LoginComponent :isInModal="false" :isFromAdvertisement="false" />
            </div>
        </div>
    </div>
</template>

<script>
import LoginComponent from '@/components/LoginComponent.vue';
import CustomButton from '@/components/CustomButton.vue';

import { mapState } from 'vuex';

export default {
    data() {
        return {
            // Initial width to store the current window width
            windowWidth: 0,
        };
    },
    components: {
        LoginComponent,
        CustomButton
    },
    name: 'LoginView',
    computed: { 
        ...mapState(['user']), 
        isMobile() {
            // Use navigator.userAgent to check the device type
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Regular expressions for mobile detection
            return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
        }, 
        isNarrowScreen() {
            // Check if the viewport width is less than 500px
            return window.innerWidth < 500;
        },
    },
    methods: {
        handleResize() {
            this.updateWindowWidth();
        },
        updateWindowWidth() {
            // Update windowWidth data property with the current window width
            this.windowWidth = window.innerWidth;
        }
    },
    beforeDestroy() {v
        // Remove event listener before the component is destroyed to clean up
        window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        if (this.user) {
            this.$router.push('/dashboard');
            return 
        }
        this.$store.state.isPresentingNavbar = true;

        this.updateWindowWidth();
        // Add event listener when the component is mounted
        window.addEventListener('resize', this.handleResize);
    },
};
</script>

<style scoped>

.btn-style {
    background-color: indigo; 
    color: white;
    font-size: 24px;
}

.fr { 
    float: right;
}

.card-title {
    font-size: 24px;
    width: 100%;
}

label { 
    font-size: 18px;
    color: gray;
}

input { 
    font-size: 16px;
}

form { 
    margin-top: 20px;
}

.text-danger { 
    font-size: 18px;
}
.alert { 
    font-size: 18px;
}

.vertical { 
    display: flex;
    flex-direction: column;
    align-items:baseline;
    justify-content: left;
    margin-bottom: 20px;
}

/* END SAME CSS AS REGISTER VIEW (REFACTOR LATER) */

</style>
