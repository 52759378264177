<template>
    <button v-if="buttonImage" @click="clickHandler">
      <img :src="buttonImage" class="button-size" alt="button image" />
    </button>
    <button v-else class="button-outline" @click="clickHandler">Get Started</button>
  </template>
  
  <script>
  export default {
    name: 'PixelButton',
    props: {
      buttonImage: String,
      text: String
    },
    methods: {
      clickHandler() {
        this.$emit('click');
      }
    }
  }
  </script>
  
<style scoped>

.button-size {
  width: 200px;
  height: 60px;
}

/* Button styles */
@keyframes colorCycle {
  0% { border-color: #ff5722; color: #ff5722; }
  14% { border-color: #2096f3; color: #2096f3; }
  28% { border-color: #4caf50; color: #4caf50; }
  42% { border-color: #b217ff; color: #b217ff; }
  57% { border-color: #e91e63; color: #e91e63; }
  71% { border-color: #ffeb3b; color: #ffeb3b; }
  85% { border-color: #00bcd4; color: #00bcd4; }
  100% { border-color: #ff5722; color: #ff5722; }
}

.button-outline {
  padding: 10px 20px;
  border: 5px solid #ff5722; /* Initial color */
  color: #ff5722; /* Initial text color */
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-weight: bold;
  border-radius: 4px;
  transition: background-color 0.3s;
  animation: colorCycle 10s infinite; /* Change the animation duration as needed */
  width: 200px;
  height: 60px;
}

.button-outline:hover {
  background-color: currentColor;
  color: #fff;
}
</style>