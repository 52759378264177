<template>
  <router-link v-if="snippet" :to="`/snippets/${snippet.SK}`">
    <home-title title="💡 Keyword Spotlight"></home-title>
    <div class="spotlight-content"> 
        {{ snippet.spotlight_title }}
    </div>
  </router-link>
</template>

<script>
import HomeTitleComponent from './HomeTitleComponent.vue';

export default {
  name: 'SwiftSpotlight',
  props: { 
    snippet: { 
      type: Object, 
      required: true
    },
  },
  components: {
    'home-title': HomeTitleComponent
  },
  data() {
    return {
      // Your data properties go here
    };
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>

/* Spotlight */ 

.spotlight-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Ensure the parent div has a defined height */
  font-size: 36px;
  padding: 20px;
  color: #FF79B2FF;
  font-family: 'SFMono-Bold', monospace;
  min-width: 200px;
  border-radius: 3px;
}

@media (prefers-color-scheme: dark) {
    .spotlight-content {
      border: 1px solid rgba(255, 255, 255, 0.211);
    }
}

@media (prefers-color-scheme: light) {
    .spotlight-content {
      border: 1px solid #f5f5f5;
      background-color: #f5f5f5;
    }
}

</style>