<template>
  <div class="custom-overlay" v-if="isModalVisible">
  <div class="custom-overlay-content">
      <header class="modal-card-head">
          <button v-if="currentPage == 2" aria-label="close" @click="this.currentPage = 1">👈</button>
          <p class="modal-card-title" style="text-align: center;">{{ getTitle(currentPage) }}</p>
          <button aria-label="close" @click="closeModal">❌</button>
      </header>
      <section class="modal-card-body">
          <div v-if="currentPage === 1">
            <p style="font-size: 24px; width: 100%; text-align: center; font-weight: bold; padding-bottom: 10px;">🎉 You've completed the course! 🎉</p>
            <img :src="course.step_gif" alt="Adam" style="width: 100%; border-radius: 10px;"/>
            <div style="margin-top: auto; display: flex; justify-content: space-between; padding-top: 10px;">
                <button class="button is-secondary">Leave some feedback 💡</button>
                <button class="button is-secondary" @click="startCourseAgain()">Restart Course ♻️</button>
            </div>
          </div>
          <div v-else-if="currentPage === 2">
              Page 2
          </div>
      </section>
  </div>
</div>
</template>

<script>

import { updateCompletedEpisodeIds, deleteLastWatchedEpisode, restartCourse } from '../apis/watch.js'
// Page 1 - Registration
// Page 2 - Log in 
// Page 3 - Stripe payment
// Page 4 - Success

export default {
  name: 'LearnCourseCompleteModal',
  emits: ['closeOnboardingModal', 'openOnboardingModal'],
  props: {
      initialPage: {
          type: Number,
          required: false
      },
      isModalVisible: {
          type: Boolean,
          required: true
      },
      course: {
          type: Object,
          required: true
      }
  },
  data() {
      return {
          currentPage: 1, // this.initialPage || 1
          windowHeight: window.innerHeight
      }
  },
  components: {

  },
  watch: {
      isModalVisible: function (newVal, oldVal) {
          //// console.log(`LearnOnboardingModal isModalVisible changed from ${oldVal} to ${newVal}`)
          if (newVal) {
              this.goToPage(this.initialPage) // Skip to stripe page if we have a user
          }
      },
  },
  methods: {
    async startCourseAgain() {
        // console.log('Restarting course', this.course.SK, this.$store.state.user.uid)
        await restartCourse(this.course.SK, this.$store.state.user.uid)
        this.closeModal()
        window.location.reload();
    },
    updatewindowHeight() {
        this.windowHeight = window.innerHeight;
    },
    getTitle(currentPage) { 
        switch (currentPage) {
            case 1:
                return '🥳 Congratulations!';
            case 2:
                return '👋 Welcome Back';
            default:
                return 'Unknown';
        }
    },
    closeModal() {
        this.$emit('closeCourseCompletedModal');
        this.currentPage = 1; 
    },
    nextPage() {
        if (this.currentPage < 4) {
            this.currentPage++;
        }
    },
    goToPage(pageNumber) {
        this.currentPage = pageNumber;
        if (pageNumber === 4 || pageNumber === 5) {
            setTimeout(() => {
                this.closeModal();
            }, 3000);
        }
    },
    previousPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
    }
  },
  mounted() {
      this.updatewindowHeight();
      window.addEventListener('resize', this.updatewindowHeight);
  }
};
</script>

<style>
/* Add a custom CSS class to style the modal background */
.custom-modal-background {
  background-color: rgba(35, 22, 118, 0.5); /* Change the alpha value (0.5) to control transparency */
}
.modal {
  /** 
      For some reason there are TailwindCSS variables overwriting styles in the css.
      I have searched the app and am not able to find where, if any, there is an import
      of tailwind css. For now just manually overwriting the value of the variable enables
      the modal overlay to be transparent.
  */
  --tw-bg-opacity: 0;
}

/* Dark Mode Styles */
@media (prefers-color-scheme: dark) {

/* Modal content area */
.modal-card, .modal-card-head, .modal-card-body, .modal-card-foot{
  background-color: black; /* Darker shade for the modal itself */
  color: #ffffff; /* Light text color */
  border: 1px solid #333333; /* Slightly lighter border color */
}

.modal-card-title { 
  color: white;
}

/* Header, Body, and Footer of the modal can also be customized if needed */
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  border-color: #333333; /* Slightly lighter border color */
}

/* You might also want to customize buttons or other elements within the modal */
}

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-overlay-content {
  background-color: #fff;
  margin: 20px;
  padding: 0px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto; /* Enable vertical scrolling if needed */
  max-height: 80%; /* Limit the height, ensuring it's scrollable */
  width: 500px; /* Adjust width as needed */
}

.modal-card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-card-body {
  padding: 20px;
}
</style>
