<template>
   <div style="margin-top: 20px;"> 
        <ul v-for="menuItem in menuItems"> 
            <li> 
                <button 
                    v-if="menuItem.name != 'Logo'"
                    @click="selectMenuItem(menuItem)"
                    style="padding-bottom: 60px; font-size: 36px;"
                > 
                    {{ menuItem.icon }} {{ menuItem.name }}
                </button>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'MobileMenuComponent',
    props: { 
        menuItems: Array,
        required: true
    },
    emits: ['selectMenuItem'],
    data() {
        return {
            // Your data properties go here
        };
    },
    methods: {
        selectMenuItem(menuItem) {
            this.$emit('selectMenuItem', menuItem);
        }   
    },
    mounted() {
        // console.log('MobileMenuComponent mounted', this.menuItems)
    },
};
</script>

<style scoped>

/* Code duplicated in NavigationBar.vue */

ul li { 
  list-style: none;
  font-weight: bold;
  padding: 5px;
  line-height: 2px;
}

button { 
    color: var(--md-sys-color-on-primary);
    font-weight: bold;
    transition: transform 0.3s ease; /* Smooth transition for the transform */
}

button:hover { 
    transform: scale(1.2);
}
</style>