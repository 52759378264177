<template>
    <TickerFeed :title="'Apple Top 25 Paid Apps'" :feedUrl="top25Url" />
    <!-- <TickerFeed :title="'Apple Top 10 Free Apps'" :feedUrl="`${process.env.VUE_APP_BASE_URL}/news/apple/top25Free`" />  -->
    <AppleDeveloperNews />
    <AppleDeveloperNewsCarousel />
</template>

<script>
import TickerFeed from '@/components/home/TickerFeed.vue';
import AppleDeveloperNews from '@/components/AppleDeveloperNews.vue';
import AppleDeveloperNewsCarousel from '@/components/home/AppleDeveloperNewsCarousel.vue';

export default {
    name: 'AppleNews',
    components: {
        TickerFeed,
        AppleDeveloperNews,
        AppleDeveloperNewsCarousel
    }, 
    mounted() {
        // console.log(`process.env.VUE_APP_BASE_URL: ${process.env.VUE_APP_BASE_URL}`)
    },
    computed: {
        top25Url() {
            return `${process.env.VUE_APP_BASE_URL}/news/apple/top25`
        }
    }
}
</script>

<style>
/* Add your custom styles here */
</style>
