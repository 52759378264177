<template>
    <div style="margin-top: 100px;">
        <circular-avatar
            :size="500"
            :progress-segments="[
                { color: '#027DFF', progress: 33.3 },
                { color: '#B217FF', progress: 33.3 },
                { color: '#FFF315', progress: 33.3 }
            ]"
            avatar-url="../assets/avatars/avatar.png"
        />
    </div>
</template>

<script>
import AvatarProgressComponent from '../components/AvatarProgressComponent.vue';

export default {
    name: 'TestView3',
    components: {
        'circular-avatar': AvatarProgressComponent
    }
}
</script>

<style scoped>
/* Your component's CSS styles go here */
</style>