<template>
    <div class="navbar" v-if="this.$store.state.isPresentingNavbar" :style="{ height: isOpen ? '100vh' : '70px' }">
      <!-- <div class="container-left" v-if="isNarrowScreen">
          <div style="position: fixed; top: 0;" v-if="isOpen"> 
            <button @click="openMenu" style="margin-top: 10px; font-size: 30px; font-weight: bold;">
                <i class="fa-solid fa-xmark"></i>
            </button>
            <MobileMenuComponent :menuItems="menuItems" @select-menu-item="handleSelectMenuItem" />
          </div>
          <div v-else>
              <div class="item">
                  <button @click="openMenu"> 
                      <i style="color: #2954FF; margin-right: 10px;" class="fa-solid fa-bars"></i> {{ iconForMenuItem(this.$store.state.mainMenuItemName) }} {{ this.$store.state.mainMenuItemName }}
                  </button>
              </div>
          </div>
      </div> -->
      <div v-if="isBetaEnabled" class="container-left">
          <div class="item" v-for="menuItem in menuItems">
              <div>
                  <button v-if="menuItem.name == 'Logo'">
                      <img class="heart" style="width: 40px;" :src="logo"/>
                  </button>
                  <button v-else
                   :style="{ 
                          marginTop: '10px'
                  }" @click="selectMenuItem(menuItem)"> 
                      {{ menuItem.icon }} {{ menuItem.name }}
                  </button>
              </div>
          </div>
      </div>
      <div v-else> 
        <div class="item" v-for="menuItem in menuItems">
              <div>
                <router-link :to="`/`" v-if="menuItem.name == 'Logo'">
                      <img class="heart" style="width: 40px;" :src="logo"/>
                  </router-link>
              </div>
          </div>
      </div>
      <div v-if="isBetaEnabled" class="container-right">
        <div style="margin-top: 0px; "> 
            <NavbarAvatar :is-logged-in="isLoggedIn"/>
        </div>
      </div>
      <!-- <ScrollProgressBar /> -->
    </div>
</template> 

<script>
import MobileMenuComponent from '@/components/MobileMenuComponent.vue';
import ScrollProgressBar from '@/components/ScrollProgressBar.vue';
import AvatarProgressComponent from '@/components/AvatarProgressComponent.vue';
import NavbarAvatar from '@/components/NavbarAvatar.vue';

export default {
  name: 'NavigationBar',
  data() { 
      return { 
        isBetaEnabled: false,
        windowWidth: window.innerWidth,
        isOpen: false,
        isProductMenuOpen: false,
        menuItems: [
            { name: 'Logo', icon: '@/assets/logos/rainbow-heart.png'},
            { name: 'iheartswift.com', icon: '⚡️' },
        //   { name: 'Courses', icon: '📱' },
        //   { name: 'Learning Paths', icon: '🎓' },
        //   { name: 'Books', icon: '📚' },
        //   { name: 'Test', icon: '🚀' }, 
        //   { name: 'CSS', icon: '🌎' }, 
        //   { name: 'Snippets', icon: '💙' },
        //   { name: 'HTMLPlayground', icon: '✌️' }
        ],
        isDarkMode: false,
      }
  },
  components: {
      MobileMenuComponent,
      ScrollProgressBar,
      AvatarProgressComponent,
      NavbarAvatar
  },
  mounted() {

    this.loadFeatureFlags();
      // Add a resize event listener to update the window width
      // Collapses the menu when the window is resized
      // i.e user is in desktop but resizes to mobile then opens the menu
      // then resizes backout to desktop
      window.addEventListener('resize', () => {
          this.windowWidth = window.innerWidth;

          if (this.windowWidth > 1100) {
              this.isOpen = false;
          }
      });

      this.updateMainSelectedMenuItem();
      window.addEventListener('popstate', (event) => {
          this.updateMainSelectedMenuItem();
      });

      this.checkDarkMode();
      this.watchDarkModeChange();
      this.updateTheme();
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.updateTheme)
  },
  computed: {
      logo() {
          return require('@/assets/logos/rainbow-heart.png');
      },
      avatarStyle() {
          // // console.log('App.vue avatarStyle: ' + this.$router.currentRoute.value.fullPath)
          if (this.isDashboard) {
              return {
                  border: `2px solid ${this.isDarkMode ? 'yellow' : '#7C3AED'}`,
                  marginRight: `0px`
              }
          } else {
              return {
                marginRight: `0px`
              }
          }
      },
      isDashboard() {
          const isDashboard = this.$router.currentRoute.value.fullPath === '/dashboard';
          if (isDashboard) {
              this.$store.state.mainMenuItemName = '';
          }
          return isDashboard;
      },
      isMobile() {
          // Use navigator.userAgent to check the device type
          const userAgent = navigator.userAgent || navigator.vendor || window.opera;

          // Regular expressions for mobile detection
          return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
      }, 
      isNarrowScreen() {
          // Check if the viewport width is less than 500px
          return this.windowWidth < 1100;
      },
      isLoggedIn() { 
          return this.$store.state.user ? true : false;
      }, 
      userPhotoURL() {
        if (!this.$store.state.user) { 
            return require('@/assets/avatars/noface-avatar.png');
        }

        if (this.$store.state.user.providerData.length === 0) { 
            return require('@/assets/avatars/noface-avatar.png');
        }

        return this.$store.state.user.providerData[0].photoURL ||  require('@/assets/avatars/noface-avatar.png')
      },
  },
  methods: { 
    loadFeatureFlags(){ 
        const posthog = this.$posthog;
        posthog.onFeatureFlags(() => {
            if (posthog.isFeatureEnabled('beta-feature')) {
                this.isBetaEnabled = true;
            } else {
                this.isBetaEnabled = false;
            }
        });
    },
    toggleDropdown() {
        this.isProductMenuOpen = !this.isProductMenuOpen;
    },
    updateTheme() {
          const colorMode = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
          document.querySelector("html").setAttribute("data-bs-theme", colorMode);
      },
      openMenu() { 
          // // console.log('Opening menu');
          this.isOpen = !this.isOpen;
      },
      selectMenuItem(menuItem) { 

          this.$store.state.mainMenuItemName = menuItem.name;
          this.isOpen = false;
        //   this.$router.push('/');

          switch(this.$store.state.mainMenuItemName) { 
            case 'iheartswift.com': 
                this.$router.push('/snippets');
                break;
            case 'Courses': 
                this.$router.push('/products');
                break;
            case 'Learning Paths': 
                this.$router.push('/products');
                break;
            case 'Books': 
                this.$router.push('/products');
                break;
            // case 'Learn': 
            //     this.$router.push('/course-list');
            //     break;
            // case 'Snippets': 
            //     this.$router.push('/snippets');
            //     break;
            default: 
                // console.log('Unknown menu item: ' + this.$store.state.mainMenuItemName)
          }
      }, 
      /// From MobileMenuComponent.vue
      handleSelectMenuItem(menuItem) { 
        this.selectMenuItem(menuItem);
      },
      updateMainSelectedMenuItem() { 
          const urlComponents = window.location.href.split('/')
          var name = urlComponents[urlComponents.length - 1];
          // // console.log('App.vue mounted: ' + urlComponents)
          if (name == '') { 
              name = 'home';
          }
          // // console.log('App.vue mounted: ' + name)
          const path = "/" + name
          switch (path) { 
              case '/home': 
                  this.$store.state.mainMenuItemName = 'Home';
                  break;
              case '/course-list': 
              this.$store.state.mainMenuItemName = 'Learn';
                  break;
              case '/test': 
                  this.$store.state.mainMenuItemName = 'Test';
                  break;
          }
      },
      iconForMenuItem(menuItem) { 
          switch(menuItem) { 
              case 'Home': 
                  return '⚡️';
              case 'Learn': 
                  return '📚';
              case 'Test': 
                  return '🚀';
          }
      },
      checkDarkMode() {
          // Check if the user prefers dark mode
          this.isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
          // // console.log('App.vue Dark mode: ' + this.isDarkMode);
      },
      watchDarkModeChange() {
          // Listen for changes in the color scheme preference
          window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
              this.isDarkMode = e.matches;
          });
      }
  }, 
  beforeDestroy() {
      // Remove the event listener when the component is destroyed
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.watchDarkModeChange);
  }
}
</script>

<style scoped>

@media (prefers-color-scheme: dark) {
    .navbar { 
        background-color: black;
        color: white;
    }
}

@media (prefers-color-scheme: light) {
    .navbar { 
        background-color: white;
        color: black;
    }
}

.navbar { 
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    border-radius: 0px;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    display: flex; /* Enables Flexbox */
    align-items: center; /* Vertically centers the child elements */
    justify-content: center;
}

img { 
    width: 50px; 
    height: 40px;
}

.container-left {
  position: fixed;
  left: 0;
  margin-left: 20px;
}

.container-center { 
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
}

.container-right {
  position: fixed;
  right: 20px;
  overflow: hidden;
}

/* Code duplicated in MobileMenuComponent.vue */

button { 
    color: var(--md-sys-color-on-primary);
    font-weight: bold;
    transition: transform 0.3s ease; /* Smooth transition for the transform */
}

button:hover { 
    transform: scale(1.2);
}

.item {
    float: left; 
    margin-right: 20px;
}

.item:last-child {
    margin-right: 0;
}

ul li { 
  list-style: none;
  font-weight: bold;
  padding: 5px;
  line-height: 2px;
}

.dropdown {
  width: 100%;
  height: 400px;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.dropdown.is-active {
  max-height: 400px; /* Adjusted for the sliding animation */
}

.dropdown {
  position: absolute;
  width: 100%;
  height: 400px;
  background-color: #f9f9f9; /* Example background color */
  z-index: 10;
  /* Initial state for the animation */
  transform: translateY(-100%);
  transition: transform 0.5s ease-in-out;
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s ease-in-out;
}

.slide-enter, .slide-leave-to /* .slide-leave-active in <2.1.8 */ {
  transform: translateY(-100%);
}

</style>
