<template>
      <div :style="{ 
            'padding-top': isNarrowScreen ? '110px' : '40px',
            'padding-bottom': '40px',
            'min-height': '100vh',
        }"> 
        <div style="height: 100%;" class="container">
            <div class="row" :style="{ 
                'padding-left': '20px', 
                'padding-bottom': '20px', 
                'padding-top': isNarrowScreen ? '0px' : '60px',
            }">
                <ul class="horizontal-list">
                    <button v-for="(item, index) in options" :key="index" @click="selectOption(index)" :style="{ color: this.selectedOption === this.options[index] ? isDarkMode ? '#ED1844' : 'gray' : isDarkMode ? 'white' : 'black'}">
                        <span style="font-size: 20px;">{{ iconForOption(item) }} {{ item }} {{ countForOption(item) }} </span>
                    </button>
                </ul>
            </div>
            <div class="columns is-multiline">
                <div class="column is-one-third" v-for="course in filterBySelectedOption(courses)" :key="course.id">
                    <CourseCardComponent 
                        :course="course"
                        :course-progress="getCourseProgress(course).progress" 
                    />
                </div>
            </div>
        </div>
      </div>
      <FooterComponent />
</template>

<script>
import { mapState, mapActions } from 'vuex'
import axios from 'axios';
import TickerFeed from '@/components/home/TickerFeed.vue';
import CourseCardComponent from '@/components/CourseCardComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import { getCourses } from '../apis/courses.js';
import { getCoursesProgress } from '../apis/watch.js'

export default {
    components: {
        TickerFeed,
        CourseCardComponent,
        FooterComponent
    },
    data() {
        return {
            // Initial width to store the current window width
            windowWidth: 0,
            coursesProgress: [],
            showCards: false,
            options: [ 'All Courses', 'Watched', 'Completed' ],
            selectedOption: 'All Courses',
            isDarkMode: false,
        };
    },
    computed: {
        ...mapState([
            'courses'
        ]),
        isMobile() {
            // Use navigator.userAgent to check the device type
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Regular expressions for mobile detection
            return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
        }, 
        isNarrowScreen() {
            // Check if the viewport width is less than 500px
            return this.windowWidth < 1100;
        },
    },
    beforeDestroy() {
        // Remove event listener before the component is destroyed to clean up
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        ...mapActions([ 
            'updateCoursesAction',
            'updateCoursesProgressAction'
        ]),
        didCompleteCourse(course) {
            // // console.log(`Checking if course is completed...`)
            const myCompletedCourses = this.$store.state.myCourses.filter((myCourse) => {
                return myCourse.progress === 100
            }).map((myCourse) => {
                return myCourse.pk.split('#')[1]
            });
            // // console.log(`My completed courses: ${JSON.stringify(myCompletedCourses)}`)
            if (myCompletedCourses.includes(course.SK)) {
                // // console.log(`Course is completed!`)
                return true
            }
            return 
        },
        enrollInCourse(course) {
            this.$router.push('/course/' + course.SK)
        },
        goToCourse(course) {
            this.$router.push('/learn/' + course.SK)
        },
        reset(course) {
            // // console.log(`Resetting course...`)
            // TODO: Reset course
        },
        fetchCourses() { 
            getCourses().then((courses) => {
                this.updateCoursesAction(courses.filter(course => course.published === true));
            }).catch((error) => {
                // console.log(error);
            });
        },
        fetchCoursesProgress() { 
            const user = this.$store.state.user;
            if (!user) {
                return
            }
            getCoursesProgress(user.uid).then((coursesProgress) => {
                this.coursesProgress = coursesProgress;
                this.updateCoursesProgressAction(coursesProgress);
            }).catch((error) => {
                // console.log(error);
            });
        },
        handleResize() {
            this.updateWindowWidth();
        },
        updateWindowWidth() {
            // Update windowWidth data property with the current window width
            this.windowWidth = window.innerWidth;
        },
        getCourseProgress(course) {
            if (!this.$store.state.user) { 
                return {}
            }
            const courseId = course.SK
            return this.$store.state.coursesProgress.find(p => p.courseId === courseId) || {}
        },
        selectOption(index) {
            this.selectedOption = this.options[index];
        },
        filterBySelectedOption(courses) {
            if (this.selectedOption === 'All Courses') {
                return courses
            } else if (this.selectedOption === 'Watched') {
                return courses.filter(course => this.getCourseProgress(course).progress > 0)
            } else if (this.selectedOption === 'Completed') {
                return courses.filter(course => this.getCourseProgress(course).progress === 100)
            }
        },
        countForOption(option) {
            if (option === 'All Courses') {
                return `(${this.$store.state.courses.length})`
            } else if (option === 'Watched') {
                return `(${this.filterBySelectedOption(this.$store.state.courses).filter(course => this.getCourseProgress(course).progress > 0).length})`
            } else if (option === 'Completed') {
                return `(${this.filterBySelectedOption(this.$store.state.courses).filter(course => this.getCourseProgress(course).progress === 100).length})`
            }
        },
        iconForOption(option) {
            if (option === 'All Courses') {
                return `📚`
            } else if (option === 'Watched') {
                return `👀`
            } else if (option === 'Completed') {
                return `✅`
            }
        },
        checkDarkMode(event) {
            this.isDarkMode = event ? event.matches : window.matchMedia('(prefers-color-scheme: dark)').matches;
        }
    },
    mounted() {
        // // console.log(`CourseListView.vue mounted!`)
        this.fetchCourses();
        this.fetchCoursesProgress();
        this.$store.state.isPresentingNavbar = true;
        this.updateWindowWidth();
        // Add event listener when the component is mounted
        window.addEventListener('resize', this.handleResize);
        this.isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.checkDarkMode);
    },
    beforeDestroy() {
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.checkDarkMode);
    },
};
</script>

<style scoped>

.selected {
  background-color: #007bff; /* Highlight color */
  color: white; /* Text color for the selected item */
}

/* Optional: Add some styles to your items */

.horizontal-list {
  list-style-type: none; /* Removes the default list style */
  padding: 0; /* Removes the default padding */
}

.horizontal-list button {
  display: inline-block; /* Makes list items inline with block formatting */
  margin-right: 20px; /* Adds space between the items */
}

/* Optional: Remove the margin for the last item */
.horizontal-list button:last-child {
  margin-right: 0;
}

.item-container {
  border: 1px solid #313030;
  padding: 0px;
  border-radius: 0px;
  background: white;
  margin-bottom: 20px; /* Space between items */
  height: 400px;
}

/* Adjust the .course-card-container */
.course-card-container {
  background-size: cover;
  background-position: center;
  position: relative; /* For positioning the progress bar at the bottom */ 
  height: 398px;
}

/* Remove the margin and max-width from .course-card */
.course-card {
  margin-left: 100px;
  height: 398px;
}

/* Remove the margin-left from .course-title if any */
.course-title {
  color: orange;
  font-size: 24px;
  margin-bottom: 10px;
}

/* Add media query for large devices where the container is wider than 1200px */
@media (min-width: 1200px) {
  .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 20px;
  }
  .course-title { 
    font-size: 32px;
    margin-bottom: 10px;
  }
  .instructor-name { 
    margin-top: -15px;
    margin-left: -10px;
  }
  .instructor-job { 
    margin-left: -10px;
  }
}

/* Light theme styles */
@media (prefers-color-scheme: light) {
    .course-card {
        background-color: rgb(255, 255, 255);
    }
    .course-title {
        color: rgb(5, 5, 5);
    }

    .course-description {
        color: black;
        height: 100px;
    }

    .instructor-name { 
        color: rgb(233, 30, 99);
    }

    .instructor-job { 
        color: rgb(108, 108, 108);
    }

    .course-timing {
        color: rgb(108, 108, 108);
    }

    .watch-course {
        background-color: #242424;
        color: white;
    }
}

/* Dark theme styles */
@media (prefers-color-scheme: dark) {
    .course-card {
        background-color: #121212;
    }


    .course-title {
        color:orange;
    }

    .course-description {
        color: white;
        height: 100px;
    }

    .instructor-name { 
        color: white;
    }

    .instructor-job { 
        color: rgb(108, 108, 108);
    }

    .course-timing {
        color: white;
    }

    .watch-course {
        background-color: #242424;
        color: white;
    }
}

@media (max-width: 1200px) {
  .container {
    margin-top: 0px;
  }
}

@media screen and (max-width: 1100px) {
  .course-card {
    margin-left: 0px;
  } 
}

.marginTop {
  margin-top: 20px;
}

.instructor-info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.instructor-info img {
    border-radius: 50%;
    margin-right: 10px;
    width: 50px;
    height: 50px;
}
.instructor-name {
    font-size: 18px;
}
.instructor-job {
    font-size: 18px;
}
.course-description {
    margin-bottom: 20px;
    text-overflow: ellipsis;
    overflow: hidden; 
    white-space: wrap;
}
.course-timing {
    font-size: 16px;
    margin-bottom: 20px;
}
.button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-right: 10px;
}

.continue-watching {
    background-color: #E91E63;
    color: white;
}

.continue-watching:hover {
    background-color: #bd184f;
  /* Other hover styles if necessary */
}

   /* ... other styles ... */
.progress-bar-container {
    width: 100%;
    background-color: #555;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 20px;
}
.progress-bar {
    height: 5px;
    background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    width: 0%; /* Initial state of the progress bar */
    transition: width 0.5s ease;
}
</style>
  ../apis/courses