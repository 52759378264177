<template>

    <LearnCourseCompleteModal
        :initial-page="1"
        :isModalVisible="isCourseCompletedModalVisible"
        :course="course"
        @closeCourseCompletedModal="closeCourseCompletedModal" 
    />

    <LearnOnboardingModal
        :course="course"
        :initialPage="page"
        :isFromAdvertisement="false"
        :isModalVisible="isOnboardingModalVisible" 
        :shouldPresentPayment="true"
        @openOnboardingModal="showOnboardingModal"
        @closeOnboardingModal="closeOnboardingModal" 
    />

    <div v-if="!isCourseDataLoaded" style="width: 100%; height: 100vh;">
       <SpinnerComponent />
    </div>

    <LearnComponent 
        :course="course" 
        v-if="isCourseDataLoaded" 
        @openOnboardingModal="showOnboardingModal"
        @showCourseCompletedModal="handleShowCourseCompletedModal"
    /> 
    
</template>

<script>

// Dependencies
import axios from 'axios';
import { computed, reactive } from 'vue';
import confetti from 'canvas-confetti';
import { mapState, mapActions } from 'vuex';
import { getCoursesByExternalId } from '../../src/apis/courses.js';

// Components
import LearnOnboardingModal from '@/components/LearnOnboardingModal.vue';
import LearnCourseCompleteModal from '@/components/LearnCourseCompleteModal.vue';
import LearnComponent from '@/components/LearnComponent.vue';
import HeartAnimationComponent from '@/components/HeartAnimationComponent.vue';
import SpinnerComponent from '@/components/SpinnerComponent.vue';

export default {
    name: 'LearnView',
    data() {
        return {
            page: 0, // 0 - Register, 1 - Log in, 2 - Stripe payment, 3 - Success Open LearnOnboardingModal to page
            isOnboardingModalVisible: false,
            isCourseCompletedModalVisible: false,
            isCourseDataLoading: false,
            isCourseDataLoaded: false,
            course: {},
        };
    },
    components: {
        LearnOnboardingModal, 
        LearnComponent,
        HeartAnimationComponent,
        SpinnerComponent,
        LearnCourseCompleteModal
    },
    methods: {
        ...mapActions([
            'updateMyCoursesAction'
        ]),
        launchConfetti() {
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        },
        handleShowCourseCompletedModal() {
            this.page = 1;
            this.isCourseCompletedModalVisible = true;
        },
        showOnboardingModal(page) {
            // console.log(`Showing onboarding modal...  passing page: ${page}`)
            this.page = page;
            this.isOnboardingModalVisible = true;
        },
        closeOnboardingModal() {
            this.isOnboardingModalVisible = false;
        }, 
        closeCourseCompletedModal() {
            this.isCourseCompletedModalVisible = false;
        }, 
        fetchCourse(courseId) { // TODO: Pass course ID as parameter
            // console.log(`Fetching course...`)
            this.isCourseDataLoading = true;
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: `${process.env.VUE_APP_BASE_URL}/cms/courses/${courseId}`,
                headers: { }
            };
            axios.request(config)
                .then((response) => {
                    this.course = response.data;
                    this.isCourseDataLoaded = true;
                    this.isCourseDataLoading = false;
                    this.isCourseDataLoaded = true;
                    this.selectedModule = this.course.modules[0];
                    this.selectedEpisode = this.course.modules[0].episodes[0];
                    // console.log(`Course: ${JSON.stringify(this.course)}`)
                    // // console.log(`Course modules: ${JSON.stringify(this.course.modules)}`)
                    // // console.log(`Course episodes: ${JSON.stringify(this.course.modules[0].episodes)}`)
                })
                .catch((error) => {
                    // console.log(`Error fetching course: ${error}`)
                    this.isCourseDataLoading = false;
                    this.isCourseDataLoaded = false;
                });
        }, 
        completeCourse() { 

            if (!this.$store.state.user) {
                // console.log(`User not logged in!`)
                return
            }

            let data = JSON.stringify({
                "PK": `COURSE#${this.course.SK}`,
                "SK": `USER#${this.$store.state.user.uid}`,
                "joinedAt": "2024-01-01",
                "completedAt": `${new Date().toISOString()}`,
                "GSI1PK": `USER#${this.$store.state.user.uid}`,
                "GSI1SK": `COURSE#${this.course.SK}`,
                "progress": 100
            });

            let config = {
                method: 'patch',
                maxBodyLength: Infinity,
                url: `${process.env.VUE_APP_BASE_URL}/courses/join`, // Updating progress here need to re-name endpoint
                headers: { 
                    'Content-Type': 'application/json'
                },
                data : data
            };

            axios.request(config)
            .then((response) => {
                    // console.log(JSON.stringify(response.data));
                    this.getMyCourses()
                })
                .catch((error) => {
                    // console.log(error);
                });
            },
        // Dupe code in dashboard view (TODO: refactor into a service)
        // Pulling my courses here after I join one.
        getMyCourses() { 
            if (!this.$store.state.user) {
                // console.log(`User not logged in!`)
                return
            }
            // console.log(`UPDATE MY COURSES BEING CALLED`)
            getCoursesByExternalId(this.$store.state.user.uid)
                .then((myCourses) => {
                    // console.log(`UPDATE MY COURSES BEING CALLED: ${JSON.stringify(myCourses)}`)
                    this.updateMyCoursesAction(myCourses);
                    this.isCourseCompletedModalVisible = true;
                })
        },
    },
    mounted() {
        this.$store.state.isPresentingNavbar = false;
        // console.log('LearnView mounted');
        const courseId = this.$route.params.courseId
        // console.log(`Received param Course ID: ${courseId}`)
        this.fetchCourse(courseId);
    },
}
</script>

<style scoped>

</style>
