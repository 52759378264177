<template>
  <div class="hero-container" :style="this.windowWidth < 1100 ? 'margin-top: 70px; padding-top: 20px;' : 'padding-top: 80px;'">
    <div class="content">
      <div>
        <img src="@/assets/logos/rainbow-heart.png" class="centered-image heart" />
      </div>
      <!-- <h1 class="title"> Junior is the new Senior 🚀 </h1> -->
      <h1 class="title">7 🔑 concepts</h1>
      <h2 class="subtitle"> Build any app you want </h2>
      <div class="buttons">
        <PixelatedButton text="Get Started" @click="getStarted" />
      </div>
    </div>
  </div>
  
  <!-- <HomeInfo2Component /> -->
  <BabyStepsComponent />
  <MainHeroTabComponent />
  <div style="float: left; position: relative; width: 100%; background-color: #121212; padding: 40px;">
    <HomeSectionComponent 
      :course-id="1" 
      :module-id="1" 
      :title="`1`"
      :subtitle="`App Development`"
      :tint-color="`#FF5722`" 
    />
    <HomeSectionComponent 
      :course-id="1" 
      :module-id="`1`" 
      :title="`2`"
      :subtitle="`Data modeling`"
      :tint-color="`#2096F3`"
    />
    <HomeSectionComponent 
      :course-id="1" 
      :module-id="`1`" 
      :title="`3`"
      :subtitle="`API Development`"
      :tint-color="`#4CAF50`" 
    />
    <HomeSectionComponent 
      :course-id="1" 
      :module-id="`1`" 
      :title="`4`"
      :subtitle="`Infrastructure`"
      :tint-color="`#B217FF`" 
    />
    <HomeSectionComponent 
      :course-id="1" 
      :module-id="`1`" 
      :title="`5`"
      :subtitle="`Security`"
      :tint-color="`#E91E63`" 
    /> 
    <HomeSectionComponent 
      :course-id="1" 
      :module-id="`1`" 
      :title="`6`"
      :subtitle="`Deployment`"
      :tint-color="`#FFEB3B`" 
    /> 
    <HomeSectionComponent 
      :course-id="1" 
      :module-id="`1`" 
      :title="`7`"
      :subtitle="`Monitoring`"
      :tint-color="`#00BCD4`" 
    /> 
</div>
<div style="float: left; position: relative; width: 100%;"> 
  <section class="hero is-primary">
  <div class="hero-body">
    <p class="title" style="color: black;">Buddy up 🤑</p>
    <p class="subtitle" style="color: black;">This was made for your crew</p>
    <img src="../../public/Group 16.png"> 
  </div>
</section>
</div>
<div style="float: left; position: relative; width: 100%;"> 
    <router-link :to="'/learn/1'"> 
      <div class="color-box" id="color-changing-div" :style="{ 
          float: left, 
          position: relative,
          width: '100%',
          paddingTop: '20px',
          backgroundColor: currentColor,    
        }">
          <div class="centered-div" :style="{ position: 'relative', width: windowWidth < 768 ? '100%' : '40%', margin: '0 auto', paddingRight: '40px'}"> 
          <img src="../../public/swift-bird-light.png" style="width: 80%; margin: 0 auto; background: transparent; margin-top: 40px; margin-bottom: 0px;"> 
          <span
            v-bind:style="emojiStyle"
            style="float: left; position: relative; width: 100%; text-align: center; padding-top: 40px; font-weight: bold; font-size: 100px;   text-shadow: 2px 2px;
      "
            >
            {{ currentEmoji }}
            </span>
            <span style="float: left; position: relative; width: 100%; text-align: center; padding-bottom: 0px; margin-top: -20px; font-weight: bold; font-size: 72px; color: white;"> Just tap! </span>
        </div>  
      </div>
  </router-link>
 </div>



<FooterComponent /> 
</template>

<script>

import PixelatedButton from '@/components/PixelatedButton.vue';
import HomeInfo2Component from '@/components/HomeInfo2Component.vue';
import MainHeroTabComponent from '@/components/MainHeroTabComponent.vue';
import BabyStepsComponent from '@/components/BabyStepsComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';
import CourseListView from './CourseListView.vue';
import CourseInfoTableV2 from '@/components/CourseInfoTableV2.vue';
import HomeSectionComponent from '@/components/HomeSectionComponent.vue';

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
      colors: ['#FF5722', '#2096F3', '#4CAF50', '#B217FF', '#E91E63', '#FFEB3B', '#00BCD4'],
      currentColorIndex: 0, // To track the current color
      emojis: ['☝️', '☝🏼', '☝🏽', '☝🏾', '☝🏿'],
      currentEmojiIndex: 0,
    }
  },
  computed: {
    currentColor() {
        return this.colors[this.currentColorIndex];
    },
    currentEmoji() {
        return this.emojis[this.currentEmojiIndex];
    },
  },
  mounted() {
      // // console.log(`CourseListView.vue mounted!`)
      setInterval(this.nextEmoji, 1000); // Change emoji every 1000ms (1 second)
     // Sets up an interval to change the currentColorIndex every second
      setInterval(() => {
        this.currentColorIndex = (this.currentColorIndex + 1) % this.colors.length;
      }, 1000);
        // Add a resize event listener to update the window width
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });

        this.$store.state.isPresentingNavbar = true;
    },
  components: {
    PixelatedButton,
    HomeInfo2Component,
    MainHeroTabComponent,
    BabyStepsComponent,
    HomeSectionComponent,
    CourseInfoTableV2,
    FooterComponent
  },
  methods: {
    browseCatalog() {
      // Logic to browse the catalog
    },
    getStarted() {
      this.$router.push('/learn/1');
    }, 
  }
}
</script>

<style scoped>

.color-box {
  /* Adjust the size of the div as needed */
  width: 100%;
  height: 100vh;
  transition: background-color 1s; /* Smooth transition between colors */
}

.centered-image {
  display: block;
  margin: 0 auto;
  max-width: 200px;
  width: 100%;
}

.hero-container {
  float: left; position: relative;
  background: url('@/assets/background_2.png') center center / cover no-repeat;
  background-color: #1A48FE;
  width: 100%;
  height: 50%;
  margin-top: 70px;
}

.hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  opacity: 0.8;
  pointer-events: none;
} 

.title {
  font-size: 64px; /* Adjust font size as necessary */
  font-family: 'PixelSplitter', sans-serif;                                                                                                                                        
  margin-bottom: 30px; /* Adjust margin as necessary */
  font-weight: bold;
  color: white;
  text-align: center;
}

.subtitle { 
  font-size: 48px;
  margin-bottom: 30px;
  font-family: 'PixelSplitter', sans-serif;                                                                                                                                        
  color: #16CDA5; /* Red text color */
  text-align: center;
  text-shadow: 2px 2px 2px rgba(255, 255, 255, 0.5);
}

.btn { 
  width: 300px;
  height: 80px;
  font-size: 48px;
  font-weight: bold;
}

.content {
  position: relative; /* Ensures content is above the overlay */
  max-width: 900px;
  margin: 0 auto;
  z-index: 1; /* Stack above the overlay */
}

.buttons {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  gap: 10px; /* Optional: adds space between the buttons */
  margin-top: 40px;
  margin-bottom: 50px;
}

.btn {
  background: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  margin: 5px;
  transition: all 0.3s ease;
}

.btn:hover {
  background: #ffffff;
  color: #000000;
}

.btn-primary {
  background: #8A2BE2; /* Adjust primary color as necessary */
  border-color: #8A2BE2;
}

.btn-primary:hover {
  background: #7B1FA2; /* Adjust on hover color as necessary */
}

</style>
