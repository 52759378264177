const axios = require('axios');
const uuid = require('uuid');

const postQuestion = async (payload) => {

  const { 
    id,
    courseId,
    user,
    text, 
    timestamp
  } = payload;

  return new Promise((resolve, reject) => {

    let data = JSON.stringify({
      "PK": `QUESTION#${id}`,
      "SK": `COURSE#${courseId}`,
      "user": JSON.stringify(user),
      "text": text,
      "upvotes": 0,
      "timestamp": timestamp,
      "GSI7PK": `COURSE#${courseId}`,
      "GSI7SK": `QUESTION#${id}`, 
      "GSI9PK": `COURSE#${courseId}`,
      "GSI9SK": `NOTIFICATION#${uuid.v4()}`
    });

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_BASE_URL}/qa`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // console.log(error);
        reject(error);
      });
  });
} 

const postAnswer = async (payload) => {

  const { 
    id,
    questionId,
    courseId,
    user,
    text, 
    timestamp, 
    to
  } = payload;

  return new Promise((resolve, reject) => {

    let data = JSON.stringify({
      "PK": `ANSWER#${id}`,
      "SK": `COURSE#${courseId}`,
      "user": JSON.stringify(user),
      "to": to,
      "text": text,
      "upvotes": 0,
      "timestamp": timestamp,
      "GSI8PK": `QUESTION#${questionId}`,
      "GSI8SK": `ANSWER#${id}`, 
      "GSI10PK": `USER#${JSON.parse(to).uid}`,
      "GSI10SK": `NOTIFICATION#${uuid.v4()}`
    });

    // console.log(`data: ${data}`);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_BASE_URL}/qa`,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // console.log(error);
        reject(error);
      });
  });
}

const questionsForCourse = async (courseId) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_BASE_URL}/qa/${courseId}`,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
    .catch((error) => {
        // console.log(error);
        reject(error);
      });
  });
}

const deleteQuestion = async (courseId, questionId) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_BASE_URL}/qa/question/${courseId}/${questionId}`,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
    .catch((error) => {
        // console.log(error);
        reject(error);
      });
  });
}

const deleteAnswer = async (courseId, answerId) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_BASE_URL}/qa/answer/${courseId}/${answerId}`,
      headers: {
        'Content-Type': 'application/json'
      }
    };
    axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
    .catch((error) => {
        // console.log(error);
        reject(error);
      });
  });
}

const upvoteQuestion = async (courseId, questionId) => {
  return new Promise((resolve, reject) => {
    
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_BASE_URL}/qa/upvote/question/${courseId}/${questionId}`,
      headers: { },
    };
    
    axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // console.log(error);
        reject(error);
      });
  });
} 

const upvoteAnswer = async (courseId, answerId) => {
  return new Promise((resolve, reject) => {
    
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_BASE_URL}/qa/upvote/answer/${courseId}/${answerId}`,
      headers: { },
    };
    
    axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // console.log(error);
        reject(error);
      });
  });
}

export { 
  postQuestion,
  postAnswer,
  questionsForCourse, 
  deleteQuestion,
  deleteAnswer,
  upvoteQuestion,
  upvoteAnswer
}