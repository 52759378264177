<template>
    <div class="bg" style="float: left; position: relative; width: 100%; height: 100%;"> 
        <div v-if="isLoading" style="width: 100%; height: 100vh; padding: 20px;">
            <SpinnerComponent />
        </div>
        <div v-else class="container container-content" :style="{ 'height' : '100%', 'padding-top': '80px', 'width': windowWidth < 768 ? '100%' : '60%', 'margin': '0 auto', 'position': 'relative' , 'margin-bottom' : '100px'}"> 
            <div class="button-container"> 
                <dark-mode-button style="margin-left: -3px; width: 100px;" @click="leave()" title="👈 Back" />
                <br />
                <span style="margin-top: 20px;" class="spotlight-title"> 🚀 Latest Posts </span>
                <div style="margin-bottom: 20px;"> 
                    <span style="font-weight: medium; font-size: 24px; color: gray;"> 
                    A collection of <span style="color: #ff79b2"> swift </span> related writings and tinkerings in the Swift programming language.
                    </span>
                </div>
            </div>
            <br />
            <router-link :to="`/snippets/${snippet.SK}`" class="columns" v-for="snippet in sorted(snippets)">
                <SnippetRowView style="width: 100%;" class="background-hover" :snippet="snippet" />
            </router-link>
        </div>
    </div>
</template>

<script>
import SpinnerComponent from '../../components/SpinnerComponent.vue';
import SnippetRowView from '@/components/SnippetRowView.vue';
import { getSnippets } from '@/apis/snippets';
import DarkModeButton from '@/components/DarkModeButton.vue';

export default {
    emits: ['snippet-pressed'],
    data() {
        return {
            snippets: [],
            isLoading: false, 
            windowWidth: window.innerWidth
        };
    },
    components: {
        SpinnerComponent, 
        SnippetRowView,
        'dark-mode-button': DarkModeButton
    },
    mounted() { 
        this.$store.state.isPresentingNavbar = true;
        this.capturePageView();
        this.fetchSnippets();

        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });
    },
    methods: {
        leave() {
            this.$router.go(-1);
        },
        sorted(snippets) { 
            // Sort by updated_at
            return snippets.sort((a, b) => {
                return new Date(b.updated_at) - new Date(a.updated_at);
            });
        },
        snippetPressed(snippet) {
            this.$emit('snippet-pressed', snippet);
        },
        fetchSnippets() { 
            this.isLoading = true;

            getSnippets()
                .then((response) => {
                    this.snippets = response;
                    this.isLoading = false;
                })
                .catch((error) => {
                    // console.log(error);
                    this.isLoading = false;
                });
        }, 
        capturePageView() {
            this.$posthog.capture('snippets_view', {
                'date': new Date().toISOString(),
            });
        }
    }
};
</script>

<style scoped>

/* Prefers light or dark mode */ 

@media (prefers-color-scheme: light) {
    .background-hover:hover { 
        background-color: rgba(0, 255, 255, 0.081);
    }
    .bg { 
        background: white;
    }
}

@media (prefers-color-scheme: dark) {
    .background-hover:hover { 
        background-color: rgba(0, 255, 255, 0.081);
    }
    .bg { 
        background: black;
    }
    .spotlight-title { 
        color: white;
    }
}

.column { 
    text-align: center;
}

ul {
    list-style-type: none;
    padding: 10;
}

li {
    margin-bottom: 10px;
    font-size: 36px;
    color: white;
}

button { 
    width: 100%;            /* Set a fixed width or max-width */
    padding: 10px;           /* Add some padding */
    border: 1px solid #ccc;  /* Add a border */
    text-align: center;      /* Center the text */
    overflow: hidden;        /* Prevents the content from spilling out */
    white-space: normal;     /* Override the default nowrap of button */
    line-height: 1.5; 
    color: var(--snippet-title-color);
}

/* Make li hover */
button:hover {
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
} 

.button-container {
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px; /* Added padding-right for symmetry */
}

.spotlight-title { 
  float: left; 
  position: relative; 
  width: 100%; 
  font-weight: bold; 
  font-size: 36px; 
  margin-bottom: 10px;
}

</style>
