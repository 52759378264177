<template>
    <div :style="mainContainerStyle"> 
        <div class="dashboard-view" :style="dashboardHeight">
            <div id="myprofile-section" class="user-profile">
                <div class="avatar">
                    <navbar-avatar :is-logged-in="true" :width="200" :height="200" />
                </div>
                <div class="user-details">
                    <p :style="{ 
                        color: isDarkMode ? 'white' : 'black', 
                        fontSize: '36px'
                    }">{{ displayName }}</p>
                    <p :style="{ color: isDarkMode ? 'yellow' : 'black', marginTop: '-10px' }">{{ email }}</p>
                    <button class="btn btn-dark" style="height: 30px; line-height: 0.2px; margin-top: 10px;" @click="signOut()"> Sign Out</button>
                </div>
            </div>
            <div id="mycourses-section" v-if="myCourses.length > 0"> 
                <div :style="lineSeparatorStyle"></div>
                <p :style="sectionTitleStyle">📚 My Courses</p>
                <ul style="margin-top: 20px;"> 
                    <li v-for="course in myCourses" :key="course.SK" style="margin-bottom: 10px;">
                        <div style="display:flex;">
                            <div class="left-div">
                                <img style="width: 100px; height: 60px; object-fit: cover; overflow: hidden;" :src="getThumbnail(course.PK.split('#')[1])" alt="Course Cover Image" />
                            </div>
                            <div class="right-div">
                                <router-link :to="`/learn/${course.PK.split('#')[1]}`" :style="{ 
                                    color: isDarkMode ? '#eceef4' : 'black', 
                                    fontSize: '16px',
                                    marginLeft: '10px',
                                    fontWeight: 'bold',
                                    width: '100%'
                                }">
                                {{ getCourseTitle(getCourseId(course)) }}
                                </router-link>
                                <div style="width: 100%; margin-left: 10px;"> 
                                    {{ getCourseInformation(getCourseId(course)) }}
                                </div>
                            </div>
                        </div>
                        <course-button 
                            style="float: right; position: relative; font-size: 16px; margin-top: -60px;"
                            @click="() => this.$router.push(`/learn/${course.PK.split('#')[1]}`)"
                            :course-id="course.PK.split('#')[1]"
                            :did-join-course="this.didJoinCourse(course.PK.split('#')[1])" 
                            :is-course-completed="isCourseCompleted(course)"
                        >
                        </course-button>
                        <course-progress-bar style="margin-top: 10px;" v-if="course" :progress="getCourseProgress(course).progress" />
                    </li>
                </ul>
            </div>
            <div v-else style="padding-top: 40px; padding-bottom: 40px;"> 
                <rainbow-button buttonText="🌈 Explore Courses" @click="() => this.$router.push(`/`)" />
            </div>
            <div id="payment-section" v-if="payments && payments.length > 0"> 
                <div :style="lineSeparatorStyle"></div>
                <p :style="sectionTitleStyle">💳 Purchases </p>
                <div class="table-container"> 
                    <table class="payments-table">
                        <thead :style="{ background: isDarkMode ? 'black' : '#ECEEF4'} ">
                        <tr>
                            <th>Purchased Course</th>
                            <th>Currency</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="payment in payments" :key="payment.id">
                            <td>{{ getCourseTitleFromPayment(payment) }}</td>
                            <td>{{ payment.currency.toUpperCase() }}</td>
                            <td>{{ centsToDollars(payment.amount) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div id="notifications-section"> 
                <div :style="lineSeparatorStyle"></div>
                <p :style="sectionTitleStyle">🔔 Notifications </p>
                <ul class="activity-list">
                    <li v-for="notification in this.notifications" :key="notification.SK">
                        {{ JSON.parse(notification.user).email }} {{ notification.text }}
                    </li>
                </ul>
                <p>Received event: {{ eventData }}</p>
            </div>
        </div>
    </div>
</template>

<script>

import { getCoursesProgress } from '../apis/watch.js';
import { getUserNotifications } from '../apis/user.js';

import { mapActions, mapState, mapGetters } from 'vuex'
import { logout } from '../../src/apis/auth.js'
import { getCoursesByExternalId } from '../../src/apis/courses.js';
import { getPaymentIntents } from '../../src/apis/stripe.js';
import CourseProgressBar from '@/components/CourseProgressBar.vue';
import CourseButton from '@/components/CourseButton.vue';
import RainbowButton from '@/components/RainbowButton.vue';
import NavbarAvatar from '@/components/NavbarAvatar.vue';

export default {
    data() {
        return {
            recentActivity: [
                { id: 1, description: 'Posted a new article' },
                { id: 2, description: 'Liked a post' },
                { id: 3, description: 'Followed a user' }
            ],
            windowWidth: 0,
            windowHeight: document.documentElement.clientHeight,
            isDarkMode: false,
            coursesProgress: [],
            tempCourseId: 'ghi',
            notifications: [],
            interval: null,
            eventData: '',
            eventSource: null // Define eventSource variable
        };
    },
    components: {
        'course-button': CourseButton, // Add the CourseButton component
        'course-progress-bar': CourseProgressBar, 
        'rainbow-button': RainbowButton,
        'navbar-avatar': NavbarAvatar
    },
    computed: {
        ...mapState([
            'user',
            'payments',
            'myCourses'
        ]),
        ...mapGetters([
            'didJoinCourse'
        ]),
        dashboardHeight() {
            return { 
                height: this.$store.state.isLandscape ? '100%' : innerHeight + 'px' 
            }
        },
        mainContainerStyle() { 
            return { 
                float: 'left', 
                position: 'relative',
                width: '100%', 
                height: '100vh',
                background: this.isDarkMode ? '#121212' : 'white'
            }
        },
        lineSeparatorStyle() { 
            return { 
                background: this.isDarkMode ? '#313030' : '#ECEEF4', 
                height: '1px',
                width: '100%',
                marginBottom: '20px'
            }
        },
        sectionTitleStyle() { 
            return { 
                color: this.isDarkMode ? 'yellow' : 'black', 
                fontSize: '24px'
            }
        },
        userPhotoURL() {
            const user = this.$store.state.user;
            if (user && user.providerData && user.providerData.length > 0 && user.providerData[0].photoURL) {
                return user.providerData[0].photoURL;
            }
            return require('@/assets/avatars/noface-avatar.png');
        },
        displayName() {
            const user = this.$store.state.user;
            return user && user.displayName ? user.displayName : '';
        },
        email() {
            const user = this.$store.state.user;
            return user && user.email ? user.email : '';
        },
        isMobile() {
            // Use navigator.userAgent to check the device type
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Regular expressions for mobile detection
            return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
        }, 
        isLandscape() { 
            document.documentElement.clientHeight < document.documentElement.clientWidth;
        },
        innerHeight() { 
            return window.innerHeight || 500;
        },
    },
    created() { 
        // // Initialize EventSource
        // this.eventSource = new EventSource('https://afternoon-peak-97324-bdcee60d43e5.herokuapp.com/events');
        
        // // Event listener for receiving events
        // this.eventSource.onmessage = (event) => {
        //     this.eventData = event.data;
        // };
        
        // // Event listener for handling errors
        // this.eventSource.onerror = (error) => {
        //     console.error('EventSource error:', error);
        // };  
    },
    mounted() {
        this.getMyCourses();
        this.getPaymentIntents();
        this.fetchCoursesProgress();
        this.$store.state.isPresentingNavbar = true;
        if (!this.user) {
            this.$router.push('/register');
        }
        this.updateWindowWidth();
        // Add event listener when the component is mounted
        window.addEventListener('resize', this.handleResize);

        this.checkDarkMode();
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', this.checkDarkMode);
    },
    beforeDestroy() {
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', this.checkDarkMode);
        
        // Clear interval to avoid memory leaks
        clearInterval(this.interval);

        // console.log(`Dash clearInterval`)

         // Close EventSource connection when component is destroyed
        if (this.eventSource) {
            this.eventSource.close();
        }
    },
    unmounted() {
        clearInterval(this.interval);
        // console.log(`Dash onUnmounted`)
    },
    methods: {
        
        isCourseCompleted(course) {
            return this.coursesProgress.some(c => c.courseId === course.PK.split('#')[1] && c.progress == 100);
        },
        centsToDollars(cents) {
            const dollars = cents / 100;
            return `$${dollars.toFixed(2)}`;
        },
        getCourseId(course) {
            return course.PK.split('#')[1];
        },
        getCourseTitleFromPayment(payment) { 
            const course = this.$store.state.courses.find(c => c.SK === payment.courseId);
            if (course && course.title) {
                return course.title;
            }
            return ''
        },
        getCourseTitle(courseId) {
            const course = this.$store.state.courses.find(c => c.SK === courseId);
            if (course && course.title) {
                return course.title;
            }
            return ''
        },
        getCourseInformation(courseId) {
            const course = this.$store.state.courses.find(c => c.SK === courseId);
            if (course && course.subtitle) {
                return course.subtitle;
            }
            return ''
        },
        getThumbnail(courseId) {
            const course = this.$store.state.courses.find(c => c.SK === courseId);
            if (course && course.thumbnail) {
                return course.thumbnail;
            }
            return ''
        },
        checkDarkMode(event) {
            this.isDarkMode = event ? event.matches : window.matchMedia('(prefers-color-scheme: dark)').matches;
        },
        handleResize() {
            this.updateWindowWidth();
        },
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },
        ...mapActions([
            'updateUserAction',
            'updatePaymentsAction',
            'updateMyCoursesAction'
        ]),
        getMyCourses() { 
            if (!this.$store.state.user) {
                // console.log(`User not logged in!`)
                return
            }
            getCoursesByExternalId(this.user.uid)
                .then((myCourses) => {
                    // console.log(`🤣 My Courses: ${JSON.stringify(myCourses)}`)
                    this.updateMyCoursesAction(myCourses);
                })
        },
        getPaymentIntents() { 
            getPaymentIntents(this.user.uid)
                .then((payments) => {
                    this.updatePaymentsAction(payments);
                })
        },
        signOut() {

            logout().then(() => {

                this.emitter.emit("user-logged-in", { isLoggedIn: false });

                localStorage.removeItem('user');
                localStorage.removeItem('session');
                this.$store.state.coursesProgress = [];
                this.updateUserAction(null);
                this.updateMyCoursesAction([]);
                this.updatePaymentsAction([]);
                this.$router.push('/register');
            });
        }, 
        fetchCoursesProgress() { 
            const user = this.$store.state.user
            if (!user) {
                // console.log('🚀🚀🚀 No user found')
                return
            }
            getCoursesProgress(user.uid).then((coursesProgress) => {
                this.coursesProgress = coursesProgress;
                // console.log('🚀🚀🚀 Course Progress', JSON.stringify(this.coursesProgress))
            }).catch((error) => {
                // console.log(error);
            });
        },
        getCourseProgress(course) {
            if (!this.$store.state.user) { 
              return {}
          }
          const courseId = course.PK.split('#')[1]
          // console.log(`🤣 Course ID: ${course.PK.split('#')[1]} | Progress: ${this.coursesProgress.find(p => p.courseId === courseId)}`)
          return this.coursesProgress.find(p => p.courseId === courseId) || {}
        },
        updateNotifications() {
            if (!this.$store.state.user) {
                return;
            }
            const uid = this.$store.state.user.uid;
            if (!uid) {
                return;
            }
            getUserNotifications(uid)
                .then(response => {
                    this.notifications = response;
                    // console.log(`DASHBOARD NOTIFICATIONS: ${this.notifications}`)
                })
                .catch(error => {
                    console.error(error);
                });
        },
        startNotificationsScheduler() { 
            this.updateNotifications();
    
            this.interval = setInterval(() => {

                if (!this.$store.state.user) {
                    return;
                }

                this.updateNotifications();
            }, 10000);
        }
    },
    beforeDestroy() {
        // Remove event listener before the component is destroyed to clean up
        window.removeEventListener('resize', this.handleResize);
    },
    onUnmounted() {
        clearInterval(this.interval);
        // console.log(`Dash onUnmounted`)
    }
};
</script>

<style scoped>

/* ADD CSS FOR DARK MODE */ 
@media (prefers-color-scheme: dark) {
    strong {
        color: #eceef4;
    }
}
.dashboard-view {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 100px;
    overflow-y: auto;

}

.user-profile {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
    margin-left: 0px;
}

.user-details {
    font-size: 16px;
    margin-top: -10px;
}

.activity-list {
    list-style-type: none;
    padding: 0;
}

.activity-list li {
    margin-bottom: 10px;
}

@media screen and (max-width: 500px) {
    .table-container {
        width: 100%;
    }
}

.table-container {
  overflow-x: auto; /* Enable horizontal scrolling */
}

.payments-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px; /* Set a minimum width to ensure table structure */
  margin-top: 20px;
}

.payments-table th,
.payments-table td {
  border: 1px solid #ECEEF4;
  padding: 8px;
  text-align: left;
}

.payments-table th {
  background-color: #f2f2f2;
}

/* Dark mode */ 
@media (prefers-color-scheme: dark) {
    .payments-table th {
        background-color: rgb(25, 24, 24);
        color: #eceef4;
        border: 1px solid #313030;
    }
    .payments-table td {
        border: 1px solid #313030;
        color: white;
    }
}

/* Optionally, you can add more responsive styles */
@media screen and (max-width: 600px) {
  .payments-table th,
  .payments-table td {
    padding: 5px; /* Adjust padding on smaller screens if needed */
  }
}

</style>
