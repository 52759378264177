<template>
  <div class="swift-cheat-sheet">
    <h1>Swift Cheat Sheet</h1>

    <section v-for="section in sections" :key="section.title">
      <h2>{{ section.title }}</h2>
      <div v-for="example in section.examples" :key="example.title" class="example">
        <h3>{{ example.title }}</h3>
        <pre><code ref="code">{{ example.code }}</code></pre>
        <button @click="copyToClipboard(example.code)">Copy to Clipboard</button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SwiftCheatSheet',
  data() {
    return {
      sections: [
        {
          title: 'Variables',
          examples: [
            {
              title: 'Declaring Variables',
              code: `var myVar = 42
let myConstant = 3.14`
            }
          ]
        },
        {
          title: 'Data Structures',
          examples: [
            {
              title: 'Arrays',
              code: `var numbers = [1, 2, 3, 4, 5]
numbers.append(6)`
            },
            {
              title: 'Dictionaries',
              code: `var names = ["John": 23, "Jane": 25]
names["Mark"] = 30`
            }
          ]
        },
        {
          title: 'Classes',
          examples: [
            {
              title: 'Basic Class',
              code: `class Person {
  var name: String
  var age: Int
  
  init(name: String, age: Int) {
    self.name = name
    self.age = age
  }
  
  func greet() {
    print("Hello, my name is \\(name) and I am \\(age) years old.")
  }
}

let john = Person(name: "John", age: 23)
john.greet()`
            }
          ]
        },
        {
          title: 'Structs',
          examples: [
            {
              title: 'Basic Struct',
              code: `struct Point {
  var x: Int
  var y: Int
  
  func display() {
    print("Point at (\\(x), \\(y))")
  }
}

let p = Point(x: 10, y: 20)
p.display()`
            }
          ]
        },
        {
          title: 'Enums',
          examples: [
            {
              title: 'Basic Enum',
              code: `enum Direction {
  case north
  case south
  case east
  case west
}

let direction = Direction.north`
            }
          ]
        }
      ]
    };
  },
  methods: {
    copyToClipboard(code) {
      const el = document.createElement('textarea');
      el.value = code;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      alert('Code copied to clipboard!');
    }
  }
};
</script>

<style scoped>
.swift-cheat-sheet {
  font-family: Arial, sans-serif;
  margin: 20px;
}

h1 {
  color: #333;
}

section {
  margin-bottom: 20px;
}

.example {
  margin-bottom: 10px;
}

pre {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 4px;
}

button {
  margin-top: 5px;
  background: #007BFF;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background: #0056b3;
}
</style>
