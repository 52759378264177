<template>
    <div class="flex-container" v-if="isMediumScreen || this.$store.state.isLandscape" :style="flexContainerStyle">
        <div class="toolbar rainbow-progress-container" :style="`--progress-width: ${progressWidth}%; background: ${this.theme == 'light' ? 'white' : 'black'};`">
            <div class="title-bar">
                <img :src="course.thumbnail" style="min-width: 100px; line-height: 100%; margin-top: -15px; height: 69px; object-fit: cover; overflow: hidden; margin-left: -10px" alt="Thumbnail" >
                <div class="course-title" :style="headerTextColor" style="margin: 15px"> 
                    {{course.title }}
                </div>
            </div>
            <div style="display: flex;">
                <!-- For debugging -->
                <button type="button" :class="buttonStyle">
                    {{ didJoinCourse(course.SK) ? 'Joined 💪' : 'Not joined 😅'}}
                </button>
                <button type="button" :class="buttonStyle">
                    {{ didPurchaseCourse(course.SK) ? 'Paid ✅' : 'Unpaid ❌'}}
                </button>
                <button type="button" :class="buttonStyle" @click="handleExpandLeft()">
                    {{ expandLeft ? '👈' : '👉🏼'}}
                </button>
                <button 
                    v-if="!isPictureInPicture && !hideBottomControl()" 
                    type="button" 
                    :class="buttonStyle" 
                    @click="handleExpandBottom()"
                >
                    {{ expandBottom ? '👇🏾' : '☝🏻'}}
                </button>
                <button type="button" :class="buttonStyle" @click="handleExpandRight()">
                    {{ expandRight ? '👉🏼' : '👈'}}
                </button>
                <button @click="goBack" type="button" :class="buttonStyle"> 👋 </button>
                <!-- <router-link :to="`/dashboard`"><img class="navbar-avatar" :src="userPhotoURL" v-if="isLoggedIn" /> </router-link>
                <router-link :to="`/register`"> <img class="navbar-avatar" v-if="!isLoggedIn" src="@/assets/avatars/noface-avatar.png" /></router-link> -->
                <NavbarAvatar :is-logged-in="isLoggedIn" style="margin: 10px;" />
            </div>
        </div>
        <div class="main" :style="{ 'border-top' : this.theme === 'light' ? '1px solid #ECEEF4' : '1px solid black', 'height' : this.innerWindowHeight}">
            <div class="primary">
                <div class="table-of-contents" :style="{ display: expandLeft ? 'flex' : 'none' }">
                    <div v-if="!isCourseDataLoading" v-for="mod in course.modules" class="course-module">
                        <ModuleTitleComponent :course="course" :moduleId="mod.SK" />
                        <div :style="{ 
                            'background-color': this.theme === 'light' ? selectedEpisode && episode.SK === selectedEpisode.SK ? '#ECEEF4' : 'white' : selectedEpisode && episode.SK === selectedEpisode.SK ? 'black' : '#121212',
                            'border-bottom': this.theme === 'light' ? '1px solid #eceef4' : '1px solid black'
                        }"
                        @click="onSelectedEpisode(episode)"
                        class="episode" v-for="episode in mod.episodes">
                            <img :src="episode.thumbnail" alt="Thumbnail" style="min-width: 70px; max-width: 70px; min-height: 40px; max-height: 40px; object-fit: cover; overflow: hidden; border-radius: 3px;">
                            <div class="episode-info">
                                <div class="episode-title" :style="subheaderTextColor">{{ episode.title }}</div>
                                <div class="episode-subtitle">{{ episode.subtitle }}</div>
                                <span style="margin-top: 10px; margin-bottom: 5px;" v-if="completedVideoIds.includes(episode.SK)" class="tag is-primary">Completed</span>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 300px; font-size: 100px;" v-if="isCourseDataLoading" class="loader">
                        {{ currentEmoji }}
                    </div>
                </div>
                <div class="episode-content" style="border: 0px solid green;">
                    <div class="video-player" :style="{ display: isPictureInPicture ? 'none' : 'flex'}">
                        <div class="video">
                            <VideoComponent 
                                v-if="selectedEpisode"
                                :isMobile="false"
                                :courseId="course.SK"
                                :userId="userId" 
                                :episode="selectedEpisode"
                                :playFrom="playFrom"
                                :pause="pauseVideo"
                                :didJoinCourse="didJoinCourse(course.SK)"
                                :didPurchaseCourse="didPurchaseCourse(course.SK)"
                                :completedVideoIds="completedVideoIds"
                                :is-course-complete="isEndOfCourseReached"
                                @didMarkVideoComplete="handleDidMarkVideoComplete"
                                @didMarkVideoIncomplete="handleDidMarkVideoIncomplete"
                                @signInToContinue="handleSignInToContinue"
                                @purchaseToContinue="handlePurchaseToContinue"
                                @enterPictureInPicture="handlePictureInPicture"
                                @leavePictureInPicture="handleLeavePictureInPicture"
                                @go-to-next-episode="goToNextEpisode"
                            />
                        </div>
                    </div>
                    <section 
                        id="summary-container"
                        class="summary" 
                        :style="{
                            display: expandBottom ? 'inherit' : 'none',
                            color: this.theme === 'light' ? 'black' : 'white',
                            backgroundColor: this.theme === 'light' ? 'white' : 'rgb(13, 17, 22)'
                        }"
                        :class="{ 'expand-content': expandTabs, 'pip': isPictureInPicture }"
                        style="border: 0px solid red; width: auto;"
                        >
                        <TabComponent 
                            v-if="selectedEpisode && Object.keys(selectedEpisode).length !== 0" 
                            @toggledTabs="handleToggleTabsContainer"
                            :theme="theme"
                            :selectedTab="selectedTab" 
                            :toggleTabsContainer="toggleTabsContainer" 
                            :onPressTab="onTabSelected" 
                            :course-id="course.SK"
                            :selectedEpisode="selectedEpisode"
                            :expandLeft="expandLeft"
                            :expandRight="expandRight"
                            :is-picture-in-picture="isPictureInPicture"
                            :is-online="isOnline"
                        />
                    </section>
                </div>
            </div>
            <div class="secondary" :style="{ display: expandRight ? 'flex' : 'none', zIndex: '200' }">
                <NotesComponent :course-id="course.SK" :selected-episode="selectedEpisode" @show-signup="handleSignInToContinue" is-mobile="false" />
            </div>
        </div>
    </div>
    <div v-if="!isMediumScreen" style="position: fixed; width: 100%;"> 
        <div 
        class="toolbar rainbow-progress-container" 
        :style="`--progress-width: ${progressWidth}%; border-bottom: ${this.theme == 'light' ? '1px solid #ECEEF4' : '1px solid #313030'}`">
            <div class="mobile-navbar">
                <div class="left-div" style="display: flex;">
                    <img :src="course.thumbnail" style="min-width: 100px; height: 69px; object-fit: cover; overflow: hidden; margin-left: -10px" alt="Thumbnail" >
                    <div class="course-title" style="margin: 15px; margin-top: 20px; font-size: 20px; font-weight: bold;">{{course.title}} {{ isLandscape }} </div>
                </div>
                <div class="right-div">
                    <button @click="goBack" :style="{ 
                        marginTop: '0px', 
                        marginRight: '10px',
                        padding: '15px',
                        borderRadius: '5px'
                    }"> 👋 </button>
                </div>
            </div>
        </div>
        <div class="mobile-episode-content">
            <div class="video-player">
                <div class="video">
                    <VideoComponent 
                    v-if="selectedEpisode"
                    :isMobile="true"
                    :courseId="course.SK"
                    :userId="userId" 
                    :episode="selectedEpisode"
                    :playFrom="playFrom"
                    :pause="pauseVideo"
                    :didJoinCourse="didJoinCourse(course.SK)"
                    :didPurchaseCourse="didPurchaseCourse(course.SK)"
                    :completedVideoIds="completedVideoIds"
                    :is-course-complete="isEndOfCourseReached"
                    @didMarkVideoComplete="handleDidMarkVideoComplete"
                    @didMarkVideoIncomplete="handleDidMarkVideoIncomplete"
                    @signInToContinue="handleSignInToContinue"
                    @purchaseToContinue="handlePurchaseToContinue"
                    @enterPictureInPicture="handlePictureInPicture"
                    @leavePictureInPicture="handleLeavePictureInPicture"
                    @go-to-next-episode="goToNextEpisode"
                    />
                </div>
            </div>
            <TabMobileComponent  
                v-if="!isCourseDataLoading" 
                @onMobileSelectedEpisode="onMobileSelectedEpisode"
                :tabs="mobileTabs" 
                :selectedTab="selectedMobileTab" 
                :onPressTab="onMobileTabSelected" 
                :course="course" 
                :selectedEpisode="selectedEpisode"
                :notes="notes"
                :currentNote="currentNote"
                :colors="colors"
                :selectedColor="selectedColor"
                :completed-video-ids="completedVideoIds"
                :theme="theme"
            />
            <div style="margin-top: 200px; font-size: 100px;" v-if="isCourseDataLoading" class="loader">
                {{ currentEmoji }}
            </div>
        </div>
    </div>
</template>

<script>

import { goOnline, goOffline } from '../apis/online.js'
import { getCompletedEpisodeIds, updateCompletedEpisodeIds, getLastWatchedEpisode } from '../apis/watch.js'

import { computed, reactive } from 'vue';
import { mapGetters, mapActions } from 'vuex';

import TabComponent from '../components/TabComponent.vue';
import TabMobileComponent from '../components/TabMobileComponent.vue';
import VideoComponent from '../components/VideoComponent.vue';
import CourseProgressBar from '../components/CourseProgressBar.vue';
import NotesComponent from '../components/NotesComponent.vue';
import ModuleTitleComponent from '../components/ModuleTitleComponent.vue';
import NavbarAvatar from '../components/NavbarAvatar.vue';

import { useMediaQuery } from '@vueuse/core';

const isMediumScreenQuery = useMediaQuery('(min-width: 868px)');
const isMediumScreen = computed(() => reactive(isMediumScreenQuery));
const userPrefersDark = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;

export default {
    name: 'LearnComponent',
    props: {
        course: {
            type: Object,
            default: () => {
                return {
                    title: 'Loading...',
                    thumbnail: 'https://via.placeholder.com/150',
                    modules: [],
                }
            }
        }
    },
    emits: ['openOnboardingModal', 'showCourseCompletedModal'],
    data() {
        return {
            theme: (userPrefersDark ? 'dark' : 'light'),
            dropdownIsActive: false,
            pauseVideo: false,
            showSignup: true,
            showStripe: false,
            emojis: ["🌟", "🌍", "🚀", "🌈", "💫"],
            colors: ["#fffdb9", "#ffcccb", "#ccffcc", "#cce4ff"],
            currentEmoji: "",
            emojiIndex: 0,
            currentNote: "",
            selectedColor: "#fffdb9",
            notes: [],
            isMediumScreen: isMediumScreen.value,
            selectedEpisode: {},
            selectedModule: {},
            selectedTab: 0,
            selectedMobileTab: 0,
            expandRight: true,
            expandBottom: true,
            expandLeft: true,
            isCourseDataLoaded: false, 
            isCourseDataLoading: false,
            progressWidth: 0,
            onboardingStep: 0,
            currentOnboardingStep: 0,
            expandTabs: false,
            playFrom: 0, // Start playing the episode from -> passed to VideoComponent
            completedVideoIds: [], // List of completed video IDs
            isPictureInPicture: false,
            innerWindowHeight: '100vh',
            summaryWidth: 0, 
            isIpadPortrait: false,
            isOnline: false,
        }
    },
    components: {
        TabComponent,
        TabMobileComponent,
        VideoComponent, 
        CourseProgressBar, 
        NotesComponent,
        ModuleTitleComponent, 
        NavbarAvatar
    },
    computed: { 
        ...mapGetters([
            'didJoinCourse',
            'didPurchaseCourse'
        ]),
        isLoggedIn() { 
            return this.$store.state.user ? true : false;
        },
        userPhotoURL() {
            
            if (!this.$store.state.user) { 
                return require('@/assets/avatars/noface-avatar.png');
            }

            if (this.$store.state.user.providerData.length === 0) { 
                return require('@/assets/avatars/noface-avatar.png');
            }

            return this.$store.state.user.providerData[0].photoURL ||  require('@/assets/avatars/noface-avatar.png')
        },
        userId() {
            if (this.$store.state.user) {
                return this.$store.state.user.uid;
            } else { 
                return "X";
            }
        },
        flexContainerStyle() {
            return {
                height: `calc(${this.innerWindowHeight} - 0px)`,
                background: this.theme === 'light' ? 'white' : '#121212',
                border: `0px solid red`
            };
        }, 
        headerTextColor() { 
            return { 
                color: this.theme === 'light' ? 'black' : 'white'
            }
        },
        subheaderTextColor() { 
            return { 
                color: this.theme === 'light' ? 'black' : '#ECEEF4'
            }
        },
        buttonStyle() { 
            return this.theme === 'light' ? 'btn btn-light  shadow-none' : 'btn btn-dark  shadow-none'
        },
        episodeRowStyle() { 
            return { 
                background: this.theme === 'light' ? 'white' : '#1e1e1e',
                color: this.theme === 'light' ? 'black' : 'white',
                borderBottom: this.theme === 'light' ? '1px solid #eceef4' : '1px solid #1e1e1e'
            }
        },
        expandRightStyle() {
            return this.expandRight ? 'background-color: white; color: white;' : 'background-color: #ECEEF4; color: black;';
        }, 
        themeStyle() { 
            this.theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
        }, 
        isEndOfCourseReached() { 
            return this.progressWidth >= 100
        }
    },
    watch: { 
        isLoggedIn() { 
            if (this.isLoggedIn) { 
                this.getLastPlayed();
                this.fetchCompletedEpisodeIds();
            }
        },
        isMediumScreen() { 
            this.collapseTabs();
            this.isPictureInPicture = false;
            this.exitPiP();
            this.onResize();
        }
    },
    methods: {
        updateCourseProgress() { 
            const allEpisodes = this.course.modules.map(module => module.episodes).flat().length;
            const completedEpisodes = this.completedVideoIds.length;
            this.progressWidth = Math.round((completedEpisodes / allEpisodes) * 100);
        },
        connectOnline() {
            if (!this.$store.state.user) { 
                // console.log(`[D] User not logged in, cannot go online`)
                return;
            }

            if (!this.course.SK) { 
                // console.log(`[D] Course not found, cannot go online`)
                return;
            }

            goOnline(this.$store.state.user.uid, this.course.SK).then((response) => {
                // console.log(response.data);
                this.isOnline = true;
            }).catch((error) => {
                // console.log(error);
                this.isOnline = false
            });
        },
        hideBottomControl() { 
            return this.$store.state.isLandscape && this.$store.state.isHeightToSmall;
        },

        // If the user is in Pipmode and then drags an app to the side
        // the website will enter mobile mode
        // We need to exit pip mode when this happens
        exitPiP() {
            if (document.pictureInPictureElement) {
                document.exitPictureInPicture()
                .then(() => {
                    // console.log('Exited PiP mode');
                })
                .catch(error => {
                    console.error('Error exiting PiP mode:', error);
                });
            }
        },
        collapseTabs() { 
            this.expandTabs = false;
        },
        handleToggleTabsContainer(expanded) { 
            // console.log(`[D] Resizing after toggling tabs container`)
            if (expanded == false) { 
                this.collapseTabs();
            }
            this.onResize();
        },
        reloadSummaryWidth() { 
            setTimeout(() => {
                const summaryContainer = document.getElementById('summary-container');
                if (!summaryContainer) { 
                    // console.log(`[D] Summary container not found`)
                    return;
                }
                const summaryWidth = summaryContainer.offsetWidth || 0;
                // console.log(`[D] Summary width: ${summaryWidth}`)
                this.updateSummaryWidthAction(summaryWidth);
                // console.log(`[D] Updated Summary width: ${summaryWidth}`)
            }, 1000);
        },
        checkIsIpad() { 
            var isIpad = /iPad|Macintosh/.test(navigator.userAgent) && 'ontouchend' in document;
            return isIpad;
        },
        checkIpadPortrait() {
            var isIpad = this.checkIsIpad();
            var isPortrait = window.matchMedia("(orientation: portrait)").matches;
            return isIpad && isPortrait || window.innerWidth < 1100;
        },
        handleExpandBottom() { 
            this.expandBottom = !this.expandBottom;
            this.onResize();
        },
        handleExpandRight() { 
            this.expandRight = !this.expandRight;

            if (this.checkIpadPortrait()) { 
                // console.log(`[D] Ipad portrait mode, trying to expand left.`)
                this.expandLeft = false;
            }

            this.reloadSummaryWidth();
        },
        handleExpandLeft() { 
            this.expandLeft = !this.expandLeft;
            
            if (this.checkIpadPortrait()) { 
                // console.log(`[D] Ipad portrait mode, trying to expand left.`)
                this.expandRight= false;
            }

            this.reloadSummaryWidth();
        },
        handleSignInToContinue() { 
            this.$emit('openOnboardingModal', 1);
        },
        handlePurchaseToContinue() { 
            this.$emit('openOnboardingModal', 3);
        },
        fetchCompletedEpisodeIds() { 
            getCompletedEpisodeIds(this.course.SK, this.userId).then((episodeIds) => {                 
                this.completedVideoIds = [...new Set(episodeIds)];
                this.updateCourseProgress();
                if (this.isEndOfCourseReached) { 
                    // console.log(`[✅] Course is complete, opening course completed modal`)
                    this.$emit('showCourseCompletedModal');
                }
            }).catch((error) => { 
                console.error(error);
                this.completedVideoIds = [];
            })
        },
        updateCompletedEpisodeIds() { 
            const videoIds = [...new Set(this.completedVideoIds)];
            updateCompletedEpisodeIds(this.course.SK, this.userId, videoIds).then((response) => {
                this.fetchCompletedEpisodeIds();
            }).catch((error) => {
                console.error(error);
            });
        },
        handlePictureInPicture() { 
            // console.log(`handlePictureInPicture`)
            this.isPictureInPicture = true;
        },
        handleLeavePictureInPicture() { 
            // console.log(`handleLeavePictureInPicture`)
            this.isPictureInPicture = false;
        },
        handleDidMarkVideoIncomplete(incompletedVideoId) { 
            this.removeIdFromList(this.completedVideoIds, incompletedVideoId);
            this.updateCompletedEpisodeIds();
        },
        handleDidMarkVideoComplete(completedVideoId) { 
            // console.log(`Handler for completed video called with ${completedVideoId}`)

            if (!this.completedVideoIds.includes(completedVideoId)) { 
                this.completedVideoIds.push(completedVideoId);
            }
            
            this.updateCompletedEpisodeIds();
            // console.log(`[✅] handle Did Mark Video Complete: ${completedVideoId}`) 

            if (this.isEndOfCourseReached) { 
                this.$emit('showCourseCompletedModal');
            }
        },
        removeIdFromList(idList, idToRemove) {
            let index = idList.indexOf(idToRemove);

            if (index !== -1) {
                idList.splice(index, 1);
            }

            return idList;
        },
        findNextId(idList, currentId) {
            let currentIndex = idList.indexOf(currentId);

            // Check if the currentId is in the list and not the last element
            if (currentIndex !== -1 && currentIndex < idList.length - 1) {
                return idList[currentIndex + 1];
            }

            // Return null or any appropriate value if currentId is the last or not found
            return null;
        },
        findPreviousId(idList, currentId) {
            let currentIndex = idList.indexOf(currentId);

            // Check if the currentId is in the list and not the first element
            if (currentIndex > 0) {
                return idList[currentIndex - 1];
            }

            // Return null or any appropriate value if currentId is the first or not found
            return null;
        },
        goBack() {
            this.pauseVideo = true;
            this.$store.state.isPresentingNavbar = true;
            this.$router.push(`/course/${this.course.SK}`);

            if (!this.$store.state.user) { 
                return;
            }

            goOffline(this.$store.state.user.uid, this.course.SK).then((response) => {
                // console.log(response.data);
            }).catch((error) => {
                // console.log(error);
            });
        },
        goToNextEpisode() { 

            if (this.isCourseComplete) { 
                // console.log(`Course is complete, no more episodes to watch`)
                return;
            }

            // console.log(`✅ Going to next episode`)

            this.pauseVideo = true;
            const allEpisodes = this.course.modules.map(module => module.episodes).flat()
            const allEpisodeIds = allEpisodes.map(episode => episode.SK);
            // console.log(`All episode ids: ${JSON.stringify(allEpisodeIds)}`)
            const nextEpisodeId = this.findNextId(allEpisodeIds, this.selectedEpisode.SK);   
            
            if (nextEpisodeId) { 
                const nextEpisode = allEpisodes.find(episode => episode.SK === nextEpisodeId);
                // console.log(`Next episode: ${JSON.stringify(nextEpisode)}`)
                this.selectedEpisode = nextEpisode;
                this.selectedCurrentTab = 0;
            } else { 
                // Find which episodeID's are not in the completed episode Ids 
                const notCompletedEpisodeIds = allEpisodeIds.filter(episodeId => !this.completedVideoIds.includes(episodeId));
                // console.log(`Not completed episode ids: ${JSON.stringify(notCompletedEpisodeIds)}`)
                if (notCompletedEpisodeIds.length === 0) { 
                    // console.log(`All episodes are completed`)
                    // END COURSE
                    return;
                } 
                const nextEpisodeId = notCompletedEpisodeIds[0];
                const nextEpisode = allEpisodes.find(episode => episode.SK === nextEpisodeId);
                // console.log(`Next episode: ${JSON.stringify(nextEpisode)}`)
                this.selectedEpisode = nextEpisode;
                this.selectedCurrentTab = 0;
            }
        },
        onResize(event) {
            
            // console.log(`[D] Resize event: ${window.innerWidth} ${window.innerHeight}`)

            // While this is good on desktop to close the right panel when the width is too small
            // This causes an issue on iPad
            // STR: If the user has the right panel open and rotates the iPad
            // the right panel will be hidden
            // if (window.innerWidth < 1200) { 
            //     this.expandRight = false;
            // }

            if (this.expandLeft && this.expandRight && window.innerWidth < 1200) { 
                this.expandRight = false;
            }
            
            this.innerWindowHeight = window.innerHeight + 'px'

            this.reloadSummaryWidth();

            this.handleLandscapeDetection();
        }, 
        handleLandscapeDetection() {
            // If the user is on a mobile device and we are in landscape mode 
            // we don't want to show the mobile version of the site because the user can't scroll
            // with this code we can force the user into the desktop experience while in landscape on a mobile device
            // the isLandscape must be persisted in order to continually be landscape when the page reloads
            const height = document.documentElement.clientHeight;
            this.$store.state.isHeightToSmall = height < 600;
            
            const isLandscape = document.documentElement.clientWidth > document.documentElement.clientHeight;
            this.$store.state.isLandscape = isLandscape
            
            if (isLandscape && height < 600) { 
                this.expandBottom = false;
                this.expandRight = false;
            }            
        },
        onTabSelected(index) {
            this.selectedTab = index;
            // console.log(index);
        },
        onMobileTabSelected(index) {
            this.selectedMobileTab = index;
            // console.log(index);
        },
        onSelectedEpisode(episode) {

            // Come back to this later when we know when we want to handle payments
            // const isPurchased = this.checkIfCourseIsPurchased();

            // if (!isPurchased) { 
            //     this.pauseVideo = true;
            //     return;
            // }

            // Update data model
            this.selectedEpisode = episode;
            this.selectedTab = 0
        },
        onMobileSelectedEpisode(episode) {

            // const isPurchased = this.checkIfCourseIsPurchased();

            // if (!isPurchased) { 
            //     this.pauseVideo = true;
            //     return;
            // }

            // Update data model
            this.selectedEpisode = episode;
            this.selectedTab = 0
        }, 
        increaseProgress() {
            if (this.progressWidth < 100) {
                this.progressWidth += 10; // Increase by 10%, adjust as needed
            }
        },
        decreaseProgress() {
            if (this.progressWidth > 0) {
                this.progressWidth -= 10; // Decrease by 10%, adjust as needed
            }
        },
        checkIfCourseIsPurchased() { 

            if (this.didPurchaseCourse(this.course.SK)) { 
                // console.log(this.didPurchaseCourse(this.course.SK));
                return true;
            }

            // If the users not logged in
            // show the onboarding from the beginning
            if (!this.$store.state.user) { 
                // console.log(`User is not logged in, trying to open modal to register page 1.`)
                this.$emit('openOnboardingModal', 1);
                return false
            }

            // If the user is logged in but hasn't purchased the course
            // show the onboarding from payment page
            if (!this.didPurchaseCourse(this.course.SK)) { 
                // console.log(`User is logged in but hasn't purhcased course, trying to open modal to payment page 3.`)
                this.$emit('openOnboardingModal', 3);
                return false
            }
        },
        toggleTabsContainer() {
            this.expandTabs = !this.expandTabs;
        },
        getLastPlayed() { 
            getLastWatchedEpisode(this.course.SK, this.userId).then((lastEpisodeWatched) => {
                if (!lastEpisodeWatched) { 
                    this.loadDefaultEpisode();
                    return;
                }
                const allEpisodes = this.course.modules.map(module => module.episodes).flat();
                const episode = allEpisodes.find(episode => episode.SK === lastEpisodeWatched.episodeId);
                if (!episode) { 
                    this.loadDefaultEpisode();
                    return;
                }
                this.selectedEpisode = episode;
            }).catch((error) => {
                // console.log(error);
                this.loadDefaultEpisode();
            });
        },
        loadDefaultEpisode() { 

            if (!this.course.modules) { 
                return;
            }
            
            // Check if there are even modules
            if (this.course.modules.length === 0) { 
                // console.log(`VIDEO DEBUG Z: No modules found in course: ${JSON.stringify(this.course)}`)
                return;
            }

            let safetyCheck = this.course.modules.length > 0 && this.course.modules[0].episodes.length > 0
            if (safetyCheck) { 
                this.selectedEpisode = this.course.modules[0].episodes[0];
                // console.log(`VIDEO DEBUG: Selecting default episode. ${JSON.stringify(this.selectedEpisode.SK)}`)
            } else { 
                // console.log(`VIDEO DEBUG Z: Unable to load default video. ${JSON.stringify(this.selectedEpisode.SK)}`)
            }
        },
        getEpisodeById(data, episodeId) {
            // Check if data and episodeId are provided
            if (!data || !episodeId) {
                // console.log(`VIDEO DEBUG: getEpisodeById: No data, no episode id`)
                return null;
            }

            // Iterate through the modules
            for (const module of data.modules) {
                    // Iterate through the episodes in each module
                    for (const episode of module.episodes) {
                        // console.log(`VIDEO DEBUG Ep: ${JSON.stringify(episode.SK)} | Req: ${JSON.stringify(episodeId)}`)
                        // Check if the episode's SK matches the requested ID
                        if (episode.SK === episodeId) {
                            // console.log(`VIDEO DEBUG: getEpisodeById: ${JSON.stringify(episode)}`)
                            return episode;
                        } else { 
                            // console.log(`VIDEO DEBUG: (getEpisodeById) No episode with ${episodeId} found in: ${JSON.stringify(data)}`)
                        }
                    }
            }

            // Return null if no matching episode is found
            return null;
        },
        setupPrefersDarkThemeListener() { 
            const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
                
            if (prefersDarkScheme.matches) {
                this.theme = 'dark';
            } else { 
                this.theme = 'light';
            }
        },
        toggleTheme() {
            this.theme = this.theme === 'light' ? 'dark' : 'light';
        },
        ...mapActions([
            'updateSummaryWidthAction'
        ]), 
        getBackgroundColor(index) {
            switch (index) {
            case 1: return '#FFBF0A'; // Blue
            case 2: return '#FD7E14'; // Red
            case 3: return '#DC3545'; // Green
            case 4: return '#8F3BF5'; // Green
            case 5: return '#027DFF'; // Green
            default: return '#bdc3c7'; // Default color (Grey)
            }
        }, 
        updateGradient() {
            const container = document.querySelector('.rainbow-progress-container');

            // Example: Generate random colors or set specific colors
            const color1 = `#FFBF0A`
            const color2 = `#FD7E14`

            // Update CSS variables
            container.style.setProperty('--start-color', color1);
            container.style.setProperty('--end-color', color2);

            // Update progress width if needed
            container.style.setProperty('--progress-width', this.progressWidth + '%');
        }
    },
    mounted() { 

        // console.log(`[D] LearnComponent mounted!`);
        // console.log(`payments: ${JSON.stringify(this.$store.state.purchases)}`);
        
        // Register an event listener when the Vue component is ready
        window.addEventListener('resize', this.onResize)

        //  && this.didPurchaseCourse(this.course.SK) for when purchasing comes into place.. 
        if (this.$store.state.user) { 
            this.getLastPlayed();
            this.fetchCompletedEpisodeIds();
        } else { 
            this.loadDefaultEpisode();
        }
        if (window.innerWidth < 1200) { 
            this.expandRight = false;
        }
        this.innerWindowHeight = window.innerHeight + 'px'

        const summaryContainer = document.getElementById('summary-container')

        if (!summaryContainer) { 
            // console.log(`[D] Summary container not found`)
            return;
        }
        const summaryWidth = summaryContainer.offsetWidth || 0;

        // console.log(`[D] Summary width: ${summaryWidth}`)
        this.updateSummaryWidthAction(summaryWidth);
        // console.log(`[D] Updated Summary width: ${summaryWidth}`)

        // Listen for the orientation change event
        window.addEventListener('orientationchange', this.onResize);

        // Should we hide the open right panel button or not? 
        this.checkIpadPortrait();

        this.setupPrefersDarkThemeListener();

        // Deal with safari exit full screen
        document.addEventListener('fullscreenchange', this.onResize, false);
        document.addEventListener('webkitfullscreenchange', this.onResize, false);
        document.addEventListener('mozfullscreenchange', this.onResize, false);
        window.matchMedia('(prefers-color-scheme: dark)').addListener(this.toggleTheme);

        // Incase user refreshes will in landscape on small devices
        this.handleLandscapeDetection();

        // Go online 
        this.connectOnline();

        // Update the progress gradient
        this.updateGradient();

        // Show the course completed modal if the user has finished this course
        setTimeout(() => {
            if (this.isEndOfCourseReached) { 
                this.$emit('showCourseCompletedModal');
            }
        }, 1000);
    },
    beforeDestroy() {
        // Unregister the event listener before destroying this Vue instance
        window.removeEventListener('resize', this.onResize)
        window.removeEventListener('fullscreenchange', this.onResize);
        window.removeEventListener('webkitfullscreenchange', this.onResize);
        window.removeEventListener('mozfullscreenchange', this.onResize);
        window.matchMedia('(prefers-color-scheme: dark)').removeListener(this.toggleTheme);
    }
}
</script>

<style scoped>

.rainbow-progress-container {
    position: relative; /* Required for absolute positioning of the pseudo-element */
}

.rainbow-progress-container::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0; /* Aligns the pseudo-element to the bottom of the container */
    width: var(--progress-width, 100%); /* Use the CSS variable, default to 100% */
    height: 4px; /* Thickness of the progress bar */
    transition: width 0.5s ease; /* Animate width changes over 0.5 seconds */
    background: linear-gradient(to right, var(--start-color, #FFBF0A), var(--end-color, #FD7E14));
}
.episode-content { 
    border-left: 1px solid #eceef4;
    border-right: 1px solid #eceef4;
    width: 100%;
}

.col-height { 
    min-height: 90vh;
}
.sidebar-width { 
    min-height: 90vh;
}
.custom-list-group-item {
    background-color: white; /* Dark teal background */
    color: black; /* White text color */
    padding: 0.5rem 1rem; /* Some padding */
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center items vertically */
    gap: 0.5rem; /* Gap between items */
    height: 60px;
}
.custom-list-group-item img {
    height: 40px; /* Fixed height for the image */
    width: auto; /* Maintain aspect ratio */
}
.custom-list-group-item .time-range {
    color: #9e9e9e; /* Lighter text color for time range */
    margin-left: auto; /* Push to the right */
}
.display-none { 
    display: none;
}

.flex-container {
    display: flex;
    flex-direction: column;
    border: 0px solid red;
}

.flex-container .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    max-height: 70px;
}

.flex-container .main {
    display: flex;
    justify-content: space-between;
}

.flex-container .main .primary {
    display: flex;
    flex: 3;
    width: 100%;
    overflow: hidden;
    order: 0;
}

.flex-container .main .primary .table-of-contents {
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 100%;
    max-width: 300px;
    width: 100%;
    overflow-y: scroll;
    flex: 1;
    border: 0px solid red;
}

@media (prefers-color-scheme: light) {
    .course-title { 
        color: black;
    }

    .flex-container .main .primary .table-of-contents {
        background-color: white;
    }
}

@media (prefers-color-scheme: dark) {
    .flex-container .main .primary .table-of-contents {
        background-color: #121212;
    }

    .toolbar {
        background-color: black;
    }

    .course-title { 
        color: white;
    }
}

.flex-container .main .primary .table-of-contents .course-module .episode {
    display: flex;
    flex: 1;
}

.flex-container .main .primary .table-of-contents .course-module .episode .thumbnail {
    height: 40px; /* Fixed height for the image */
    width: auto; /* Maintain aspect ratio */
}

.flex-container .main .primary .episode-content {
    display: flex;
    flex-flow: column;
    flex: 3;
    height: 100%;
    width: 100%;
}

.flex-container .main .primary .episode-content .summary {
    display: flex;
    flex: none;
    flex-basis: 400px;
    max-height: 400px;
    height: auto;
}

.flex-container .main .primary .episode-content .summary.expand-content {
    flex-basis: 600px;
    max-height: 600px;
}

.flex-container .main .primary .episode-content .summary.pip {
    flex-basis: 100% !important;
    max-height: 100% !important;
}

.hide-on-medium-screen, .hide-on-large-screen {
    display: none !important;
}

.flex-container .main .primary .episode-content .video-player {
  background-color: #000000;
  justify-content: center;
  flex: 3;
  height: 100%;
  width: 100%;
  position: relative;
}

.flex-container .main .primary .episode-content .video-player .video {
    position: relative;
    height: 100%;
    width: 100%;
}

.flex-container .main .primary .episode-content .video-player .video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.flex-container .main .secondary {
    max-height: 100%;
    max-width: 300px;
    width: 100%;
}

.episode-content { 
    margin-top: 0px;
}

.episode {
    display: flex;
    padding: 10px;
}

.episode:hover { 
    background-color: #f1f1f1;
    cursor: pointer;
}
.episode-info { 
    margin-left: 10px;
    margin-top: 0px;
}

.episode-title { 
    font-size: 14px;
    font-weight: 500;
}
.episode-subtitle { 
    font-size: 12px;
    font-weight: 400;
    color: #9e9e9e;
}

.course-title {
    font-size: 2vw;
    font-weight: 750;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 10px;
    white-space: nowrap;
}

.module-title {
    font-size: 18px;
    font-weight: 1000;
    margin-top: 10px;
    margin-bottom: 0px;
    margin-left: 10px;
}

.btn { 
    margin-right: 10px;
}

.title-bar { 
    display: flex;
    align-items: flex-start;
    margin-left: 10px;
    height: 100%;
    line-height: 100%;
}

.mobile-episode-content {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh);
    overflow: hidden;
    flex: 1;
    padding-bottom: 100px;
}

.mobile-episode-content .video-player {
    background-color: #000000;
    display: flex;
    justify-content: center;
    flex: 3;
    width: 100%;
    height: 100%;
    position: relative;
}

.loader {
  font-size: 24px;
  text-align: center;
  /* Additional styling */
}

/* Bottom Bar */ 
.flex-container .bottom-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 10px;
    height: 100px;
    border-top: 1px solid #ddd;
}

.bottom-bar-btn {
    /* Style for the buttons */
    height: 50px; /* Adjust the height as needed */
    width: 120px; /* Adjust the width as needed */
    font-size: 36px; /* Optional: Adjust font size if needed */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s ease; /* Smooth transition for background color change */
    /* Add other styling as needed */
    margin-left: 10px;
    margin-right: 10px;
    line-height: 1;
}

.bottom-bar-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 75%; /* Center content occupies 75% of the bottom bar */
    margin-bottom: 20px;
}

.bottom-bar-title {
    text-align: center;
    font-size: 1.5em;
    margin-bottom: 5px;
    color: black;
}

.modal-page {
  display: none; /* Hide pages by default */
}

/* Style for the modal - Add your existing styles here */
.modal {
  /* Your existing modal styles */
}

.modal-content {
  /* Your existing modal content styles */
}

.actions { 
    display: flex;
    align-items: center;
}

.actions .btn { 
    width: 40px; 
    height: 40px;
}

@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 1) {
    /* Your CSS styles for iPad in portrait mode here */
}

button { 
    height: 40px;
    margin-top: 20px;
    line-height: 0.2;
}

.mobile-navbar {
  display: flex;
  justify-content: space-between; /* This will create space between the two divs */
  align-items: center; /* This aligns the divs vertically at the center, you can adjust as needed */
}

.left-div {
  width: 70%; /* Adjust the width as needed, ensuring the total is less than 100% to maintain the space */
  /* You can add more styling here such as padding, background-color, etc. */
}

.right-div {
    width: 30%;
    text-align: right;
}

/* Remove scroll bar table-of-contents */ 
::-webkit-scrollbar {
    display: none;
}

</style>
  
