<template>
    <div>
        <QuizComponent />
    </div>
</template>

<script>
import QuizComponent from '@/components/QuizComponent.vue';

export default {
    name: 'QuizView',
    components: {
        QuizComponent
    },
    data() {
        return {
            // Your data properties go here
        };
    },
    methods: {
        // Your methods go here
    },
    mounted() {
        // Code to run when the component is mounted
    },
};
</script>

<style scoped>
/* Your component-specific styles go here */
</style>