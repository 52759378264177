<template>
  <div class="container container-content">
    <div class="column side-column">Left Column</div>
    <div class="column middle-column">Middle Column</div>
    <div class="column side-column">Right Column</div>
  </div>
</template>

<script>
export default {
  name: 'ThreeColumnLayout',
};
</script>

<style scoped>
.container {
  display: flex;
  flex-wrap: wrap;
}

.column {
  padding: 10px;
  box-sizing: border-box;
}

.side-column {
  flex: 1;
}

.middle-column {
  flex: 2;
}

@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .middle-column {
    order: -1;
  }
}
</style>