<template>
    <div @click="patchCourse()" class="course-card-component-container">
        <img class="fill" :src="course.thumbnail"/>
        <div class="course-card-component-container-top-bar"></div>
        <div class="course-card-component-container-top-bar-left-image">
            <i class="fas fa-lock"></i>
        </div>
        <div v-if="isUserSignedIn" class="progress-bar-container">
            <div class="progress-bar" :style="{ width: courseProgress + '%' }"></div> 
        </div>
        <div class="course-card-component-info-bar">
            <p class="course-card-component-info-bar-title">{{ course.title }}</p>
        </div>
        <p class="course-card-component-info-bar-details-title">
            {{ course.subtitle }}
        </p>
        <p class="course-card-component-info-bar-details-description">
            {{ course.information }}
        </p>
        <div class="gradient-div"> </div>

        <!-- Show More Button -->
        <button @click.stop="showMore" class="show-more-btn">Show More</button>
    </div>
</template>

<script>

import { joinCourse, getCoursesByExternalId } from '../apis/courses.js';
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        course: {
            type: Object,
            required: true
        },
        courseProgress: {
            type: Number,
            default: 0
        },
    },
    computed: {
        ...mapState([
            'user'
        ]),
        isUserSignedIn() {
            return this.user ? true : false
        }
    },
    methods: {
        ...mapActions([
            'updateMyCoursesAction'
        ]),
        goToCourse() { 
            this.$router.push(`/learn/${this.course.SK}`)
        },
        patchCourse() {
            if (!this.user) {
                this.goToCourse();
                return
            }
            // console.log('[RR] - Join Course - ' + this.course.SK + ' - ' + this.user.uid)
            joinCourse(this.course.SK, this.user.uid)
                .then(() => {
                    getCoursesByExternalId(this.user.uid)
                        .then((myCourses) => {
                            this.updateMyCoursesAction(myCourses);
                            this.goToCourse();
                        })  
                })
        },
        showMore() {
            // console.log('Show More button clicked');
            this.$router.push(`/course/${this.course.SK}`)
        }
    }
};
</script>

<style scoped>

@media (prefers-color-scheme: dark) {
    .course-card-component-container {
        background: black;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        border: 2px solid #1F2937;
    }
    .course-card-component-container img {
        background-color: black; /* Semi-transparent red */
    }
    .course-card-component-container-top-bar {
        background-color: black;
    }
    .course-card-component-info-bar { 
        background: #1F2937;
        color: white;
    }
    .course-card-component-info-bar-title { 
        color: white;
    }
    .course-card-component-info-bar-details-title { 
        color: white;
    }
    .gradient-div {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    }
}

@media (prefers-color-scheme: light) {
    .course-card-component-container {
        background: white;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        border: 2px solid #ECEEF4;
    }
    .course-card-component-container img {
        background-color: white; /* Semi-transparent red */
    }
    .course-card-component-container-top-bar {
        background-color: white;
    }
    .course-card-component-info-bar { 
        background: #ECEEF4;
    }
    .course-card-component-info-bar-title { 
        color: black;
    }
    .course-card-component-info-bar-details-title { 
        color: black;
    }
    .gradient-div {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(234, 232, 232) 100%);
    }
}

.course-card-component-container {
    position: relative;
    height: 440px; 
    border-radius: 10px;
    overflow-y: hidden;
    min-width: 300px;
}

.course-card-component-container img {
    position: absolute;
    top: 0px; 
    left: 0px;
    width: 100%;
    height: 50%;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    z-index: 1; /* Ensures the overlay is on top */
}

.course-card-component-container-top-bar {
    position: relative;
    width: 100%;
    height: 35px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    opacity: 0.3;
    z-index: 1; /* Ensures the overlay is on top */
}

.course-card-component-container-top-bar-left-image {
    position: absolute;
    top: 5px; 
    left: 10px;
    font-size: 15px;
    color: white;
    z-index: 2;
}

.course-card-component-info-bar { 
    position: relative;
    top: 40%;
    width: 100%;
    height: 40px;
    line-height: 42px;
}

.course-card-component-info-bar-title { 
    padding-left: 10px;
    padding-top: 3px;
    font-weight: bold;
    font-size: 14px;
}

.course-card-component-info-bar-details-title { 
    position: relative;
    top: 39%;
    padding: 20px;
    font-weight: 550;
    font-size: 22px;
}

.course-card-component-info-bar-details-description { 
    position: relative;
    top: 31%;
    padding: 20px;
    font-size: 16px;
    color: gray;
}

.gradient-div {
    position: absolute;
    top: 60%; /* Adjust this value as needed to position the gradient correctly */
    left: 0;
    width: 100%; /* Ensure it spans the full width of the container */
    height: 40%; /* Ensure it spans the full height of the container */
    z-index: 3; /* Increase the z-index so it's above other content */
}

.fill { 
    background-size: cover;
    background-position: center;
}

.progress-bar-container {
    position: absolute;
    top: 49%; /* Adjust this value as needed to position the gradient correctly */
    left: 0;
    width: 100%; /* Ensure it spans the full width of the container */
    height: 5px; /* Ensure it spans the full height of the container */
    background-color: #555;
    border-radius: 4px;
    overflow: hidden;
    z-index: 5;
}
.progress-bar {
    height: 5px;
    background: linear-gradient(to right, white, rgb(255, 166, 0));
    width: 0%; /* Initial state of the progress bar */
    transition: width 0.5s ease;
}

.show-more-btn {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 8px 16px;
    font-size: 14px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    z-index: 10; /* Adjusted z-index to ensure it's above other elements */
}

.show-more-btn:hover {
    background-color: #0056b3;
}

</style>