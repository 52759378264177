<template>
  <div class="container">
    <h1 class="coming-soon">Coming Soon 🚀</h1>
  </div>
</template>

<script>
export default {
  data() {
    return {
      darkMode: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
    };
  },
  mounted() {
    const handler = (e) => {
      this.darkMode = e.matches;
      this.applyTheme();
    };
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handler);
    this.applyTheme();
  },
  methods: {
    applyTheme() {
      document.body.classList.toggle('dark-mode', this.darkMode);
      document.body.classList.toggle('light-mode', !this.darkMode);
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.coming-soon {
  font-size: 3rem;
  font-weight: bold;
}

.light-mode .coming-soon {
  color: #000;
  background-color: #fff;
}

.dark-mode .coming-soon {
  color: #fff;
}

body {
  margin: 0;
}
</style>
