<template>
    <div class="progress-container">
      <div class="progress-bar" :style="{ width: `${progress}%`, backgroundColor: progressBarColor }"></div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        progress: 0,
      };
    },
    computed: {
      progressBarColor() {
        // Hue value changes between 0 and 360 for a full spectrum
        const hue = (this.progress / 100) * 360;
        return `hsl(${hue}, 100%, 50%)`; // Full saturation and lightness at 50%
      },
    },
    mounted() {
      window.addEventListener('scroll', this.updateProgress);
      this.updateProgress(); // Initialize progress on mount
    },
    beforeUnmount() {
      window.removeEventListener('scroll', this.updateProgress);
    },
    methods: {
      updateProgress() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrollProgress = (scrollTop / scrollHeight) * 100;
        this.progress = Math.min(100, Math.max(0, scrollProgress));
      },
    },
  };
  </script>
  
  <style scoped>
  .progress-container {
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    height: 5px;
    z-index: 1000;
  }
  
  .progress-bar {
    height: 100%;
    transition: width 0.2s ease, background-color 0.2s ease;
  }
  </style>
  