<template>
    <div class="quiz-container">
      <div v-if="currentQuestionIndex < questions.length">
        <h2>{{ currentQuestion.text }}</h2>
        <div class="options-container">
          <button 
            class="option-button" 
            v-for="(answer, index) in currentQuestion.answers" 
            :key="index"
            @click="selectAnswer(answer.isCorrect)"
          >
            {{ answer.text }}
          </button>
        </div>
        <div class="navigation">
          <button 
            class="nav-button" 
            @click="prevQuestion"
            :disabled="currentQuestionIndex === 0"
          >
            Back
          </button>
          <div class="pagination">
            <button
                v-for="n in questions.length"
                :key="n"
                :class="['page-button', { 'current-question': currentQuestionIndex === n - 1 }]"
                @click="goToQuestion(n - 1)"
            >
                {{ n }}
            </button>
          </div>
          <button 
            class="nav-button" 
            @click="nextQuestion"
            :disabled="currentQuestionIndex >= questions.length - 1"
          >
            Next
          </button>
        </div>
      </div>
      <div v-else>

        <img v-if="score === questions.length" src="../../public/gifs/giphy-1.gif" />
        <img v-if="score !== questions.length" src="../../public/gifs/giphy-5.gif" />

        <h2>Your Score: {{ score }}/{{ questions.length }}</h2>
        <button @click="restartQuiz">Restart Quiz</button>
      </div>
    </div>
  </template>
  
  
  <script>

  export default {
    components: {
    },
    data() {
      return {
        questions: [
          // Sample question format
          {
            text: "Which Swift statement is valid?",
            answers: [
              { text: 'print("Hello world"); print("Goodbye");', isCorrect: true },
              { text: "print('Hello world'); print('Goodbye');", isCorrect: false },
              // Add more options here
            ],
          },
          {
            text: "What is 3 + 2?",
            answers: [
              { text: "5", isCorrect: true },
              { text: "22", isCorrect: false },
              // Add more options here
            ],
          },
          {
            text: "What is 3 + 2?",
            answers: [
              { text: "5", isCorrect: true },
              { text: "22", isCorrect: false },
              // Add more options here
            ],
          },
          {
            text: "What is 3 + 2?",
            answers: [
              { text: "5", isCorrect: true },
              { text: "22", isCorrect: false },
              // Add more options here
            ],
          },
          // Add more questions here
        ],
        currentQuestionIndex: 0,
        score: 0,
      };
    },
    computed: {
      currentQuestion() {
        return this.questions[this.currentQuestionIndex];
      },
    },
    methods: {
      selectAnswer(isCorrect) {
        if (isCorrect) this.score++;
        this.currentQuestionIndex++;
      },
      restartQuiz() {
        this.currentQuestionIndex = 0;
        this.score = 0;
      },
      prevQuestion() {
        if (this.currentQuestionIndex > 0) this.currentQuestionIndex--;
      },
      nextQuestion() {
        if (this.currentQuestionIndex < this.questions.length - 1) this.currentQuestionIndex++;
      },
      goToQuestion(index) {
        this.currentQuestionIndex = index;
      },
    },
  };
  </script>


<style>
.quiz-container {
  max-width: 500px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 100px;
}

h2 {
  color: #333;
  font-size: 24px;
}

.options-container {
  margin: 20px 0;
}

.option-button {
  display: block;
  width: 100%;
  padding: 15px;
  margin-bottom: 10px;
  background: #eee;
  color: #333;
  text-align: left;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.option-button:hover {
  background: #ddd;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.nav-button {
  padding: 10px 20px;
  background: #ddd;
  color: #333;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.nav-button:disabled {
  background: #f5f5f5;
  color: #bbb;
  cursor: not-allowed;
}


.pagination {
  display: flex;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Prevent vertical scrolling */
  white-space: nowrap; /* Prevent wrapping to a new line */
  gap: 0; /* Remove spacing between items */
}

.page-button {
  padding: 5px 10px;
  background: #eee; /* default background */
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.page-button.current-question {
  background-color: #b30059; /* active button background */
  color: white; /* active button text color */
}

/* Add custom scrollbar styling if desired */
.pagination::-webkit-scrollbar {
  height: 4px;
}

.pagination::-webkit-scrollbar-thumb {
  background-color: #b30059; /* Or any color of choice */
  border-radius: 2px;
}

.pagination::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* Add responsiveness if needed */
@media (max-width: 600px) {
  .quiz-container {
    width: 90%;
    box-sizing: border-box;
  }
}
</style>