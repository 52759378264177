<template>
  <div class="fw">
    <home-title :title="title"></home-title>
    <div class="alert alert-info" role="alert">
        <span class="quote"> {{ quoteText }} </span>
    </div>
  </div>
</template>

<script>
import HomeTitleComponent from './HomeTitleComponent.vue';

export default {
  data() {
    return {
      quote: 'The best way to the future is to invent it.',
      author: 'Alan Kay',
    };
  },
  components: {
    'home-title': HomeTitleComponent,
  },
  computed: {
    title () {
      return `📅 ${ this.dateTimeString }`;
    },
    dateTimeString() {
      return new Date().toDateString();
    },
    quoteText() {
      return `"${this.quote}" - ${this.author}`;
    },
  },
};
</script>

<style scoped>

.fw { 
  width: 100%;
}

.quote { 
  font-weight: bold; 
  color: #121212;
}

@media (prefers-color-scheme: dark) {
    .quote {
        color: white;
    }
}

</style>