<template>
  <p class="home-title">{{ title }}</p>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    }
  }
};
</script>

<style scoped>

/* Add dark mode support */ 

.home-title { 
  font-weight: bold; 
  color: #121212;
  width: 100%; 
  padding-bottom: 10px; 
  text-align: left;
}

@media (prefers-color-scheme: dark) {
    .home-title  {
        color: rgb(0, 255, 191); 
    }
}

</style>