const axios = require('axios');
const uuid = require('uuid');

const getNotes = async (courseId, userId) => {
  let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_BASE_URL}/notes/${courseId}/${userId}`,
      headers: { 
          'Content-Type': 'application/json'
      }
  };
  try {
      const response = await axios.request(config);
      return response.data;
  } catch (error) {
      throw error;
  }
}

const postNote = async (courseId, userId, text, color, time, episodeTitle, createdAt) => {
    const data = JSON.stringify({
        "PK": "NOTE",
        "SK": `${uuid.v4()}`,
        "text": text,
        "color": color,
        "time": time,
        "episodeTitle": episodeTitle,
        "createdAt": createdAt,
        "GSI5PK": `COURSE#${courseId}`,
        "GSI5SK": `USER#${userId}`
    });
    const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_BASE_URL}/notes`,
        headers: { 
            'Content-Type': 'application/json'
        },
        data : data
    };
    try { 
        const response = await axios.request(config);
        return response.data;
    } catch (error) {
        throw error;
    }
}

const deleteNote = async (noteId) => {
    let config = {
        method: 'delete',
        maxBodyLength: Infinity,
        url: `${process.env.VUE_APP_BASE_URL}/notes/${noteId}`,
        headers: { 
            'Content-Type': 'application/json'
        }
    };
    try {
        const response = await axios.request(config);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export {
  getNotes,
  postNote,
  deleteNote
}