<template>
  <div style="padding: 10px;">
    <div :class="showHover ? 'background-hover' : ''">
        <div class="colummns"> 
            <div class="column" style="text-align: left;">
                <h1 class="title is-2" style="color: var(--snippet-title-color)">
                   <span v-if="snippet.spotlight"> 💡 </span> 
                   <span v-if="snippet.is_episode"> 📺 </span>
                   {{ snippet.title }}
                </h1>
                <h3 
                    class="subtitle is-4" 
                    style="text-align: left; padding-left: 20px; padding-right: 20px; color: var(--snippet-subtitle-color)">
                    {{ snippet.subtitle}}
                </h3>
            </div>
            <div style="text-align: left;"> 
                <span class="updatedAt"> {{ displayDate(snippet) }} • {{ displayAuthor(snippet) }}</span>
            </div>
            <div class="tags-container">
                <ul class="tags-list">
                    <li v-for="(tag, key) in JSON.parse(snippet.tags)" :key="key">
                        <tag v-if="key != 'author'" :snippet="snippet" :k="key" :tag="tag" />
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import TagComponent from './Tag.vue';
export default {
  props: { 
    snippet: { 
        type: Object,
        required: true
    },
    showHover: { 
        type: Boolean,
        default: false
    }
  }, 
  components: {
    'tag': TagComponent
  },
  data() {
    return {};
  },
  methods: {
    displayDate(snippet) { 
        const date = new Date(snippet.updated_at);
        return date.toDateString();
    },
    displayAuthor(snippet) { 
        const tags = JSON.parse(snippet.tags);
        return tags.author;
    },
    tagClass(key) { 
        switch(key) { 
            case 'platform': 
                return 'tag is-primary is-light';
            case 'language': 
                return 'tag is-link is-light';
            case 'category':
                return 'tag is-warning is-light';
            case 'twitter':
                return 'tag is-info is-light';
            default: 
                return 'yellow';
        }
    },
    getEmoji(key) { 
        const tags = JSON.parse(this.snippet.tags);
        switch (key) { 
            case 'platform': 
                if (tags.platform === 'iOS') {
                    return '📱';
                } else if (tags.platform === 'Android') {
                    return '🤖';
                } else if (tags.platform === 'Web') {
                    return '🌐';
                } else {
                    return '🔥';
                }
            case 'language': 
                return '🔤';
            case 'category':
                return '📦';
            case 'twitter':
                return '🐦';
            case 'author':
                return '👤';
            default: 
                return '🔥'
        };
    }
  }
};
</script>

<style scoped>

@media (prefers-color-scheme: light) {
    .updatedAt {
        color: #9b9ea8;
    }
}

@media (prefers-color-scheme: dark) {
    .updatedAt {
        color: rgba(255, 255, 0, 0.524);
    }
}

.updatedAt {
    font-size: 0.8em;
    padding-left: 34px;
    text-align: left;
}

.tags-container {
    margin: 0px 0; /* Adjusts the margin around the tags list for spacing */
}

.tags-list {
    list-style: none; /* Removes the default list styling */
    padding: 0; /* Removes the default padding */
    margin: 0; /* Removes the default margin */
    margin-top: 10px;
    display: flex; /* Displays the list items in a row */
    flex-wrap: wrap; /* Allows the list to wrap onto the next line if needed */
    font-weight: bold;
    padding-left: 30px;
    padding-bottom: 10px;
}

.tags-list li {
    margin-right: 5px; 
    text-decoration: none; /* Removes the underline from links */
    padding: 5px 5px; /* Adds some padding around the text */
    border-radius: 5px; /* Rounds the corners of the tag background */
    font-size: 0.4em; /* Slightly smaller font size */
}

.tag { 
    padding: 5px;
}

</style>