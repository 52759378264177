<template>
    <div>
        <h5 class="card-title">Forgot Password 🙉</h5>
        <p style="color: red; font-size: 16px;"> TODO: Implement this code </p>
        <div class="alert alert-warning" role="alert" v-if="isSendError">
            🚨 Send failed. Please try again.
        </div>
        <div class="alert alert-success" role="alert" v-if="isSendComplete">
            🎉 Send successful! Your account was created!
        </div>
        <form @submit.prevent="submitForm">
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" class="form-control" id="email" v-model="email" required>
                <small v-if="submitted && !isEmailValid" class="text-danger">Please enter a valid email address.</small>
            </div>
            <router-link :to="`/login`" class="btn btn-style" style="background-color: #3e2ce2; color: white; height: 40px; line-height: 1; font-size: 16px;">Back</router-link>
            <button type="submit" class="btn btn-style fr" style="background-color: #c42ce2; color: white; height: 40px; line-height: 1; font-size: 16px;">Send forgot password email</button>
        </form>
    </div>
</template>

<script>
export default {
    name: 'ForgotPasswordComponent',
    props: {
        isInModal: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            email: '',
            isEmailValid: true,
            isConfirmPasswordValid: true,
            isSendError: false,
            isSendComplete: false,
            submitted: false // Track if the form has been submitted
        };
    },
    methods: {
        submitForm() {
            this.submitted = true; // Set submitted to true when form is submitted
            this.validateForm();
            if (this.isEmailValid) {
                this.isSendError = false;
                this.isSendComplete = true;
                // Proceed with registration logic
            } else {
                this.isSendComplete = false;
                this.isSendError = true;
            }
        },
        validateForm() {
            this.isEmailValid = this.validateEmail(this.email);
        },
        validateEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
    }
};
</script>

<style scoped>

/* SAME CSS AS REGISTER VIEW (REFACTOR LATER) */

.container {
    margin-top: 50px;
}

.btn-style {
    background-color: indigo; 
    color: white;
    font-size: 24px;
}

.fr { 
    float: right;
}

.card-title {
    font-size: 24px;
    width: 100%;
}

label { 
    font-size: 18px;
    color: gray;
}

input { 
    font-size: 16px;
}

form { 
    margin-top: 20px;
}

.text-danger { 
    font-size: 18px;
}
.alert { 
    font-size: 18px;
}

.vertical { 
    display: flex;
    flex-direction: column;
    align-items:baseline;
    justify-content: left;
    margin-bottom: 20px;
}

/* END SAME CSS AS REGISTER VIEW (REFACTOR LATER) */

</style>
