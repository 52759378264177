<template>
    <div> 
        <div class="vertical"> 
            <CustomButton
                style="width: 100%;"
                class="fr"
                imageSrc="../../button-icons/google.svg"
                title="Sign in with Google"
                :onClick="signInWithGoogle"
            />
        </div>
        <div class="vertical"> 
            <router-link v-if="!isInModal" :to="`/login`" style="width: 100%;">
                <CustomButton
                    style="width: 100%;"
                    class="fr"
                    title="Sign in with email and password"
                />
            </router-link>
            <CustomButton
                v-else
                style="width: 100%;"
                class="fr"
                title="Sign in with email and password"
                :onClick="goToLogin"
            />
        </div>
        <div class="or-container">
            <div class="line"></div>
            <div class="text">or</div>
            <div class="line"></div>
        </div>

        <h5 class="card-title">Create an account</h5>
        <div class="alert alert-warning" role="alert" v-if="isRegistrationError">
            🚨 Registration failed. {{ errorMessage }}
        </div>
        <div class="alert alert-success" role="alert" v-if="isRegistrationComplete">
            🎉 Registration successful! Your account was created!
        </div>
        <form @submit.prevent="submitForm">
            <div class="form-group">
                <label for="name">Name</label>
                <input type="text" class="form-control" id="name" v-model="name" required>
                <small v-if="submitted && !isNameValid" class="text-danger">Please enter a valid name.</small>
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" class="form-control" id="email" v-model="email" required>
                <small v-if="submitted && !isEmailValid" class="text-danger">Please enter a valid email address.</small>
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="password" v-model="password" required>
                <small v-if="submitted && !isPasswordValid" class="text-danger">Please enter a valid password.</small>
            </div>
            <div class="form-group">
                <label for="confirm-password">Confirm Password</label>
                <input type="password" class="form-control" id="confirm-password" v-model="confirmPassword" required>
                <small v-if="submitted && !isConfirmPasswordValid" class="text-danger">Passwords do not match.</small>
            </div>
            <CustomButton
                type="submit"
                class="fr"
                title="Register 🎉"
            />
        </form>
    </div>
</template>

<script>
import { auth, GoogleAuthProvider, signInWithPopup } from '../firebase/client.js';
import { register } from '../../src/apis/auth.js';
import { joinCourse, getCoursesByExternalId } from '../../src/apis/courses.js';
import { getPaymentIntents } from '../../src/apis/stripe.js';
import { mapActions, mapState } from 'vuex';
import CustomButton from './CustomButton.vue';

export default {
    props: {
        isFromAdvertisement: {
            type: Boolean,
            required: true
        },
        isInModal: {
            type: Boolean,
            default: false
        },
        course: {
            type: Object,
            required: false
        }
    },
    components: {
        CustomButton
    },
    data() {
        return {
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            isNameValid: true,
            isEmailValid: true,
            isPasswordValid: true,
            isConfirmPasswordValid: true,
            isRegistrationError: false,
            isRegistrationComplete: false,
            errorMessage: '',
            submitted: false // Track if the form has been submitted
        };
    },
    computed: {
        ...mapState([
            'user'
        ])
    },
    methods: {
        ...mapActions([
            'updateUserAction',
            'updateMyCoursesAction',
            'updatePaymentsAction'
        ]),
        getMyCourses() { 
            getCoursesByExternalId(this.user.uid)
                .then((myCourses) => {
                    this.updateMyCoursesAction(myCourses);
                    this.getPaymentIntents();
                })
        },
        getPaymentIntents() { 
            getPaymentIntents(this.user.uid)
                .then((paymentIntents) => {
                    this.updatePaymentsAction(paymentIntents);
                    if (!this.$store.getters.didPurchaseCourse(this.course.SK)) { 
                        this.$emit('goToPage', 3);
                    } else {
                        this.$emit('goToPage', 5);
                    }
                })
        },
        async signInWithGoogle() {
            try {
                const provider = new GoogleAuthProvider();
                const userCredential = await signInWithPopup(auth, provider);
                const user = userCredential.user;

                this.updateUserAction(user);

                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('session', user.stsTokenManager.accessToken);

                this.isRegistrationComplete = true;
                this.isRegistrationError = false;

                this.emitter.emit("user-logged-in", { isLoggedIn: true });
               
                if (this.isInModal) {
                    this.takeCourse();
                } else {
                    setTimeout(() => {
                        this.$router.push('/dashboard');
                    }, 1000);
                }

            } catch (error) {
                this.isRegistrationComplete = false;
                this.isRegistrationError = true;
                this.errorMessage = error.message;
                setTimeout(() => {
                    this.errorMessage = ' Try again.';
                }, 5000);
                this
            }
        },
        registerUser() {

            register(this.name, this.email, this.password).then((user) => {
               
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('session', user.stsTokenManager.accessToken);
               
                this.updateUserAction(user);
                
                this.isRegistrationComplete = true;
                this.isRegistrationError = false;

                this.emitter.emit("user-logged-in", { isLoggedIn: true });
            
                if (this.isInModal) {
                    this.takeCourse();
                } else {
                    setTimeout(() => {
                        this.$router.push('/dashboard');
                    }, 1000);
                }
            }).catch((error) => {
                this.isRegistrationComplete = false;
                this.isRegistrationError = true;
                this.errorMessage = error.message;

                setTimeout(() => {
                    this.errorMessage = 'Try again.';
                }, 5000);            
            });
        },
        takeCourse() { 
            if (!this.user) {
                console.error(`User not logged in! cannot join course.`);
                return
            }
            joinCourse(this.course.SK, this.user.uid)
                .then(() => {
                    this.getMyCourses();
                })
        },
        submitForm() {
            this.submitted = true; // Set submitted to true when form is submitted
            this.validateForm();
            if (this.isNameValid && this.isEmailValid && this.isPasswordValid && this.isConfirmPasswordValid) {
                this.registerUser();
            } else {
                this.isRegistrationComplete = false;
                this.isRegistrationError = true;
            }
        },
        validateForm() {
            this.isNameValid = this.validateName(this.name);
            this.isEmailValid = this.validateEmail(this.email);
            this.isPasswordValid = this.validatePassword(this.password);
            this.isConfirmPasswordValid = this.validateConfirmPassword(this.password, this.confirmPassword);
        },
        validateName(name) {
            return name.length >= 2;
        },
        validateEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        validatePassword(password) {
            return password.length >= 6;
        },
        validateConfirmPassword(password, confirmPassword) {
            return password === confirmPassword;
        },
        goToLogin() {
            this.$emit('goToPage', 2);
        }
    },
    mounted() { 
        document.querySelectorAll('input[type="text"]').forEach(input => {
            input.value = '';
        });
    }
};
</script>

<style scoped>
.container {
    margin-top: 50px;
}

input:focus {
    box-shadow:none;
}

.fr { 
    float: right;
}

.card-title {
    font-size: 20px;
    width: 100%;
}

label { 
    font-size: 14px;
    color: gray;
}

input { 
    font-size: 16px;
}

form { 
    margin-top: 20px;
}

.text-danger { 
    font-size: 18px;
}
.alert { 
    font-size: 18px;
}

.vertical { 
    display: flex;
    flex-direction: column;
    align-items:baseline;
    justify-content: left;
    margin-bottom: 20px;
}

@media (prefers-color-scheme: dark) {
    .button { 
        border: 1px solid #313030;
        color: white;
    }
    .button:hover { 
        background-color: black;
        border: 1px solid yellow;
        color: yellow;
    }
    input { 
        background-color: black;
        border: 1px solid #313030;
        color: white;
    }

    input:focus {
        background: black;
        border: 1px solid yellow;
        outline: none;
        color: yellow;
    }
}

.or-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.line {
  height: 1px;
  background-color: #313030;
  flex-grow: 1;
}

.text {
  margin: 0 10px;
  white-space: nowrap;
  color: #313030;
}

</style>