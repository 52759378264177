<template>
  <div class="bg"> 
    <div v-if="isLoading" style="width: 100%; height: 100vh;">
      <SpinnerComponent /> <!-- this doesn't work -->
  </div>
  <div v-else> 
      <div class="container container-content" :style="{ 'padding-top': '80px', 'width': windowWidth < 768 ? '100%' : '60%', 'margin': '0 auto', 'position': 'relative'}">
        <div class="button-container">
          <dark-mode-button style="margin-left: -3px;" @click="leave()" title="👈 Back" />
          <div class="series-title"> 
            {{ this.getSeries.title }} {{ currentPage }} / {{ JSON.parse(getSeries.snippets).length }}
          </div>
          <dark-mode-button v-if="currentPage == JSON.parse(getSeries.snippets).length" style="margin-left: -3px;" @click="finishSeries()" title="✅ Complete Series" />
        </div>
        <div style="padding-left: 30px; padding-right: 30px; padding-bottom: 20px;" v-if="isSpotlight">
          <swift-spotlight :snippet="snippet" />
        </div>
        <div style="padding-left: 30px; padding-right: 30px; padding-bottom: 20px;" v-if="isEpisode">
          <img :src="snippet.episode_thumbnail" style="object-fit: cover; width: 100%; height: 200px; border-radius: 10px; box-shadow: 0 4px 6px rgba(0,0,0,0.1); margin-bottom: 20px;"/>
        </div>
        <div v-if="snippet" class="columns background-hover">
          <SnippetRowView :snippet="snippet" />
        </div>
        <div class="markdown-body" :style="preview" v-html="compiledMarkdown"></div>
      </div>
    </div>
    <series-progress 
      :currentPage="currentPage" 
      :totalPages="JSON.parse(getSeries.snippets).length" 
      @prev-click="prev"
      @next-click="next"
      />
  </div>
</template>
  
<script>
  
import { getSeries } from '@/apis/snippets';  
import SpinnerComponent from '@/components/SpinnerComponent.vue';
import SnippetRowView from '@/components/SnippetRowView.vue';
import Like from '@/components/Like.vue';
import SwiftSpotlight from '@/components/home/SwiftSpotlight.vue';
import DarkModeButton from '@/components/DarkModeButton.vue';
import SeriesProgressComponent from '@/components/series/SeriesProgressComponent.vue';

import { mapGetters } from 'vuex';
import MarkdownIt from 'markdown-it';
import Prism from 'prismjs';
import 'prismjs/components/prism-swift'; // Import Swift language support
import 'prismjs/themes/prism.css';
import '../../../../public/markdown.css';
import 'prismjs/themes/prism-okaidia.css'; 

export default {
    name: 'SnippetView',
    components: {
      'swift-spotlight': SwiftSpotlight,
      'dark-mode-button': DarkModeButton,
      'series-progress': SeriesProgressComponent,
      SpinnerComponent, 
      SnippetRowView, 
      Like
    },
    data() {
      return {
        windowWidth: window.innerWidth,
        snippet: null,
        isLoading: false,
        markdownContent: this.value ? this.value : '',
        compiledMarkdown: '',
        theme: 'light',
        headers: [],
        md: new MarkdownIt({
          html: true,
          linkify: true,
          typographer: true,
          breaks: true,
          highlight: function (str, lang) {
            if (lang && Prism.languages[lang]) {
              try {
                return Prism.highlight(str, Prism.languages[lang], lang);
              } catch (e) {
                console.error(`Failed to highlight code: ${e}`);
              }
            }
            return '';
          }
        }),
      };
    },
    computed: {
      ...mapGetters(['getSummaryWidth', 'getSeries']),
      isSpotlight() {
        return this.snippet && this.snippet.spotlight;
      },
      isEpisode() {
        return this.snippet && this.snippet.is_episode;
      },
      preview() {
        return {
          overflowY: 'auto',
          height: '100%',
          width: '100%',
          border: '0px solid pink',
          margin: '0 auto',
          paddingBottom: '100px',
          paddingTop: '30px',
          background: this.theme === 'light' ? 'white' : '#000000',
        };
      },
      currentPage() { 

        if (this.snippet === null) {
          return 0;
        }

        const snippets = JSON.parse(this.getSeries.snippets)
        const indexOfCurrentSnippet = snippets.findIndex((snippet) => snippet.SK === this.snippet.SK);
        // console.log(`Index of current snippet: ${indexOfCurrentSnippet}`)
        return indexOfCurrentSnippet + 1;
      }
    },
    mounted() {
      this.$store.state.isPresentingNavbar = true;

      if (this.getSeries.snippets === undefined) {
        this.fetchSnippet(this.$route.params.seriesId);
      } else {
        this.snippet = JSON.parse(this.getSeries.snippets)[0];
        // console.log(`✅ Spotlight: ${JSON.stringify(this.snippet.spotlight)}`);
        this.markdownContent = this.snippet.resources;
        this.updatePreview();
      }

      this.updatePreview();
    
      const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
      
      // Set the initial theme based on the user's preference
      this.handleThemeChange(prefersDarkScheme);
      
      // Listen for changes in the media query
      prefersDarkScheme.addListener(this.handleThemeChange);


      window.addEventListener('resize', () => {
          this.windowWidth = window.innerWidth;
      });
    },
    methods: {
      finishSeries() {
        this.$router.push(`/series`);
      },
      canGoBack() { 
        if (this.snippet === null) {
          return false;
        }

        const snippets = JSON.parse(this.getSeries.snippets)
        const indexOfCurrentSnippet = snippets.findIndex((snippet) => snippet.SK === this.snippet.SK);
        // console.log(`Index of current snippet: ${indexOfCurrentSnippet}`)

        return indexOfCurrentSnippet > 0;
      },
      canGoNext() { 
        if (this.snippet === null) {
          return false;
        }

        const snippets = JSON.parse(this.getSeries.snippets)
        const indexOfCurrentSnippet = snippets.findIndex((snippet) => snippet.SK === this.snippet.SK);
        // console.log(`Index of current snippet: ${indexOfCurrentSnippet}`)

        return indexOfCurrentSnippet < snippets.length - 1;
      },
      next() {
        const snippets = JSON.parse(this.getSeries.snippets)
        const indexOfCurrentSnippet = snippets.findIndex((snippet) => snippet.SK === this.snippet.SK);
        // console.log(`Index of current snippet: ${indexOfCurrentSnippet}`)

        if (indexOfCurrentSnippet === snippets.length - 1) {
          return;
        }
        
        const nextSnippetInSeries = snippets[indexOfCurrentSnippet + 1];
        // console.log(`Next snippet in series: ${JSON.stringify(nextSnippetInSeries.SK)}`)
        this.snippet = nextSnippetInSeries;
        this.markdownContent = this.snippet.resources;
        this.updatePreview();
      },
      prev() {
        const snippets = JSON.parse(this.getSeries.snippets)
        const indexOfCurrentSnippet = snippets.findIndex((snippet) => snippet.SK === this.snippet.SK);
        // console.log(`Index of current snippet: ${indexOfCurrentSnippet}`)

        if (indexOfCurrentSnippet === 0) {
          return;
        }

        const nextSnippetInSeries = snippets[indexOfCurrentSnippet - 1];
        // console.log(`Next snippet in series: ${JSON.stringify(nextSnippetInSeries.SK)}`)
        this.snippet = nextSnippetInSeries;
        this.markdownContent = this.snippet.resources;
        this.updatePreview();
      },
      updatePreview() {
        this.compiledMarkdown = this.md.render(this.markdownContent);
        this.$emit('input-updated', this.markdownContent);
      },
      handleThemeChange(e) {
        this.theme = e.matches ? 'dark' : 'light';
        this.updatePreview();
      },
      fetchSnippet(snippetId) { 
        if (this.isLoading) { 
          return 
        }
        this.isLoading = true
        getSnippet(snippetId)
            .then((response) => {
                this.capturePageView(response);
                this.snippet = response;
                // console.log(`✅ Spotlight: ${JSON.stringify(this.snippet.spotlight)}`);
                this.markdownContent = this.snippet.resources;
                this.updatePreview();
                this.isLoading = false;
            })
            .catch((error) => {
                console.error(error);
                this.isLoading = false;
            });
      }, 
      leave() {
        this.$router.go(-1);
      },
      capturePageView(snippet) {
        this.$posthog.capture('snippet_view', {
          'snippet': snippet,
          'date': new Date().toISOString(),
        });
      },
    },
    beforeDestroy() {
      window.matchMedia('(prefers-color-scheme: dark)').removeListener(this.handleThemeChange);
    },
  };
</script>
  
<style scoped>

  /* Hide scrollbar for Chrome, Safari and Opera */
.markdown-body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.markdown-body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding: 30px;
}

.button-container {
  display: flex;
  justify-content: space-between; /* Aligns children (wave button and buttons-right) on each side */
  padding-top: 0px;
  padding-bottom: 40px;
  padding-left: 30px;
  padding-right: 30px; /* Added padding-right for symmetry */
}

.button-container .series-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 36px;
  font-weight: bold;
}

.buttons-right {
  display: flex; /* This keeps the like and dislike buttons grouped together */
}

.bg { 
  margin-top: 20px;
}

@media (prefers-color-scheme: light) {
  .bg { 
    background: white;
  }
}

@media (prefers-color-scheme: dark) {
  .bg { 
    background: black;
  }

  .button-container .series-title {
    color: rgb(0, 255, 191); ;
  }
}

</style> 