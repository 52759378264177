<template>
  <div class="footer">
    <button @click="$emit('prev-click')" :disabled="prevDisabled">Previous</button>
    <div class="progress-container">
      <div v-for="page in totalPages" :key="page" :class="['progress-chip', { 'active': page === currentPage }]"></div>
    </div>
    <button @click="$emit('next-click')" :disabled="nextDisabled">Next</button>
  </div>
</template>

<script>
export default {
  name: 'StickyFooter',
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    },
    prevDisabled: {
      type: Boolean,
      default: false
    },
    nextDisabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>

@media (prefers-color-scheme: dark) {

  .footer {
    background-color: #121212;
    color: white;
  }

  .footer button {
    background-color: rgb(0, 255, 191); ;
    color: black;
  }

  .progress-chip {
    background-color: white;
  }

  .progress-chip.active {
    background-color: rgb(0, 255, 191); ;
  }
}

@media (prefers-color-scheme: light) {

.footer {
  background-color: white;
}

.footer button {
  background-color: blueviolet;
  color: white;
}

.progress-chip {
  background-color: white;
}

.progress-chip.active {
  background-color: blueviolet;
}
}

.footer {
  position: sticky;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.footer button {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.footer button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-chip {
  width: 10px;
  height: 10px;
  margin: 0 5px;
  background-color: lightgray;
  border-radius: 50%;
}

.progress-chip.active {}
</style>
