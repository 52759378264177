<template>
  <product-list />
</template>

<script>
import AvatarProgressComponent from '../components/AvatarProgressComponent.vue';
import DownloadComponent from '../components/DownloadComponent.vue';
import PixelatedButton from '../components/PixelatedButton.vue';


export default {
  name: 'LearningPath',
  components: {
    'circular-avatar': AvatarProgressComponent,
    'product-list': DownloadComponent,
    'PixelatedButton': PixelatedButton
  }, 
  data() {
    return {
      windowWidth: window.innerWidth,
    }
  },
  mounted() {
    this.$store.state.isPresentingNavbar = true;
  }
}
</script>

<style scoped>

.color-box {
  /* Adjust the size of the div as needed */
  width: 100%;
  height: 100vh;
  transition: background-color 1s; /* Smooth transition between colors */
}

.centered-image {
  display: block;
  margin: 0 auto;
  max-width: 200px;
  width: 100%;
  margin-bottom: 40px;
}

.hero-container {
  float: left; position: relative;
  background: url('@/assets/background_2.png') center center / cover no-repeat;
  background-color: #1A48FE;
  width: 100%;
  height: 50%;
  margin-top: 70px;
}

.hero-container::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  opacity: 0.2;
  pointer-events: none;
} 

/* h1 { 
  font-size: 4rem;
  font-weight: bold;
  color: white;
}

h2 { 
  color: #ECEEF4;
} */

.learning-path {
  display: flex;
  align-items: center;
  background: black;
  background-size: cover;
  color: white;
  margin-top: 70px;
}

.progress-indicator {
  /* Styles for your progress indicator (circular or horizontal) */
  font-size: 3rem;
  margin-right: 1rem;
  /* Add more styles as needed */
}

.content {
  margin: 0 auto;
  padding: 3rem;
  background-color: rgba(40, 0, 57, 0.451);
}

.content h1, .content h2, .content p {
  margin: 0;
  /* Add more styles for typography as needed */
}


.title {
  font-size: 72px; /* Adjust font size as necessary */
  font-weight: bold;
  color: white;
  text-align: center;
}

.subtitle { 
  font-size: 36px;
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
}


.buttons {
  display: flex;
  justify-content: center; /* Centers the buttons horizontally */
  gap: 10px; /* Optional: adds space between the buttons */
  margin-top: 50px;
}

.btn {
  background: transparent;
  color: #ffffff;
  border: 2px solid #ffffff;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  margin: 5px;
  transition: all 0.3s ease;
}

.btn:hover {
  background: #ffffff;
  color: #000000;
}

.btn-primary {
  background: #8A2BE2; /* Adjust primary color as necessary */
  border-color: #8A2BE2;
}

.btn-primary:hover {
  background: #7B1FA2; /* Adjust on hover color as necessary */
}

/* Add media queries and additional styling as needed */
</style>
