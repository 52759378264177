const axios = require('axios');

// API: 
// auth.routes.js
// auth.controller.js
// auth.model.js

exports.login = (email, password) => {
    return new Promise((resolve, reject) => {
        let data = JSON.stringify({
            "email": email,
            "password": password
        });
        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `${process.env.VUE_APP_BASE_URL}/auth/user/signin`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data : data
        };
        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                // console.log(error);
                reject(error);
            });
    });
}

exports.register = (name, email, password) => {
    return new Promise((resolve, reject) => {
        let data = JSON.stringify({
            "name": name,
            "email": email,
            "password": password
        });
        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `${process.env.VUE_APP_BASE_URL}/auth/user/register`,
          headers: { 
              'Content-Type': 'application/json'
          },
          data : data
      };
      axios.request(config)
      .then((response) => {
          const user = response.data;
          resolve(user);
      })
      .catch((error) => {
          // console.log(error);
          reject(error);
      })
    });
}

exports.logout = () => {
    return new Promise((resolve, reject) => {
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
            url: `${process.env.VUE_APP_BASE_URL}/auth/user/signout`,
            headers: { 
                'Content-Type': 'application/json'
            }
        };
        axios.request(config)
            .then((response) => {
                // console.log(JSON.stringify(response.data));
                resolve(response.data);
            })
            .catch((error) => {
                // console.log(error);
                reject(error);
            });
    });
}
