<template>
    <div class="emoji-picker">
      <div class="emoji-list" @click="emitEmoji($event)">
        <span v-for="emoji in emojis" :key="emoji" class="emoji">{{ emoji }}</span>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'EmojiPickerComponent',
    data() {
      return {
        // Include a sample of emojis here; extend this list based on your preference
        emojis: [
            '😀', '😁', '😂', '🤣', '😃', '😄', '😅', '😆',
            '😉', '😊', '😋', '😎', '😍', '😘', '😗', '😙',
            '😚', '🙂', '🤗', '🤩', '🤔', '🤨', '😐', '😑',
            '😶', '🙄', '😏', '😣', '😥', '😮', '🤐', '😯',
            '😪', '😫', '😴', '😌', '😛', '😜', '😝', '🤤',
            '😒', '😓', '😔', '😕', '🙃', '🤑', '😲', '☹️',
            '🙁', '😖', '😞', '😟', '😤', '😢', '😭', '😦',
            '😧', '😨', '😩', '🤯', '😬', '😰', '😱', '😳',
            '🤪', '😵', '😡', '😠', '🤬', '😷', '🤒', '🤕',
            '🤢', '🤮', '🤧', '😇', '🤠', '🤡', '🤥', '🤫',
            // Add more emojis as needed
            ]
      };
    },
    methods: {
      emitEmoji(event) {
        // Check if the clicked element is an emoji
        if (event.target && event.target.classList.contains('emoji')) {
          this.$emit('emojiSelected', event.target.textContent);
        }
      },
    },
  };
  </script>
  
  <style scoped>
.emoji-picker {
  width: 100%; /* Make the emoji picker take the full width of its parent */
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent emojis from wrapping to a new line */
  background: var(--emoji-picker-background); /* Background color */
  padding: 10px 0; /* Vertical padding */
}

.emoji-list {
  /* No flexbox properties needed */
}

.emoji {
  display: inline-block; /* Display emojis inline */
  cursor: pointer; /* Indicate that emojis are clickable */
  padding: 0 10px; /* Horizontal padding around each emoji */
  font-size: 24px; /* Size of emojis */
  line-height: 1; /* Adjust line height to fit the emoji size */
}

/* Style to remove the scrollbar visually but keep the scroll functionality */
.emoji-picker::-webkit-scrollbar {
  display: none;
}

</style>
  