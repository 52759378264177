<template>
  <div class="progress-container">
    <div class="progress-bar" :style="progressStyle"></div>
  </div>
</template>

<script>
export default {
  props: {
    // Prop to accept progress percentage (e.g., 50 for 50%)
    progress: {
      type: Number,
      required: true,
      default: 0
    }
  },
  computed: {
    // Compute the style to set the width of the progress bar based on the progress prop
    progressStyle() {
      return {
        width: `${this.progress}%`,
        backgroundImage: 'linear-gradient(to right, #FC963B, #FF3A28)'
      };
    }
  }
}
</script>

<style scoped>
.progress-container {
  width: 100%;
  height: 5px;
  background-color: #ddd; /* Light gray background for the part of the bar not filled */
  overflow: hidden; /* Ensures the inner div does not spill over the container */
}

.progress-bar {
  height: 100%;
  transition: width 0.3s ease; /* Smooth transition for width changes */
}
</style>
