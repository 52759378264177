<template>
    <div class="avatar-progress-wrapper" :style="{ width: size + 'px', height: size + 'px', background: 'transparent' }">
      <svg :width="size" :height="size">
        <path
          v-for="(segment, index) in progressSegments"
          :key="index"
          :d="calculatePath(segment.progress, index)"
          :stroke="segment.color"
          :stroke-width="strokeWidth"
          fill="transparent"
          stroke-linecap="round"
        />
      </svg>
      <img src="../../public/no-avatar.png" alt="Avatar" class="avatar-image" :style="avatarImageStyle"/>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CircularAvatar',
    data() {
      return {
        strokeWidth: 2
      };
    },
    props: {
      progressSegments: {
        type: Array,
        default: () => []
      },
      avatarUrl: {
        type: String,
        required: true
      },
      size: {
        type: Number,
        default: 120 // Default size
      }
    },
    computed: {
      radius() {
        return this.size / 2 - this.strokeWidth; // Adjust the radius based on the size and stroke width
      },
      center() {
        return this.size / 2;
      },
      strokeWidth() {
        return this.size / 15; // Adjust the stroke width based on the size
      },
      avatarImageStyle() {
        const imageSize = this.size - 2 * this.strokeWidth;
        return {
          width: imageSize + 'px',
          height: imageSize + 'px',
          top: this.strokeWidth + 'px',
          left: this.strokeWidth + 'px',
          borderRadius: '50%'
        };
      }
    },
    methods: {
      calculatePath(progress, index) {
        const startProgress = this.progressSegments.slice(0, index).reduce((acc, segment) => acc + segment.progress, 0);
        const startAngle = (startProgress / 100) * 360;
        const endAngle = ((startProgress + progress) / 100) * 360;
  
        const start = this.polarToCartesian(this.center, this.center, this.radius, endAngle);
        const end = this.polarToCartesian(this.center, this.center, this.radius, startAngle);
  
        const largeArcFlag = progress > 50 ? "1" : "0";
        const d = [
          "M", start.x, start.y,
          "A", this.radius, this.radius, 0, largeArcFlag, 0, end.x, end.y
        ].join(" ");
  
        return d;
      },
      polarToCartesian(centerX, centerY, radius, angleInDegrees) {
        const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
        return {
          x: centerX + radius * Math.cos(angleInRadians),
          y: centerY + radius * Math.sin(angleInRadians)
        };
      }
    }
  }
  </script>
  
  <style>
  .avatar-progress-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .avatar-image {
    position: absolute;
    object-fit: cover;
  }
  </style>
  