<template>
  <div>
    <div class="tabs">
      <div 
        v-for="(module, index) in course.modules" 
        :key="module.SK" 
        class="tab" 
        :class="{ active: currentTab.SK === module.SK }" 
        :style="{ 
          borderBottomColor: tabColors[index], 
          color: currentTab.SK === module.SK ? tabColors[index] : isDarkMode ? '#000' : '#fff', 
          backgroundColor: currentTab.SK === module.SK ? isDarkMode ? '#000' : '#fff' : tabColors[index]
        }" 
        @click="selectTab(module, index)"
      >
        {{ module.title }}
      </div>
    </div>
    <div class="content">
      <MainHeroTabDataComponent :module="currentTab" />
    </div>
  </div>
</template>

<script>
import MainHeroTabDataComponent from '@/components/MainHeroTabDataComponent.vue';

export default {
  name: 'MainHeroTabComponent',
  props: {
    course: {
      type: Object,
      required: true
    }
  },
  components: {
    MainHeroTabDataComponent
  },
  created() {
    // Set up an event listener for changes in the color scheme preference
    this.matchMedia = window.matchMedia('(prefers-color-scheme: dark)');
    this.matchMedia.addListener(this.prefersColorSchemeChanged);
  },
  destroyed() {
    // Clean up the event listener when the component is destroyed
    this.matchMedia.removeListener(this.prefersColorSchemeChanged);
  },
  mounted() {
    // Set the initial tab to the first module
    // console.log(`TAB COLORS: ${JSON.stringify(this.tabColors)}`)
  },
  computed: {
    tabColors() {
      return this.course.modules.map(module => module.color);
    }
  },
  data() {
    return {
      currentTab: this.course.modules[0],
      isDarkMode: window.matchMedia('(prefers-color-scheme: dark)').matches
    };
  },
  methods: {
    selectTab(selectedTab) {
      this.currentTab = selectedTab;
    },
    prefersColorSchemeChanged(e) {
      // Update isDarkMode when the user's preference changes
      this.isDarkMode = e.matches;
    }
  }
};
</script>

<style scoped>
.tabs {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  width: 100%;
}

.tab {
  flex: 1;
  min-width: 120px;
  padding: 10px 20px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 2px solid transparent; /* Provide an initial border */
}

.tab.active {
  /* Styles for the active tab */
  background-color: #121212; /* Black background */
  /* The outline and text color will be dynamically set via the :style binding */
}

.content {
  background-color: #FFF;
}
</style>
