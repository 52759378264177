<template>
  <button class="see-all-button" @click="navigateToLink">{{ buttonText }}</button>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true
    }
  },
  computed: {
    buttonText() {
      return "See all";
    }
  },
  methods: {
    navigateToLink() {
      // Use your preferred method to navigate to the custom link
      // For example, you can use Vue Router:
      this.$router.push(this.link);
    }
  }
};
</script>

<style scoped>

.see-all-button {
  border: 1px solid #ff79b2;
  color: #ff79b2;
  border-radius: 5px;
  text-decoration: none;
  font-size: 12px;
  width: 100px;
  height: 20px;
  margin-top: -10px;
  font-weight: bold;
}

@media (prefers-color-scheme: light) {
  
  .see-all-button {
    border: 1px solid gray;
    color: gray;
  }
  .see-all-button:hover {
    border: 1px solid blueviolet;
    color: blueviolet;
  }
}

/* Add dark mode support*/ 
@media (prefers-color-scheme: dark) {

  .see-all-button {
    border: 1px solid #ff79b2;
    color: #ff79b2;
  }
  .see-all-button:hover {
    border: 1px solid yellow;
    color: yellow;
  }
}

</style>