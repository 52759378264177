<template>
  <div> 
    <div class="news-title"> 
      <i class="fa-brands fa-apple"></i> Software Releases
      <!-- <i class="fa-regular fa-heart"></i> ihearswift News -->
    </div>
    <div class="carousel-container" ref="carouselContainer">
        <div class="carousel" ref="carousel">
            <div v-for="item in newsData" class="carousel-item">
                <div class="columns"> 
                  <div class="column image is-128x128">
                    <img v-if="item.imageUrl" :src="item.imageUrl"/>
                  </div>
                  <div class="column">
                    <a v-if="item.title.length > 0" :href="item.link" style="text-decoration: none;" target="_blank"> {{ item.title.split("(")[0] }}</a>
                  </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
name: "AppleDeveloperNews",
data() {
  return {
    newsData: [],
    autoScrollInterval: null,
    scrollSpeed: 1, // Adjust scroll speed as needed
    emojis: ['😀', '😃', '😄', '😁', '😆', '😅', '🤣', '😂', '🙂', '🙃', '😉', '😊', '😇', '🥰', '😍', '🤩', '😘', '😗', '☺️', '😚', '😙',
      '😋', '😛', '😜', '🤪', '😝', '🤑', '🤗', '🤭', '🤫', '🤔', '🤐', '🤨', '😐', '😑', '😶', '😏', '😒', '🙄', '😬', '🤥', '😌',
      '😔', '😪', '🤤', '😴', '😷', '🤒', '🤕', '🤢', '🤮', '🤧', '😵', '🤯', '🤠', '🥳', '😎', '🤓', '🧐', '😕', '😟', '🙁', '☹️',
      '😮', '😯', '😲', '😳', '🥺', '😦', '😧', '😨', '😰', '😥', '😢', '😭', '😱', '😖', '😣', '😞', '😓', '😩', '😫', '🥱', '😤',
      '😡', '😠', '🤬', '😈', '👿', '💀', '☠️', '💩', '🤡', '👹', '👺', '👻', '👽', '👾', '🤖', '😺', '😸', '😹', '😻', '😼', '😽',
      '🙀', '😿', '😾', '🙈', '🙉', '🙊', '💋', '💌', '💘', '💝', '💖', '💗', '💓', '💞', '💕', '💟', '❣️', '💔', '❤️', '🧡', '💛',
      '💚', '💙', '💜', '🤎', '🖤', '🤍', '💯', '💢', '💥', '💫', '💦', '💨', '🕳️', '💣', '💬', '👁️‍🗨️', '🗨️', '🗯️', '💭', '💤',
      '👋', '🤚', '🖐️', '✋', '🖖', '👌', '🤌', '🤏', '✌️', '🤞', '🤟', '🤘', '🤙', '👈', '👉', '👆', '🖕', '👇', '☝️', '👍', '👎',
      '✊', '👊', '🤛', '🤜', '👏', '🙌', '👐', '🤲', '🤝', '🙏', '✍️', '💅', '🤳', '💪', '🦾', '🦿', '🦵', '🦶', '👂', '🦻', '👃',
      '🧠', '🦷', '🦴', '👀', '👁️', '👅', '👄', '🧑', '👶', '🧒', '👦', '👧', '🧔', '👨', '🧔‍♂️', '👱‍♂️', '👨‍🦰', '👨‍🦱', '👨‍🦳',
      '👨‍🦲', '👩', '👱‍♀️', '👩‍🦰', '👩‍🦱', '👩‍🦳', '👩‍🦲', '👱', '👨‍⚕️', '👩‍⚕️', '👨‍🎓', '👩‍🎓', '👨‍🏫', '👩‍🏫', '👨‍⚖️',
      '👩‍⚖️', '👨‍🌾', '👩‍🌾', '👨‍🍳', '👩‍🍳', '👨‍🔧', '👩‍🔧', '👨‍🏭', '👩‍🏭', '👨‍💼', '👩‍💼', '👨‍🔬', '👩‍🔬', '👨‍💻', '👩‍💻',
      '👨‍🎤', '👩‍🎤', '👨‍🎨', '👩‍🎨', '👨‍✈️', '👩‍✈️', '👨‍🚀', '👩‍🚀', '👨‍🚒', '👩‍🚒', '👮', '👮‍♂️', '👮‍♀️', '🕵️', '🕵️‍♂️',
      '🕵️‍♀️', '💂', '💂‍♂️', '💂‍♀️', '👷', '👷‍♂️', '👷‍♀️', '🤴', '👸', '👳', '👳‍♂️', '👳‍♀️', '👲', '🧕', '🤵', '👰', '🤰',
      '🤱', '👩‍🍼', '👨‍🍼', '🧑‍🍼', '👼', '🎅', '🤶', '🧑‍🎄', '🦸', '🦸‍♂️', '🦸‍♀️', '🦹', '🦹‍♂️', '🦹‍♀️', '🧙', '🧙‍♂️',
      '🧙‍♀️', '🧚', '🧚‍♂️', '🧚‍♀️', '🧛', '🧛‍♂️', '🧛‍♀️', '🧜', '🧜‍♂️', '🧜‍♀️', '🧝', '🧝‍♂️', '🧝‍♀️', '🧞', '🧞‍♂️',
      '🧞‍♀️', '🧟', '🧟‍♂️', '🧟‍♀️', '💆', '💆‍♂️', '💆‍♀️', '💇', '💇‍♂️', '💇‍♀️', '🚶', '🚶‍♂️', '🚶‍♀️', '🧍', '🧍‍♂️', '🧍‍♀️',
      '🧎', '🧎‍♂️', '🧎‍♀️', '🧑‍🦯', '👨‍🦯', '👩‍🦯', '🧑‍🦼', '👨‍🦼', '👩‍🦼', '🧑‍🦽', '👨‍🦽', '👩‍🦽', '🏃', '🏃‍♂️', '🏃‍♀️',
      '💃', '🕺', '🕴️', '👯', '👯‍♂️', '👯‍♀️', '🧖', '🧖‍♂️', '🧖‍♀️', '🧗', '🧗‍♂️', '🧗‍♀️', '🤺', '🏇', '⛷️', '🏂', '🏌️', '🏌️‍♂️',
      '🏌️‍♀️', '🏄', '🏄‍♂️', '🏄‍♀️', '🚣', '🚣‍♂️', '🚣‍♀️', '🏊', '🏊‍♂️', '🏊‍♀️', '⛹️', '⛹️‍♂️', '⛹️‍♀️', '🏋️', '🏋️‍♂️',
      '🏋️‍♀️', '🚴', '🚴‍♂️', '🚴‍♀️', '🚵', '🚵‍♂️', '🚵‍♀️', '🤸', '🤸‍♂️', '🤸‍♀️', '🤼', '🤼‍♂️', '🤼‍♀️', '🤽', '🤽‍♂️', '🤽‍♀️',
      '🤾', '🤾‍♂️', '🤾‍♀️', '🧘', '🧘‍♂️', '🧘‍♀️', '🛀', '🛌', '🧑‍🤝‍🧑', '👭', '👫', '👬', '💏', '👩‍❤️‍💋‍👨', '👨‍❤️‍💋‍👨',
      '👩‍❤️‍💋‍👩', '💑', '👩‍❤️‍👨', '👨‍❤️‍👨', '👩‍❤️‍👩', '👪', '👨‍👩‍👦', '👨‍👩‍👧', '👨‍👩‍👧‍👦', '👨‍👩‍👦‍👦', '👨‍👩‍👧‍👧',
      '👨‍👨‍👦', '👨‍👨‍👧', '👨‍👨‍👧‍👦', '👨‍👨‍👦‍👦', '👨‍👨‍👧‍👧', '👩‍👩‍👦', '👩‍👩‍👧', '👩‍👩‍👧‍👦', '👩‍👩‍👦‍👦', '👩‍👩‍👧‍👧',
      '👨‍👦', '👨‍👦‍👦', '👨‍👧', '👨‍👧‍👦', '👨‍👧‍👧', '👩‍👦', '👩‍👦‍👦', '👩‍👧', '👩‍👧‍👦', '👩‍👧‍👧', '🗣️', '👤', '👥', '🫂',
      '👣', '🧳', '🌂', '☂️', '🧵', '🧶', '👓', '🕶️', '🥽', '🥼', '🦺', '👔', '👕', '👖', '🧣', '🧤', '🧥', '🧦', '👗', '👘', '🥻',
      '🩴', '🩱', '🩲', '🩳', '👙', '👚', '👛', '👜', '👝', '🛍️', '🎒', '🩸', '👞', '👟', '🥾', '🥿', '👠', '👡', '🩰', '👢', '👑',
      '👒', '🎩', '🎓', '🧢', '🪖', '⛑️', '📿', '💄', '💍', '💎', '🔇', '🔈', '🔉', '🔊', '📢', '📣', '📯', '🔔', '🔕', '🎼', '🎵',
      '🎶', '🎙️', '🎚️', '🎛️', '🎤', '🎧', '📻', '🎷', '🪗', '🎸', '🎹', '🎺', '🎻', '🪕', '🥁', '🪘', '📱', '📲', '☎️', '📞',
      '📟', '📠', '🔋', '🔌', '💻', '🖥️', '🖨️', '⌨️', '🖱️', '🖲️', '💽', '💾', '💿', '📀', '🧮', '🎥', '🎬', '📽️', '📺', '📷',
      '📸', '📹', '📼', '🔍', '🔎', '🕯️', '💡', '🔦', '🏮', '🪔', '📔', '📕', '📖', '📗', '📘', '📙', '📚', '📓', '📒', '📃', '📜',
      '📄', '📰', '🗞️', '📑', '🔖', '🏷️', '💰', '🪙', '💴', '💵', '💶', '💷', '🪛', '🪚', '🪝', '🪜', '⚒️', '🛠️', '⛏️', '🪓',
      '🔨', '🔧', '🔩', '⚙️', '🗜️', '⚖️', '🦯', '🔗', '⛓️', '🪝', '🧰', '🧲', '🪜', '⚗️', '🧪', '🧫', '🧬', '🔬', '🔭', '📡',
      '💉', '🩸', '💊', '🩹', '🩺'
    ]
  };
},
methods: {
  fetchNews() {
    axios.get(`${process.env.VUE_APP_BASE_URL}/news/apple/developer-news`)
      .then(response => {
        // Duplicate data for an infinite effect
        this.newsData = [...response.data];
        // // console.log(JSON.stringify(this.newsData.map(item => item.link)));
      })
      .catch(error => {
        console.error(error);
      });
  },
  fetchReleases() {
    axios.get(`${process.env.VUE_APP_BASE_URL}/news/apple/software-releases`)
      .then(response => {
        this.newsData = [...response.data];
      })
      .catch(error => {
        console.error(error);
      });
  },
  shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
  },
  getRandomEmoji() {
    const randomIndex = Math.floor(Math.random() * this.emojis.length);
    return this.emojis[randomIndex];
  },
  startAutoScroll() {
    const carousel = this.$refs.carouselContainer;
    this.autoScrollInterval = setInterval(() => {
      if (carousel.scrollLeft < carousel.scrollWidth - carousel.clientWidth) {
        carousel.scrollLeft += this.scrollSpeed;
      } else {
        carousel.scrollLeft = 0;
      }
    }, 15);
  }
},
mounted() {
  this.fetchReleases();
  this.startAutoScroll();
},
beforeDestroy() {
  clearInterval(this.autoScrollInterval);
}
};
</script>

<style>

.carousel-container {
  overflow-x: hidden;
  white-space: nowrap;
}

.news-title { 
  float: left; 
  position: relative; 
  padding: 10px; 
  animation: rainbowGlow 5s infinite alternate;
  overflow: hidden;
  background: rgb(0, 0, 0);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.title {
  margin-left: 20px;
}

.carousel {
  display: flex;
  padding-left: 20px;
  padding-right: 20px;
}

.carousel-item {
  display: inline-block;
  margin-right: 0px;
  padding: 10px;
  color: white;
  font-weight: bold;
}

a:hover {
  color: rgb(255, 166, 0);
  text-decoration: none;
}

@keyframes rainbowGlow {
0% { text-shadow: 0 0 10px #ff0000, 0 0 20px #ff0000, 0 0 30px #ff0000, 0 0 40px #ff0000, 0 0 50px #ff0000, 0 0 60px #ff0000, 0 0 70px #ff0000; color: #ff0000; }
16% { text-shadow: 0 0 10px #ff7f00, 0 0 20px #ff7f00, 0 0 30px #ff7f00, 0 0 40px #ff7f00, 0 0 50px #ff7f00, 0 0 60px #ff7f00, 0 0 70px #ff7f00; color: #ff7f00; }
33% { text-shadow: 0 0 10px #ffff00, 0 0 20px #ffff00, 0 0 30px #ffff00, 0 0 40px #ffff00, 0 0 50px #ffff00, 0 0 60px #ffff00, 0 0 70px #ffff00; color: #ffff00; }
50% { text-shadow: 0 0 10px #00ff00, 0 0 20px #00ff00, 0 0 30px #00ff00, 0 0 40px #00ff00, 0 0 50px #00ff00, 0 0 60px #00ff00, 0 0 70px #00ff00; color: #00ff00; }
66% { text-shadow: 0 0 10px #ff7f00, 0 0 20px #ff7f00, 0 0 30px #ff7f00, 0 0 40px #ff7f00, 0 0 50px #ff7f00, 0 0 60px #ff7f00, 0 0 70px #ff7f00; color: #ff7f00; }
83% { text-shadow: 0 0 10px #ffff00, 0 0 20px #ffff00, 0 0 30px #ffff00, 0 0 40px #ffff00, 0 0 50px #ffff00, 0 0 60px #ffff00, 0 0 70px #ffff00; color: #ffff00; }
100% { text-shadow: 0 0 10px #8b00ff, 0 0 20px #8b00ff, 0 0 30px #8b00ff, 0 0 40px #8b00ff, 0 0 50px #8b00ff, 0 0 60px #8b00ff, 0 0 70px #8b00ff; color: #8b00ff; }
}

@media (prefers-color-scheme: dark) {
  .carousel-item {
      background-color: #121212;
      color: #ffffff;
  }
}

@media (prefers-color-scheme: light) {
  .carousel-item {
      background-color: rgba(246, 242, 242, 0.57);
      color: black;
  }
}
</style>
