<template>
    <div>
        <h1>Video View</h1>
        <VideoComponent />
    </div>
</template>

<script>
import VideoComponent from '@/components/VideoComponent.vue';

export default {
    name: 'VideoView',
    components: {
        VideoComponent,
    },
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
