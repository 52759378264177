<template> 
    <nav-bar v-if="this.$store.state.isPresentingNavbar"></nav-bar>
    <router-view></router-view>
</template>

<script>

import NavigationBar from './components/NavigationBar.vue'

export default {
    name: 'App',
    components: {
        'nav-bar': NavigationBar
    }, 
    data() {
        return {
            eventSource: null 
        }
    },
    created() { 
        // const user = this.$store.state.user;
        // if (user) {
        //     this.openNotificationEventSource(user);
        //     return;
        // }
        // this.subscribeToUserLoginEvents();
    },
    methods: { 
        openNotificationEventSource(user) {
            let url = `http://localhost:8080/events?userId=${user.uid}`;
            this.eventSource = new EventSource(url);
            /// Based on this event payload
            /// The reload events should target only components that need to be reloaded
            /// Based on the server's requirements. For now.
            /* 
            {
                "type": "reload",
                "components": [
                    "badge",
                    "qa"
                ],
                "uid": "XXX"
            }
            */ 
            const self = this;
            this.eventSource.onmessage = (event) => {
                // console.log(`Received event: ${event.data}`)
                const data = JSON.parse(event.data);
                switch (data.type) { 
                    case 'reload':
                        const components = data.components;
                        components.forEach((component) => {
                            console.info('Reloading component:', component);
                            if (component === 'badge') { // Only reload my badge if the reload event was targeted at me
                                const uid = data.uid;
                                if (uid === user.uid) {
                                    self.emitter.emit(`reload-${component}`);
                                }
                            } else { 
                                self.emitter.emit(`reload-${component}`);
                            }
                        });
                        break;
                    default:
                        // console.log(`Event type not recognized: ${data.type}`);
                        break;
                }
            }
            
            // Event listener for handling errors
            this.eventSource.onerror = (error) => {
                console.error('EventSource error:', error);
            };  
        }, 
        closeNotificationEventSource() { 
            // console.log(`Closing Notification Event Source...`)
            if (this.eventSource) {
                this.eventSource.close();
            }
        },
        subscribeToUserLoginEvents() {
            const self = this;
            this.emitter.on("user-logged-in", (event) => {
                const user = this.$store.state.user;
                if (event.isLoggedIn && user) {
                    self.openNotificationEventSource(user);
                } else {
                    self.closeNotificationEventSource();
                }
            });
        }
    },
    beforeDestroy() {
         this.closeNotificationEventSource();
    },
}
</script>

<style scoped>
</style>