<template>
  <div>
    <home-title title="😎 Most Liked"></home-title>
    <div class="most-liked-container">
      <div class="most-liked-feed">
        <router-link :to="`/snippets/${mostLikedItem.SK}`" v-for="mostLikedItem in sorted(filtered(snippets))" :key="mostLikedItem.SK" class="most-liked-item">
          <i class="fa-solid fa-arrow-right"></i>
          <span class="most-liked-content">{{ mostLikedItem.title}}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import HomeTitleComponent from './HomeTitleComponent.vue';

export default {
  name: "MostLikedComponent",
  props: {
    snippets: {
      type: Array,
      default: []
    },
  },
  components: {
    'home-title': HomeTitleComponent
  },
  data() {
    return {
    
    };
  },
  computed: {

  },
  mounted() {
    
  },
  beforeDestroy() {
    
  },
  methods: {
    filtered(snippets) {
      return this.snippets.filter(snippet => snippet.likes > 0)
    }, 
    sorted(snippets) {
      return this.snippets.sort((a, b) => b.likes - a.likes).slice(0, 5);
    }
  },
};
</script>

<style scoped>

.header-title { 
  text-align: left;
  color: yellow;
}

.most-liked-container {
  overflow: hidden;
  white-space: normal;
  margin-top: 10px;
  position: relative;
}

.most-liked-feed {
  display: flex;
  flex-direction: column;
}

.most-liked-item {
  display: flex;
  align-items: center;
  padding: 10px;
  text-align: left;
  color: white;
}

.most-liked-image {
  width: 30px; 
  height: 30px; 
  border-radius: 50%;
  margin-right: 10px;
}

.most-liked-item:hover {
  background-color: rgba(0, 255, 255, 0.081);
}

.most-liked-title {
  font-size: 14px;
  font-weight: bold;
}

.most-liked-content {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: 'SFMono-Bold', monospace;
  padding-left: 10px;
}

@media (prefers-color-scheme: dark) {
  .most-liked-content {
    color: white;
  }
}

@media (prefers-color-scheme: light) {
  .most-liked-content {
    color: #121212;
  }
  .most-liked-item { 
    color: #121212;
  }
}


</style>
