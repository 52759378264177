<template>
  <div>
    <video
      id="video-player"
      :style="isMobile ? 'width: 100%; height: 300px;' : 'width: 100%;'"
      ref="videoPlayer"
      class="video-js vjs-default-skin vjs-big-play-centered"
      controls
      preload="auto"
      >
    </video>
    <div id="test" v-if="this.isCourseComplete" style="z-index: 100; margin-top: -60px; position: absolute; width: 100%; height: calc(100% + 60px); background-color: red; background-size: cover; background-repeat: no-repeat; background-position: center; top: 0; left: 0;">
    
    </div>
    <div id="test" v-if="isVideoEnded && !this.isCourseComplete" style="z-index: 1; margin-top: -60px; position: absolute; width: 100%; height: calc(100% + 60px); background-color: rgb(0, 0, 0, 0.8); background-size: cover; background-repeat: no-repeat; background-position: center; top: 0; left: 0;">

      <radial-progress
          foreground-color="#ff4500"
          background-color="#d3d3d3"
          :label="timeLabel"
          @update:label="timeLabel = $event"
          @completed="goToNextEpisode"
      ></radial-progress>
      
      <div style="position: absolute; bottom: 0; height: 70px; background-color: rgb(0, 0, 0, 0.6); padding: 10px; width: 100%; text-align: right;">
       
        <!-- First iteration of the system made the user purchase the course after the first episode -->
        <!-- Will come back to this, for now im removing payments from here and the table of contents in the learn component -->

        <!-- With purchasing -->
        <!-- <button v-if="isComplete && didJoinCourse && didPurchaseCourse || didPreviouslyComplete()" @click="markIncomplete()" class="button is-success" style="color: black; padding: 5px 10px; margin: 5px;">Marked as Complete ✅</button>
        <button v-else-if="!isComplete && !didJoinCourse && !didPurchaseCourse" @click="signInToContinue()" class="button" style="color: black; padding: 5px 10px; margin: 5px;">Sign in to continue 👉🏼</button>
        <button v-else-if="!isComplete && didJoinCourse && !didPurchaseCourse" @click="purchaseToContinue()" class="button is-primary" style="color: black; padding: 5px 10px; margin: 5px;">Please purchase to continue 💳</button>
        <button v-else-if="!isComplete && didJoinCourse && didPurchaseCourse" @click="markComplete()" class="button is-warning" style="color: black; padding: 5px 10px; margin: 5px;">Mark as Complete 🚀</button> -->
        
        <!-- Without purchasing -->
        <button v-if="didJoinCourse" @click="goToNextEpisode()" class="button" style="color: black; background: orange; padding: 5px 10px; margin: 5px;">Go to next episode 🚀</button>


        <div v-if="!likedVideo" style="position: absolute; bottom: 0; padding: 12px; width: 50%; text-align: left; color: white;">
          <div v-if="didJoinCourse">
            <div style="float: left; margin-right: 10px; margin-top: 10px;"> 
              Enjoy the video? 
            </div>
            <button class="custom-btn" v-on:click="likedVideo = !likedVideo">
              👍
            </button>
          </div>
        </div>
        <div v-if="likedVideo" style="position: absolute; bottom: 0; padding: 20px; width: 50%; text-align: left; color: white;">
          <div>
            <div style="float: left; margin-right: 10px; margin-top: 10px;"> 
              You liked this video 💪
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import videojs from 'video.js';
  import axios from 'axios';
  import Like from './Like.vue';
  import { mapActions } from 'vuex';
  import { updateLastWatchedEpisode } from '../apis/watch.js'
  import RadialProgressComponent from '../components/RadialProgressComponent.vue'

  export default {
    name: "VideoPlayer",
    emits: [
      'didMarkVideoComplete', 
      'didMarkVideoIncomplete', 
      'signInToContinue', 
      'purchaseToContinue',
      'enterPictureInPicture',
      'leavePictureInPicture',
      'goToNextEpisode'
    ],
    props: {
      isMobile: {
         type: Boolean,
         required: false
       },
       courseId: {
         type: String,
         required: true
       },
       userId: {
         type: String,
         required: true
       },
       episode: {
         type: Object,
         required: true
       },
       playFrom: {
         type: Number,
         required: false
       }, 
       pause: {
         type: Boolean,
         required: false
       },
       didJoinCourse: {
         type: Boolean,
         required: false
       },
       didPurchaseCourse: {
         type: Boolean,
         required: false
       },
       completedVideoIds: {
         type: Array,
         required: false
       },
       isCourseComplete: { 
          type: Boolean,
          required: false
       }
    },
    components: {
      Like,
      'radial-progress': RadialProgressComponent
    },
    data() {
      return {
        timeLabel: '10s',
        currentTime: 0,
        likedVideo: false,
        isVideoEnded: false,
        isComplete: false, 
        videoDuration: 0, // Data property to store the duration
        children: {
            // poster: "https://dummyimage.com/480x480/bf00bf/ffffff",
            controlBar: {
                children: {
                    playToggle: true,
                    playbackRateMenuButton: true,
                    currentTimeDisplay: true,
                    progressControl: true,
                    durationDisplay: true,
                    fullscreenToggle: true,
                    remainingTimeDisplay: false,
                    customControlSpacer: false,
                    pictureInPictureToggle: true,
                    enableDocumentPictureInPicture: true
                }
            }
        }
      };
    },
    mounted() {

      // Log all the props
      //// console.log(`MOBILE VIDEO: ${this.episode.SK} and playFrom: ${this.playFrom} courseId: ${this.courseId} userId: ${this.userId} didJoinCourse: ${this.didJoinCourse} didPurchaseCourse: ${this.didPurchaseCourse} completedVideoIds: ${this.completedVideoIds} pause: ${this.pause}`)
        
        //// console.log(`${this.$refs.mobileVideoPlayer}`)

        this.player = videojs(this.$refs.videoPlayer, this.children, () => {
          //// console.log('Player is ready');
            this.player.src(this.episode.video);
            // Event listeners for video player
            this.player.on('play', this.onPlay);
            this.player.on('pause', this.onPause);
            this.player.on('volumechange', this.onVolumeChange);
            this.player.on('ended', this.onVideoEnded);
            this.player.on('timeupdate', this.onTimeUpdate);
            this.player.on('enterpictureinpicture', this.onEnterPictureInPicture);
            this.player.on('leavepictureinpicture', this.onLeavePictureInPicture);
            // Add other event listeners as needed
            this.isVideoEnded = false;  
            this.player.playsinline(true);
            
            // Get references to the elements
            var currentTimeDisplay = this.player.controlBar.getChild('currentTimeDisplay');
            var playToggle = this.player.controlBar.getChild('playToggle');

            // Remove the time display from its current location
            this.player.controlBar.el().removeChild(currentTimeDisplay.el());

            // Insert the time display before the play toggle button
            this.player.controlBar.el().insertBefore(currentTimeDisplay.el(), playToggle.el());
        });

        setTimeout(() => { // Vue.js seems to take time to update it's props. Idunno hot mess.
          // console.log(`Tracing Video Component: [mounted] isCourseComplete: ${this.isCourseComplete}`)
          if (this.isCourseComplete) { 
            this.player.pause();
          } else { 
            this.player.play();
            this.player.controls(true)
          }
        }, 1000);
    },
    watch: {

      isMobile(newValue, oldValue) { 
        // console.log(`Tracing Video Component: [watch] isMobile: ${newValue}`)
      },
      // Watch for changes in the episode prop
      episode() {
        //// console.log(`VIDEO DEBUG X: Watch called with episode: ${this.episode.SK} and playFrom: ${this.playFrom}`)


        if (!this.episode) {
          //// console.log(`VIDEO DEBUG X: Watch called with no episode`)
          return;
        }

        //// console.log(`I am here`)

        this.isVideoEnded = false;
        this.player.src(this.episode.video);
        this.player.currentTime(0);
        this.updateWatch('start');

        if (this.isCourseComplete) { 
            this.player.pause();
        } else { 
          this.player.play();
          this.player.controls(true)
        }
      },
      pause() {
        if (this.pause) {
          //// console.log(`trying to pause`);
          this.player.pause();
        }
      },
      didJoinCourse() { 
        //// console.log(`didJoinCourse: ${this.didJoinCourse}`)
      }, 
      didPurchaseCourse() { 
        //// console.log(`didPurchaseCourse: ${this.didPurchaseCourse}`)
      }
    },
    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }
    },
    methods: {
      ...mapActions(['updateCurrentVideoTimeAction']),
      signInToContinue() { 
        this.$emit('signInToContinue');
      },
      purchaseToContinue() { 
        this.$emit('purchaseToContinue');
      },
      onEnterPictureInPicture() {
        //// console.log('onEnterPictureInPicture');
        this.$emit('enterPictureInPicture');
      },
      onLeavePictureInPicture() {
        //// console.log('onLeavePictureInPicture');
        this.$emit('leavePictureInPicture');
      },
      onPlay() {
        //// console.log('onPlay');
        this.isVideoEnded = false;
        this.isComplete = false;
        //// console.log(this.isVideoEnded)
        // this.updateWatch('playing');
      },
      onPause() {
        //// console.log('onPause');
        var timeLeft = this.player.remainingTime();
        var duration = this.player.duration();
        //// console.log(`Duration: ${duration} | Time left: ${timeLeft} | Play From: ${duration - timeLeft}`);
      },
      onVolumeChange() {
        //// console.log('Volume changed');
      },
      onVideoEnded() {
        if (this.isCourseComplete) { 
          return 
        } 
        this.isVideoEnded = true;
        // console.log(`Tracing video ended: [onVideoEnded] isVideoEnded: ${this.isVideoEnded}`)
        this.updateWatch('completed');
        this.player.controls(false)
      },
      onTimeUpdate() {
        this.isVideoEnded = false;
        if (this.isCourseComplete) { 
          this.player.pause();
          return 
        }
      },
      markIncomplete() {
        this.updateWatch('in_progress');
        this.player.currentTime(0);
        this.player.play();
        this.$emit('didMarkVideoIncomplete', this.episode.SK);
        this.isComplete = false;
      },
      didPreviouslyComplete() { 
        if (this.completedVideoIds && this.episode && this.episode.SK) { 
          return this.completedVideoIds.includes(this.episode.SK);
        }
        return false;
      },
      goToNextEpisode() { 
        this.$emit('goToNextEpisode');
      },
      updateWatch(status) { 
        if (!this.episode) {
            return;
        }
        updateLastWatchedEpisode(
          this.courseId, 
          this.userId, 
          this.episode,
          this.player.remainingTime(),
          this.player.duration(), 
          status,
          this.likedVideo
        ).then((response) => {
          console.info(`Tracing video ended: [updateWatch] [updateLastWatchedEpisode]: ${status}`)
          this.isComplete = status === 'completed';
          setTimeout(() => {
            if (this.isComplete) { 
              console.info(`Tracing video ended: [updateWatch] [updateLastWatchedEpisode] emitted didMarkVideoComplete: ${this.episode.SK}`)
              this.$emit('didMarkVideoComplete', this.episode.SK);
            } else { 
              this.likedVideo = false;
              this.isVideoEnded = false;
              this.isComplete = false;
            }
          }, 1000);
        }).catch((error) => {
          // console.log(`Error: ${error}`)
          this.isComplete = false;
        });
      }
    }
  };
  </script>
  
  <style>
  .vjs-control-bar { font-size: 200% }
  /* hide time display on progress bar on the mouse position */
.video-js .vjs-progress-control:hover .vjs-mouse-display {
    display: none;
}
.vjs-current-time {
    display: none;
}
.video-js {
  background: content-box;
  position: static;
  height: auto;
}

.custom-btn { 
  margin-right: 10px; 
  border: 1px solid white;
  padding: 8px;
  border-radius: 6px;
}

.vjs-picture-in-picture-control .vjs-icon-placeholder {
    /* Hide the default icon placeholder */
    display: none;
}

.vjs-picture-in-picture-control::after {
    /* Use the ::after pseudo-element to add custom content */
    content: '';
    display: inline-block;
    width: 24px; /* Adjust the width as needed */
    height: 24px; /* Adjust the height as needed */
    background-image: url('../../public/button-icons/picture-in-picture-white.png'); /* Path to your custom image */
    background-size: cover; /* Cover the entire area of the pseudo-element */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Do not repeat the background image */
    color: white;
}

path { fill: orange; }


.vjs-time-tooltip {
  display: none;
}

</style>
  