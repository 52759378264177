<template>
    <div class="title-container">
      <home-title title="🤓 New Series"></home-title>
      <see-all-button :link="'/series'"></see-all-button>
    </div>
    <router-link v-if="latestSeries" :to="`/series/${latestSeries.SK}`">
      <img :src="latestSeries.thumbnail" class="coming-soon-image" />
    </router-link>
</template>

<script>
import HomeTitleComponent from './HomeTitleComponent.vue';
import SeeAllButtonComponent from '../SeeAllButtonComponent.vue';

import { getAllSeries } from '@/apis/series';

export default {
  name: 'ComingSoonComponent',
  components: {
    'home-title': HomeTitleComponent,
    'see-all-button': SeeAllButtonComponent
  },
  data() {
    return {
      isLoading: false,
      latestSeries: null
    };
  },
  mounted() {
    this.fetchAllSeries();
  },
  methods: {
    fetchAllSeries() {
      this.isLoading = true;

      getAllSeries()
        .then((response) => {

            const sortedSeries = response
              .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
          
            this.latestSeries = sortedSeries[0];
          
            this.isLoading = false;
        })
        .catch((error) => {
          // console.log(error);
          this.isLoading = false;
        });
    },
  },
}
</script>

<style scoped>

.coming-soon-image { 
  object-fit: cover; 
  width: 100%; 
  height: 200px; 
  margin-bottom: 10px;
  min-width: 200px;
  border-radius: 3px;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>