<template>
    <div class="container" :style="isMobile || windowWidth < 1100 ? 'margin-top: 100px;' : 'margin-top: 20px;'">
        <div class="row justify-content-center">
            <div 
            :class="isMobile || windowWidth < 1100 ? '' : 'col-md-4'"
            >
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Forgot Password 🙉</h5>
                        <p style="color: red; font-size: 16px;"> TODO: Implement this code </p>
                        <div class="alert alert-warning" role="alert" v-if="isSendError">
                            🚨 Send failed. Please try again.
                        </div>
                        <div class="alert alert-success" role="alert" v-if="isSendComplete">
                            🎉 Send successful! Your account was created!
                        </div>
                        <form @submit.prevent="submitForm">
                            <div class="form-group">
                                <label for="email">Email</label>
                                <input type="email" class="form-control" id="email" v-model="email" required>
                                <small v-if="submitted && !isEmailValid" class="text-danger">Please enter a valid email address.</small>
                            </div>
                            <router-link :to="`/login`" class="btn btn-style" style="background-color: #3e2ce2; color: white; height: 40px; line-height: 1; font-size: 16px;">Back</router-link>
                            <button type="submit" class="btn btn-style fr" style="background-color: #c42ce2; color: white; height: 40px; line-height: 1; font-size: 16px;">Send email</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ForgotPasswordComponent from '@/components/ForgotPasswordComponent.vue';
import { mapState } from 'vuex';

export default {
    name: 'ForgotPasswordView',
    data() {
        return {
            // Initial width to store the current window width
            windowWidth: 0,
        };
    },
    components: {
        ForgotPasswordComponent
    },
    computed: {
        ...mapState(['user']), 
        isMobile() {
            // Use navigator.userAgent to check the device type
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Regular expressions for mobile detection
            return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
        }, 
    },
    mounted() {
        if (this.user) {
            this.$router.push('/dashboard');
        }

        this.updateWindowWidth();
        // Add event listener when the component is mounted
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {v
        // Remove event listener before the component is destroyed to clean up
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        submitForm() {
            this.submitted = true; // Set submitted to true when form is submitted
            this.validateForm();
            if (this.isEmailValid) {
                this.isSendError = false;
                this.isSendComplete = true;
                // Proceed with registration logic
            } else {
                this.isSendComplete = false;
                this.isSendError = true;
            }
        },
        validateForm() {
            this.isEmailValid = this.validateEmail(this.email);
        },
        validateEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },
        handleResize() {
            this.updateWindowWidth();
        },
        updateWindowWidth() {
            // Update windowWidth data property with the current window width
            this.windowWidth = window.innerWidth;
        }
    }
};
</script>

<style scoped>

/* SAME CSS AS REGISTER VIEW (REFACTOR LATER) */

.container {
    margin-top: 50px;
}

.btn-style {
    background-color: indigo; 
    color: white;
    font-size: 24px;
}

.fr { 
    float: right;
}

.card-title {
    font-size: 24px;
    width: 100%;
}

label { 
    font-size: 18px;
    color: gray;
}

input { 
    font-size: 16px;
}

form { 
    margin-top: 20px;
}

.text-danger { 
    font-size: 18px;
}
.alert { 
    font-size: 18px;
}

.vertical { 
    display: flex;
    flex-direction: column;
    align-items:baseline;
    justify-content: left;
    margin-bottom: 20px;
}

/* END SAME CSS AS REGISTER VIEW (REFACTOR LATER) */

</style>
