<template>
  <div class="message-board" style="border: 10px solid orange;">
    <!-- Scrollable Message List -->
    <div class="message-list-container" style="height: 90%;">
      <ul class="message-list">
        <li v-for="(message, index) in sortedMessages" :key="index" class="message-item">
          <div style="float: left; position: relative; width: 100%;"> 
            <div style="float: left; padding: 10px;">
              <img :src="avatar(message.user)" alt="User Avatar" class="avatar"/>
            </div>
            <div class="message-header">
                <span class="username">{{ username(message.user) }}</span>
                <span class="timestamp">{{ message.timestamp }}</span>
            </div>
            <p class="text">{{ message.text }}</p>
            <div style="float: right; position: relative;"> 
              <button @click="startReply(message)" class="action-button">Reply</button>
              <button class="action-button" @click="handleUpvoteQuestion(message.PK.split('#')[1])">
                <i style="color: blue;" class="fa-solid fa-arrow-up"></i> 
                ({{ message.upvotes }})
              </button>
            </div>
            <div v-for="answer in sortedAnswers(message.answers)" style="float: left; position: relative; width: 90%; margin-left: 10%;"> 
              <div style="float: left; padding: 10px;">
                <img :src="avatar(answer.user)" alt="User Avatar" class="avatar"/>
              </div>
              <div class="message-header" :style="{ backgroundColor: 'rgb(255, 235, 59, 0.2)', color: '#121212' }">
                  <span class="username">{{ username(answer.user) }}</span>
                  <span class="timestamp">{{ answer.timestamp }}</span>
              </div>
              <p class="text">{{ answer.text }}</p>
              <div style="float: right; position: relative;"> 
                <button class="action-button" @click="handleUpvoteAnswer(answer.PK.split('#')[1])">
                  <i style="color: blue;" class="fa-solid fa-arrow-up"></i> 
                  ({{ answer.upvotes }})
                </button>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

    <!-- Fixed Message Input and Send Button at the Bottom -->
    <div class="message-input-area">
      <input
        v-model="newMessage"
        @keyup.enter="sendMessage"
        placeholder="Type your message here..."
        type="text"
        class="message-input"
        ref="messageInput"
      />
      <button @click="sendMessage" class="button is-dark">{{ isReplying ? 'Reply' : 'Send' }}</button>
    </div>
  </div>
</template>

<script>

import { postQuestion, postAnswer, questionsForCourse, deleteQuestion, deleteAnswer, upvoteQuestion, upvoteAnswer } from '../apis/qa.js';
const uuid = require('uuid');

export default {
  data() {
    return {
      // Example messages format
      messages: [],
      newMessage: '', 
      isReplying: false,
      isReplyingToQuestionId: '',
      isReplyingToUser: {}
    };
  },
  mounted() {
    this.fetchMessages();
  },
  computed: {
    sortedMessages() {
      if (!this.messages) return [];
      return this.messages.slice().sort((a, b) => {
          // Convert timestamps to Date objects if they're not already
          const dateA = new Date(a.timestamp);
          const dateB = new Date(b.timestamp);
          // Sort in descending order: newer (larger) timestamps come first
          return dateB - dateA;
      }).sort((a, b) => {
        // Sort by upvotes in descending order
        return b.upvotes - a.upvotes;
      });
    }
  },
  methods: {
    sortedAnswers(answers) { 
      if (!answers) return [];
      return answers.slice().sort((a, b) => {
          // Convert timestamps to Date objects if they're not already
          const dateA = new Date(a.timestamp);
          const dateB = new Date(b.timestamp);
          // Sort in descending order: newer (larger) timestamps come first
          return dateB - dateA;
        }).sort((a, b) => {
          // Sort by upvotes in descending order
          return b.upvotes - a.upvotes;
        });
    },
    avatar(user) {
      try {
          // Parse the JSON safely
          const parsedUser = JSON.parse(user);

          // Access the photoURL safely using optional chaining
          const photoURL = parsedUser?.photoURL;

          // Further actions, such as using the photoURL safely
          return photoURL
      } catch (e) {
          // Handle parsing errors or other issues
          return '../assets/avatars/noface-avatar.png'
      }
    },
    username(user) {
      try {
          // Parse the JSON safely
          const parsedUser = JSON.parse(user);

          // Access the photoURL safely using optional chaining
          const email = parsedUser?.email;

          // Further actions, such as using the photoURL safely
          return email
      } catch (e) {
          // Handle parsing errors or other issues
          return 'Unknown User'
      }
    },
    fetchMessages() {
      questionsForCourse('ghi')
        .then(response => {
          this.messages = response;
        })
        .catch(error => {
          console.error('Error fetching messages:', error);
        });
    },
    sendMessage() {
      if (!this.newMessage.trim()) return; // Ignore empty messages

      const payload = {
        id: uuid.v4(),
        courseId: 'ghi',
        user: this.$store.state.user,
        text: this.newMessage,
        timestamp: new Date().toLocaleTimeString(), // Simple timestamp
      };
      
      if (this.isReplying) { 
        payload['questionId'] = this.isReplyingToQuestionId;
        payload["to"] = this.isReplyingToUser;
        postAnswer(payload)
          .then(response => {
            this.isReplying = false;
            this.isReplyingToQuestionId = '';
            this.isReplyingToUser = {};
            this.fetchMessages();
          })
          .catch(error => {
            console.error('Error posting answer:', error);
          });
      } else { 
        postQuestion(payload)
          .then(response => {
            // console.log(`Question posted: ${response}`)
            this.fetchMessages();
          })
          .catch(error => {
            console.error('Error posting question:', error);
          });
      }

      this.newMessage = ''; // Clear the input field after sending
    },
    handleUpvoteQuestion(questionId) { 
      upvoteQuestion('ghi', questionId)
        .then(response => {
          // console.log(`Question upvoted: ${response}`)
          this.fetchMessages();
        })
        .catch(error => {
          console.error('Error upvoting question:', error);
        });
    },
    handleUpvoteAnswer(answerId) { 
      upvoteAnswer('ghi', answerId)
        .then(response => {
          // console.log(`Answer upvoted: ${response}`)
          this.fetchMessages();
        })
        .catch(error => {
          console.error('Error upvoting answer:', error);
        });
    },
    startReply(message) {
      this.isReplying = true;
      this.isReplyingToQuestionId = message.PK.split("#")[1];
      this.isReplyingToUser = message.user;
      this.newMessage = `Replying to ${this.username(message.user)}: `;
      this.$nextTick(() => {
        const inputElement = this.$refs.messageInput; // Using a ref to identify the input element
        if (inputElement) {
          // Set cursor position at the end of the text
          inputElement.focus();
          const endPosition = this.newMessage.length;
          inputElement.setSelectionRange(endPosition, endPosition);
        }
      });
    },
  }
};
</script>

<style>

@media (prefers-color-scheme: dark) {
    .message-header { 
        background-color: rg;
        color: white;
    }
    .message-input-area { 
        background-color: black;
    }
    .message-input { 
        border: 1px solid rgba(0, 0, 0, 0.1);
        background-color: #121212;
    }
  
}

@media (prefers-color-scheme: light) {
    .message-header { 
        background-color: rgb(248, 248, 248);
        color: gray;
    }
    .message-input-area { 
        background-color: white;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.1);
    }
    .message-input { 
        border: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.text {
  float: left; 
  position: relative;
  width: 60%;
  height: auto;;
  word-wrap: break-word; /* This will break long words to prevent overflow */
  max-width: 60%; /* Ensures the paragraph's width will not exceed its container */
  font-size: 12px;
}

.message-board {
  display: flex;
  flex-direction: column;
  height: 100%; /* Adjust this based on your layout */
  position: relative;
}

.message-list-container {
  overflow-y: auto;
}

.message-list {
  list-style-type: none;
  margin: 0;
  padding-top: 20px;
  padding-bottom: 40px;
}

.message-list::-webkit-scrollbar {
  display: none; /* This hides the scrollbar in webkit browsers */
}

.message-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.message-content {
  flex-grow: 1;
}

.message-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  height: 20px;
  font-size: 12px;
}

.username {
  font-weight: bold;
}

.message-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
}

.action-button {
  margin-bottom: 5px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 12px;
  font-weight: bold;
}

.message-input-area {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.message-input {
  flex: 0 0 90%;
  margin-right: 5px;
  padding: 5px;
}

.send-button {
  flex: 0 0 5%;
  cursor: pointer;
  padding: 5px;
}
</style>
