<template>
    <div class="banner">
      <div class="title-with-image">
        <img class="title-image" src="@/assets/adam-small.png" alt="Title Icon" />
        <h2 class="banner-title">
          I'M PROUD TO HAVE WORKED WITH
        </h2>
    </div>
    <div class="logos-container"> 
      <div class="logos" :style="{ 'animation-duration': scrollDuration + 's' }">
        <div class="logo" v-for="logo in allLogos" :key="logo.name">
          <!-- Use actual images -->
          <img 
              :src="logo.src" 
              :alt="logo.name" 
              :style="{ 
                marginTop: logo.name == 'Scotiabank' || logo.name == 'Bosslife' ? '-10px' : '0px',
                width: logo.name == 'Scotiabank' ? '400px' : logo.name == 'Disney' ? '220px' : '300px'
              }"
          />
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  export default {
    name: 'CompanyBanner',
    data() {
      return {
        scrollDuration: 30, // Duration of the scroll in seconds
        companyLogos:  [
          { name: 'Lululemon', src: '../../company-icons/lululemon.png' },
          { name: 'Disney', src: '../../company-icons/disney.png' },
          { name: 'Wattpad', src: '../../company-icons/wattpad.png' },
          { name: 'Scotiabank', src: '../../company-icons/Scotiabank-logo.webp' },
          { name: 'Kinder', src: '../../company-icons/kinder.png' },
          { name: 'Bosslife', src: '../../company-icons/bosslife.png' }
        ],
      };
    },
    computed: {
      allLogos() {
        // Duplicate the logos to create an infinite effect
        return [
          ...this.companyLogos, 
          ...this.companyLogos, 
          ...this.companyLogos, 
          ...this.companyLogos, 
          ...this.companyLogos,
          ...this.companyLogos,
          ...this.companyLogos,
          ...this.companyLogos,
        ];
      },
    },
  };
  </script>
  
  <style scoped>
.banner {
  position: relative;
  width: 100%;
  height: 240px;
  background: var(--md-sys-color-primary);
  overflow: hidden;
}
  
.title-with-image {
  display: flex;
  align-items: center; /* Center the items vertically */
  justify-content: center; /* Center the items horizontally if needed */
  gap: 10px; /* Adjust the space between the image and the text */
  margin-top: 60px;
}

.title-image {
  /* Adjust width and height as needed */
  width: 50px; /* Example size, adjust as needed */
  height: auto;
  margin-top: -50px;
  border-radius: 50%;
  border: 2px solid white;
}

.banner-title {
  margin-top: 3rem;
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  opacity: 0.8;
  margin-top: 0; /* Adjust the margin-top as the flexbox container handles spacing */
  margin-bottom: 3rem;
}
  
.logos-container {
  overflow: hidden;
  width: 100%;
}

.logos {
  display: flex;
  animation: scroll-logos linear infinite;
}

/* Keyframes for the scrolling animation */
@keyframes scroll-logos {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-100% / 2)); /* Scroll half the total width of all logos */
  }
}

.logo img {
  max-width: 200px;
  max-height: 200px;
  opacity: 0.8;
  padding-right: 40px;
}
</style>
  