const axios = require('axios');

const getCompletedEpisodeIds = async (courseId, userId) => { 
  const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_BASE_URL}/watch/${userId}/course/${courseId}/completed`,
      headers: { 
        'Content-Type': 'application/json'
      },
  };
  try {
      const response = await axios(config);
      return response.data;
  } catch (error) {
      throw error;
  }
}

const updateCompletedEpisodeIds = async (courseId, userId, completedEpisodeIds) => { 
  const data = JSON.stringify({
    "PK": `USER#${userId}`,
    "SK": `COURSE#${courseId}|UPDATECOMPLETEDEPISODES`,
    "completedEpisodeIds": completedEpisodeIds,
    "GSI4PK": `USER#${userId}`,
    "GSI4SK": `COURSE#${courseId}|COMPLETEDEPISODES`
  });

  const config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_BASE_URL}/watch/completed`,
      headers: { 
          'Content-Type': 'application/json'
      },
      data : data
  };

  try {
      const response = await axios(config);
      return response.data;
  } catch (error) {
      throw error;
  }
}

const getLastWatchedEpisode = async (courseId, userId) => {
  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.VUE_APP_BASE_URL}/watch/${userId}/course/${courseId}`,
    headers: {
      'Content-Type': 'application/json'
     }
  };
  try {
    const response = await axios(config);
    return response.data[0];
  } catch (error) {
    throw error;
  }
}

const updateLastWatchedEpisode = async (courseId, userId, episode, timeLeft, duration, status, likedVideo) => {
  let payload = {
      "PK": `USER#${userId}`,
      "SK": `COURSE#${courseId}|LASTPLAYED`,
      "userId": `${userId}`,
      "courseId": `${courseId}`,
      "episodeId": `${episode.SK}`,
      "episodeTitle": `${episode.title}`,
      "timestamp": `${new Date().toISOString()}`,
      "status": status,
      "GSI3PK": `USER#${userId}`,
      "GSI3SK": `COURSE#${courseId}|LASTPLAYED`,
      "playFrom": parseFloat(duration - timeLeft),
  }
  if (likedVideo) { 
    payload["likedVideo"] = true;
  }

  let data = JSON.stringify(payload);

  let config = {
      method: 'patch',
      maxBodyLength: Infinity,
      url: `${process.env.VUE_APP_BASE_URL}/watch/update`,
      headers: { 
          'Content-Type': 'application/json'
      },
      data : data
  };

  try {
      const response = await axios(config);
      return response.data;
  } catch (error) {
      throw error;
  }
}

const getCoursesProgress = async (userId) => {

  const config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `${process.env.VUE_APP_BASE_URL}/watch/${userId}/courses/progress`,
    headers: { },
  };

  try {
    const response = await axios(config);
    return response.data;
  } catch (error) {
    throw error;
  }
}

const deleteLastWatchedEpisode = async (courseId, userId) => {

  let payload = {
      "PK": `USER#${userId}`,
      "SK": `COURSE#${courseId}|LASTPLAYED`
  };

  let data = JSON.stringify(payload);

  let config = {
      method: 'delete', // Change the method to 'delete'
      url: `${process.env.VUE_APP_BASE_URL}/watch/delete`, // Assuming you have a specific endpoint for deletion
      headers: {
          'Content-Type': 'application/json'
      },
      data: data
  };

  try {
      const response = await axios(config);
      return response.data;
  } catch (error) {
      throw error;
  }
};

const restartCourse = async (courseId, userId) => {
  try { 
    await updateCompletedEpisodeIds(courseId, userId, []);
    await deleteLastWatchedEpisode(courseId, userId);
    return true;
  } catch (error) {
    throw error;
  }
}

export { 
  getCompletedEpisodeIds,
  updateCompletedEpisodeIds,
  getLastWatchedEpisode,
  updateLastWatchedEpisode,
  getCoursesProgress,
  deleteLastWatchedEpisode,
  restartCourse
}