import axios from 'axios';

const getSnippets = async () => {
  return new Promise((resolve, reject) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url:   `${process.env.VUE_APP_BASE_URL}/snippets`,
        headers: { },
    };
    axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // console.log(error);
        reject(error)
      })
  })
}

const getSnippet = async (snippetId) => {
  return new Promise((resolve, reject) => {
    let config = {
        method: 'get',
        maxBodyLength: Infinity,
        url:   `${process.env.VUE_APP_BASE_URL}/snippets/${snippetId}`,
        headers: { },
    };
    axios.request(config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        // console.log(error);
        reject(error)
      })
  })
}

export {
  getSnippets,
  getSnippet,
}