<template>
    <div style="float: left; position: relative; width: 100%; height: 400px;">
      <p>Apple Developer News</p>
      <div v-for="newsItem in paginatedNews" :key="newsItem.guid" class="news-item">
        <h3>{{ newsItem.title }}</h3>
        <a :href="newsItem.link" target="_blank">Read more</a>
      </div>
  
      <div class="pagination">
        <button @click="currentPage--" :disabled="currentPage <= 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="currentPage++" :disabled="currentPage >= totalPages">Next</button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from "axios";
export default {
  name: "NewsList",
  data() {
    return {
      newsData: [], // Array of news items
      currentPage: 1,
      pageSize: 5 // Number of items per page
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.newsData.length / this.pageSize);
    },
    paginatedNews() {
      let start = (this.currentPage - 1) * this.pageSize;
      let end = start + this.pageSize;
      return this.newsData.slice(start, end);
    }
  },
  mounted() {

        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: `${process.env.VUE_APP_BASE_URL}/news/apple/developer-news`,
            headers: { }
        };

        axios.request(config)
        .then((response) => {
            // // console.log(JSON.stringify(response.data));
            this.newsData = response.data;
        })
        .catch((error) => {
            // // console.log(error);
        });
  }
};
</script>

<style>
.title { 
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 10px;
}
</style>
