<template>
    <div class="container-content" style="padding-top: 100px;">
        <div class="card" style="position: relative; margin: 0 auto; width: 95%;">
            <div class="card-body">
                <RegisterComponent :isInModal="false" :isFromAdvertisement="false" />
            </div>
        </div>
    </div>
</template>

<script>
import RegisterComponent from '@/components/RegisterComponent.vue'; 
import { mapState } from 'vuex';

export default {
    data() {
        return {
            // Initial width to store the current window width
            windowWidth: 0,
        };
    },
    components: {
        RegisterComponent
    },
    computed: {
        ...mapState(['user']), 
        isMobile() {
            // Use navigator.userAgent to check the device type
            const userAgent = navigator.userAgent || navigator.vendor || window.opera;

            // Regular expressions for mobile detection
            return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
        }, 
    },
    mounted() {
        if (this.user) {
            this.$router.push('/dashboard');
            return 
        }
        this.$store.state.isPresentingNavbar = true;

        this.updateWindowWidth();
        // Add event listener when the component is mounted
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {v
        // Remove event listener before the component is destroyed to clean up
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            this.updateWindowWidth();
        },
        updateWindowWidth() {
            // Update windowWidth data property with the current window width
            this.windowWidth = window.innerWidth;
        }
    }
};
</script>

<style scoped>

</style>
