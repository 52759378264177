<template>
    <div class="spinner-container" :style="{ height: lessHeight ? '200px' : '100vh'}">
        <div class="spinner"></div>
        <p style="padding-left: 20px; font-size: 36px;">Loading...</p>
    </div>
</template>
<script>
export default {
  name: 'SpinnerComponent',
  props: {
    lessHeight: {
      type: Boolean,
      default: false
    } 
  }
};
</script>

<style scoped>
/* Add your custom styles here */
.centered-image {
  display: block;
  margin: 0 auto;
  max-width: 200px;
  width: 100%;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height as needed */
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
